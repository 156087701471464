import './index.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { IAuthPageContext } from '../../types';
import { AuthPageContext } from '../../context/AuthPageContext';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { LS } from '../../constants';
import { I18nextProvider } from 'react-i18next';
import { i18n } from '../../locales';

export const Auth = () => {
  const continuationTokenRef = useRef<string>('');
  const usernameRef = useRef<string>('');
  const passwordRef = useRef<string>('');
  const [keepUserLoggedIn, setKeepUserLoggedIn] = useState<boolean>(false);

  const navigate = useNavigate();
  const { authenticated, logo, redirectWhenAuthenticated } =
    useAnalogAuthContext();

  const nextUrl = useMemo(() => {
    const backUrl = localStorage.getItem(LS.PreviousPage);
    return backUrl || redirectWhenAuthenticated || '/';
  }, [redirectWhenAuthenticated]);

  useEffect(() => {
    if (authenticated) {
      navigate(nextUrl, { replace: true });
      localStorage.removeItem(LS.PreviousPage);
    }
  }, [authenticated, navigate, nextUrl]);

  const context = useMemo((): IAuthPageContext => {
    return {
      continuationTokenRef,
      passwordRef,
      usernameRef,
      keepUserLoggedIn,
      setKeepUserLoggedIn,
    };
  }, [keepUserLoggedIn]);

  return (
    <AuthPageContext value={context}>
      <I18nextProvider i18n={i18n}>
        <div className="analog-auth__underlay">
          <div className="analog-auth">
            {logo && <header className="analog-auth__header">{logo}</header>}
            <div className="analog-auth__main">
              <Outlet />
            </div>
          </div>
        </div>
      </I18nextProvider>
    </AuthPageContext>
  );
};
