import { createContext, use, useEffect, useMemo, useState } from 'react';
import { IAnalogAuthContext, IAnalogAuthProviderProps } from '../types';
import { LS } from '../constants';
import { AuthRepository } from '../api/auth/repository';

export const AnalogAuthContext = createContext<IAnalogAuthContext>({
  authenticated: false,
} as IAnalogAuthContext);

export const useAnalogAuthContext = () => use(AnalogAuthContext);

export const AnalogAuthProvider = (props: IAnalogAuthProviderProps) => {
  const [authenticated, setAuthenticated] = useState<boolean>(
    !!localStorage.getItem(LS.AccessToken)
  );

  useEffect(() => {
    AuthRepository.getInstance(props.authOptions);
  }, [props.authOptions]);

  const context: IAnalogAuthContext = useMemo((): IAnalogAuthContext => {
    return {
      authenticated,
      setAuthenticated,
      ...props,
      signUpRedirectUrls: props.signUpRedirectUrls || [],
      preserveSearchParams: props.preserveSearchParams || [],
      allowSignUp: props.allowSignUp ?? true,
      allowResetPassword: props.allowResetPassword ?? true,
      allowKeepMeLoggedIn: props.allowKeepMeLoggedIn ?? true,
    };
  }, [authenticated, props]);

  return (
    <AnalogAuthContext value={context}>{props.children}</AnalogAuthContext>
  );
};
