import { FormElement, ISelectOption, Select } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import countries from 'i18n-iso-countries';
import enCountryLocales from 'i18n-iso-countries/langs/en.json';
import { sortByDelegate } from '../../../utils/sortByDelegate';
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from '../../../constants';

countries.registerLocale(enCountryLocales);

export const NationalityControl = () => {
  const { formState, setValue } = useFormContext<{
    countryCode?: string | null;
    country?: string | null;
  }>();
  const countryCode = useWatch({ name: 'countryCode' });
  const { t } = useTranslation();
  const options: ISelectOption[] = useMemo(() => {
    const codes = Object.entries(countries.getAlpha3Codes());

    const options: ISelectOption[] = codes.map(([alpha3, alpha2]) => {
      const name = countries.getName(alpha3, 'en');
      const flagUrl = `${DEFAULT_CDN_URL}${alpha2.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
      return {
        id: alpha3 || '',
        description: {
          text: alpha3 || '',
        },
        text: name || alpha3,
        icon: (
          <img
            style={{ width: '18px', height: '18px', borderRadius: '4px' }}
            src={flagUrl}
            alt={name}
          />
        ),
      };
    });

    options.sort(sortByDelegate('asc', 'text'));

    return options;
  }, []);
  const value = useMemo(() => {
    return options.find((o: ISelectOption) => o.id === countryCode);
  }, [options, countryCode]);

  const onChange = useCallback(
    (option: ISelectOption) => {
      setValue('countryCode', option.id);
      setValue('country', option.text);
    },
    [setValue]
  );

  return (
    <FormElement
      label={t('label.nationality')}
      message={formState.errors.countryCode?.message}
    >
      <Select
        options={options}
        value={value}
        onChange={onChange}
        placeholder={t('placeholder.nationality')}
        invalid={!!formState.errors.countryCode}
        search
        dropdownProps={{
          maxHeight: '290px',
        }}
      />
    </FormElement>
  );
};
