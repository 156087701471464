import { useContext } from 'react';
import { IconButton } from '../Button/IconButton';
import { Maximize2, Minimize2, X } from 'lucide-react';
import { DialogContext } from './context';
import { clsx } from 'clsx';
import { Typography } from '../Typography';

export const DialogHeader = () => {
  const {
    onDismiss,
    isFullScreen,
    toggleFullScreen,
    showFullScreenButton,
    showDismissButton = true,
    title,
    subtitle,
    onRenderHeader,
  } = useContext(DialogContext);

  if (onRenderHeader) {
    return onRenderHeader();
  }

  const rootClass: string = clsx('analog-dialog__header');

  return (
    <header className={rootClass}>
      <Typography
        as="h3"
        variant="h3"
        weight="bold"
        text={title}
        className="analog-dialog__title"
      />
      {subtitle && (
        <Typography
          as="div"
          variant="headline"
          text={subtitle}
          className="analog-dialog__subtitle"
        />
      )}
      {(showFullScreenButton || showDismissButton) && (
        <div className="analog-dialog__header-actions">
          {showFullScreenButton && (
            <IconButton
              data-testid="fullscreen-button"
              variant="quietLayer2"
              size="m"
              onClick={toggleFullScreen}
              content={isFullScreen ? <Minimize2 /> : <Maximize2 />}
            />
          )}
          {showDismissButton && (
            <IconButton
              variant="quietLayer3"
              size="m"
              onClick={onDismiss}
              data-testid="dismiss-button"
              content={<X />}
            />
          )}
        </div>
      )}
    </header>
  );
};
