import './index.css';
import {
  ISmartChartConfig,
  ISmartChartConfigBase,
} from '../../../../types/smart-charts';
import {
  ConfirmDialog,
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  TruncatedText,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useNavigate, useParams } from 'react-router';
import { clsx } from 'clsx';
import { KeyboardEvent, MouseEvent, useCallback, useMemo } from 'react';
import { Ellipsis, Pencil, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { SmartChartsRepository } from '../../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../../api/smart-charts/service';
import { useSmartChartsStore } from '../../store/slice';
import { useSmartChartsContext } from '../../context';
import { RenameSmartChartModal } from '../../../../components/RenameSmartChartModal';

type IProps = {
  item: ISmartChartConfigBase;
  focusedItem: string;
  setFocusedItem: (item: string) => void;
};

export const HistoryItem = ({ item, focusedItem, setFocusedItem }: IProps) => {
  const { t } = useTranslation();
  const { chartId } = useParams();
  const navigate = useNavigate();
  const title = item.ai_graph_meta_data.title.value;
  const isActive = chartId === item.id;
  const { baseUrl } = useSmartChartsContext();

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const rootClass = clsx(
    'sc2__history-item',
    'analog-typography--subtitle',
    isActive && 'sc2__history-item--active',
    focusedItem === item.id && 'sc2__history-item--focused'
  );

  const {
    value: showDeleteModal,
    setTrue: openDeleteModal,
    setFalse: dismissDeleteModal,
  } = useBoolean(false);

  const {
    value: showRenameConversationModal,
    setTrue: openRenameConversationModal,
    setFalse: dismissRenameConversationModal,
  } = useBoolean(false);

  const onConversationRename = useCallback((config: ISmartChartConfig) => {
    useSmartChartsStore.getState().updateHistory({
      id: config.id,
      ai_graph_meta_data: config.ai_graph_meta_data,
    });
    useSmartChartsStore.getState().updateConfig({
      id: config.id,
      ai_graph_meta_data: config.ai_graph_meta_data,
    });
  }, []);

  const menuOptions: IMenuOption[] = [
    {
      id: 'rename',
      icon: <Pencil size={16} />,
      text: t('action.rename'),
    },
    {
      id: 'delete',
      icon: <Trash2 size={16} />,
      text: t('action.delete'),
    },
  ];

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'delete') {
        openDeleteModal();
      }
      if (option.id === 'rename') {
        openRenameConversationModal();
      }
    },
    [openDeleteModal, openRenameConversationModal]
  );

  const handleDeleteItem = useCallback((): Promise<void> => {
    return smartChartsRepository
      .deleteConversation({
        chartId: item.id,
      })
      .then(() => {
        useSmartChartsStore.getState().deleteConversation(item.id);
        if (isActive) {
          navigate(baseUrl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [baseUrl, isActive, item.id, navigate, smartChartsRepository]);

  const handleNavigate = useCallback(() => {
    setFocusedItem(item.id);
    navigate(`${baseUrl}/${item.id}`);
  }, [baseUrl, item.id, navigate, setFocusedItem]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleNavigate();
      }
    },
    [handleNavigate]
  );

  const handleMenuClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setFocusedItem(item.id);
    },
    [item.id, setFocusedItem]
  );

  return (
    <div
      tabIndex={0}
      role="button"
      className={rootClass}
      onClick={handleNavigate}
      onKeyDown={handleKeyDown}
    >
      <TruncatedText text={title} />

      <span className="sc2__history-item-menu" onClick={handleMenuClick}>
        <Menu
          className="sc2__history-item-menu"
          options={menuOptions}
          onChange={onMenuChange}
          dropdownProps={{
            anchor: (
              <IconButton
                size="s"
                variant="quietLayer3"
                content={<Ellipsis />}
              />
            ),
            placement: 'bottom-end',
          }}
        />
      </span>

      {showDeleteModal && (
        <ConfirmDialog
          onDismiss={dismissDeleteModal}
          title={t('confirm.delete_chart_title')}
          subtitle={t('confirm.delete_chart_subtitle')}
          submitText={t('action.delete')}
          submitAction={handleDeleteItem}
        />
      )}
      {showRenameConversationModal && (
        <RenameSmartChartModal
          conversation={item}
          onDismiss={dismissRenameConversationModal}
          onSubmitSuccess={onConversationRename}
        />
      )}
    </div>
  );
};
