import { ReactNode } from 'react';
import './index.css';
import { clsx } from 'clsx';
import { Typography } from '../Typography';
import { IconButton } from '../Button/IconButton';
import { Copy } from 'lucide-react';

interface IProps {
  label: ReactNode;
  value: ReactNode;
  units?: ReactNode;
  direction?: 'row' | 'column';
  labelWidth?: string;
  canCopy?: boolean;
}

export const LabelValue = ({
  label,
  value,
  units,
  direction = 'row',
  labelWidth,
  canCopy = false,
}: IProps) => {
  const rootClass = clsx('label-value', `label-value--${direction}`);

  return (
    <div className={rootClass}>
      <Typography
        as="div"
        text={label}
        style={{ minWidth: labelWidth }}
        className="label-value__label"
      />
      <div className="label-value__value-wrapper">
        <Typography as="div" text={value} className="label-value__value" />
        {units && (
          <Typography as="div" text={units} className="label-value__units" />
        )}
        {canCopy && (
          <div className="label-value__copyIcon">
            <IconButton size="xs" variant="quietLayer3" content={<Copy />} />
          </div>
        )}
      </div>
    </div>
  );
};
