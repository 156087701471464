import { Navigate, Outlet } from 'react-router';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { LS } from '../../constants';

const getPreservedSearchParams = (
  search: string,
  paramsToPreserve?: string[]
) => {
  if (!paramsToPreserve?.length) return '';

  const searchParams = new URLSearchParams(search);
  const preservedParams = new URLSearchParams();

  paramsToPreserve.forEach((param) => {
    const value = searchParams.get(param);
    if (value) {
      preservedParams.set(param, value);
    }
  });

  return preservedParams.toString();
};

const getRedirectUrl = (
  pathname: string,
  search: string,
  signUpRedirectUrls?: string[],
  preserveSearchParams?: string[]
) => {
  const preservedQuery = getPreservedSearchParams(search, preserveSearchParams);

  if (signUpRedirectUrls?.includes(pathname)) {
    return `/${ROUTES.AUTH}/${ROUTES.SIGN_UP}${
      preservedQuery ? `?${preservedQuery}` : ''
    }`;
  }

  return `/${ROUTES.AUTH}/${ROUTES.SIGN_IN}${
    preservedQuery ? `?${preservedQuery}` : ''
  }`;
};

export const PrivatePage = () => {
  const { authenticated, signUpRedirectUrls, preserveSearchParams } =
    useAnalogAuthContext();

  const currentPagePathname: string = useMemo(() => {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }, []);

  if (!authenticated) {
    localStorage.setItem(LS.PreviousPage, currentPagePathname);

    const redirectTo = getRedirectUrl(
      window.location.pathname,
      window.location.search,
      signUpRedirectUrls,
      preserveSearchParams
    );

    return <Navigate to={redirectTo} replace={true} />;
  }

  return <Outlet />;
};
