import { IConfirmDialogProps } from './types';
import { useCallback, useState } from 'react';
import './index.css';
import { Toast } from '../Toast/Toast';
import { Dialog } from '../Dialog';

export const ConfirmDialog = (props: IConfirmDialogProps) => {
  const {
    onDismiss,
    title,
    subtitle,
    submitText,
    submitAction,
    cancelText,
    cancelAction,
    errorTitle,
    errorMessage,
    onResolve,
    onError,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    submitAction()
      .then(() => {
        if (onResolve) {
          onResolve();
        } else {
          onDismiss();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        } else {
          if (errorTitle) {
            Toast.error({
              title: errorTitle,
              message: errorMessage,
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [errorMessage, errorTitle, onDismiss, onError, onResolve, submitAction]);

  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      onDismiss={onDismiss}
      outsidePress={false}
      className="analog-confirm-dialog"
      cancelButtonProps={{
        variant: 'quietLayer3',
        onClick: cancelAction || onDismiss,
        content: cancelText || 'Cancel',
      }}
      submitButtonProps={{
        content: submitText,
        onClick: handleSubmit,
        loading: loading,
      }}
    />
  );
};
