import { useUsersStore } from '../store/users/slice';
import { UserRole } from '@cycling-web/common';
import { useMemo } from 'react';

type IReturn = {
  roles: UserRole[];
  isStaff: boolean;
  isAdmin: boolean;
  isSportsDirector: boolean;
  isExternalCoach: boolean;
  isCoach: boolean;
  isAthlete: boolean;
  isDoctor: boolean;
  isSecondaryDoctor: boolean;
  isNutritionist: boolean;
  isPhysiotherapist: boolean;
  isMentalCoach: boolean;
  isManagement: boolean;
};

export const useUserRole = (): IReturn => {
  const userProfile = useUsersStore((s) => s.userProfile);

  return useMemo((): IReturn => {
    const roles: UserRole[] = userProfile?.roles || [];

    const result: IReturn = {
      roles,
      isAdmin: false,
      isSportsDirector: false,
      isCoach: false,
      isExternalCoach: false,
      isAthlete: false,
      isDoctor: false,
      isSecondaryDoctor: false,
      isNutritionist: false,
      isPhysiotherapist: false,
      isMentalCoach: false,
      isManagement: false,
      isStaff: false,
    };

    roles.forEach((role) => {
      switch (role) {
        case UserRole.Admin:
          result.isAdmin = true;
          break;
        case UserRole.SportsDirector:
          result.isSportsDirector = true;
          break;
        case UserRole.Coach:
          result.isCoach = true;
          break;
        case UserRole.ExternalCoach:
          result.isExternalCoach = true;
          break;
        case UserRole.Athlete:
          result.isAthlete = true;
          break;
        case UserRole.Doctor:
          result.isDoctor = true;
          break;
        case UserRole.SecondaryDoctor:
          result.isSecondaryDoctor = true;
          break;
        case UserRole.Nutritionist:
          result.isNutritionist = true;
          break;
        case UserRole.Physiotherapist:
          result.isPhysiotherapist = true;
          break;
        case UserRole.MentalCoach:
          result.isMentalCoach = true;
          break;
        case UserRole.Management:
          result.isManagement = true;
          break;
        default:
      }
    });

    result.isStaff = !result.isAthlete;

    return result;
  }, [userProfile?.roles]);
};
