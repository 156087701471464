import { useTranslation } from 'react-i18next';
import {
  ConfirmDialog,
  IconButton,
  IMenuBaseOption,
  Menu,
  Toast,
  useBoolean,
} from '@cycling-web/analog-ui';
import { EllipsisVertical, Pencil, Trash2 } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { ISmartChartConfig } from '../../../../types/smart-charts';
import { SmartChartsRepository } from '../../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../../api/smart-charts/service';
import { usePinnedTabStore } from '../../../../store/pinned-tabs/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';
import { useParams } from 'react-router';
import { RenameSmartChartModal } from '../../../../components/RenameSmartChartModal';

type IProps = {
  chart: ISmartChartConfig;
};

export const PinnedChartMenu = ({ chart }: IProps) => {
  const { t } = useTranslation();
  const { pinnedTabId } = useParams();

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const {
    value: showDeleteUserModal,
    setTrue: openDeleteUserModal,
    setFalse: dismissDeleteUserModal,
  } = useBoolean(false);

  const {
    value: showRenameConversationModal,
    setTrue: openRenameConversationModal,
    setFalse: dismissRenameConversationModal,
  } = useBoolean(false);

  const onConversationRename = useCallback((config: ISmartChartConfig) => {
    usePinnedTabStore.getState().updateChart({
      id: config.id,
      ai_graph_meta_data: config.ai_graph_meta_data,
    });
  }, []);

  const handleOnDelete = useCallback((): Promise<void> => {
    if (!pinnedTabId) {
      return Promise.reject();
    }

    return smartChartsRepository
      .deletePinnedTab({
        graphId: chart.id,
        pinnedTabId: pinnedTabId,
      })
      .then(() => {
        usePinnedTabStore.getState().deleteChart(chart.id);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error({
            title: t('error.delete_pinned_chart_title'),
            message: t('error.delete_pinned_chart_message'),
          });
        }
      });
  }, [chart, pinnedTabId, smartChartsRepository, t]);

  const menuOptions: IMenuBaseOption[] = useMemo(() => {
    const options: IMenuBaseOption[] = [
      {
        id: 'rename',
        icon: <Pencil size={16} />,
        text: t('action.rename'),
      },
      {
        id: 'delete',
        icon: <Trash2 size={16} />,
        text: t('action.delete'),
      },
    ];
    return options;
  }, [t]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'delete') {
        openDeleteUserModal();
      }
      if (option.id === 'rename') {
        openRenameConversationModal();
      }
    },
    [openDeleteUserModal, openRenameConversationModal]
  );

  return (
    <>
      <Menu
        options={menuOptions}
        onChange={onMenuChange}
        dropdownProps={{
          anchor: (
            <IconButton variant="layer3" content={<EllipsisVertical />} />
          ),
          placement: 'bottom-end',
        }}
      />

      {showDeleteUserModal && (
        <ConfirmDialog
          onDismiss={dismissDeleteUserModal}
          title={t('confirm.delete_chart_title')}
          subtitle={t('confirm.delete_chart_subtitle')}
          submitText={t('action.delete')}
          submitAction={handleOnDelete}
          errorTitle={t('error.delete_chart_title')}
          errorMessage={t('error.delete_group_message')}
        />
      )}

      {showRenameConversationModal && (
        <RenameSmartChartModal
          conversation={chart}
          onDismiss={dismissRenameConversationModal}
          onSubmitSuccess={onConversationRename}
        />
      )}
    </>
  );
};
