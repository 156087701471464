import { SeriesOption } from 'echarts';
import merge from 'lodash/merge';
import { defaultOption } from '../constants/defaults';
import { ChartColor } from '../constants/colors';
import { IEChartOption } from '../types';

export const prepareChart = (option: IEChartOption): IEChartOption => {
  const isRadarChart = !!option.radar;

  if (isRadarChart) {
    return option;
  }

  const nextOption: IEChartOption = {
    ...defaultOption,
    ...option,
  };

  Object.keys(defaultOption).forEach((key) => {
    if (key in option) {
      if (Array.isArray(option[key])) {
        const defaultOptionArray = option[key].map(() => defaultOption[key]);
        nextOption[key] = merge([], defaultOptionArray, option[key]);

        if (key === 'series') {
          nextOption[key] = prepareSeries(nextOption[key] as SeriesOption[]);
        }
      } else {
        nextOption[key] = merge({}, defaultOption[key], option[key]);
      }
    }
  });

  return nextOption;
};

function prepareSeries(series: SeriesOption[]) {
  return series.map((s) => {
    if (s.type === 'bar') {
      s.itemStyle = {
        ...s.itemStyle,
        borderRadius: [8, 8, 0, 0],
      };
    }
    if (s.type === 'scatter') {
      s.itemStyle = {
        ...s.itemStyle,
        borderColor: ChartColor.ScatterBorder,
        borderWidth: 1,
      };
    }
    return s;
  });
}
