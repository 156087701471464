import './index.css';
import { useAppStore } from '../../store/app/slice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConsentDialog } from '../../components/data-sharing/ConsentDialog';
import { PlatformRepository } from '../../api/platform/repository';
import { PlatformService } from '../../api/platform/service';
import { Toast } from '@cycling-web/analog-ui';
import { AxiosError } from 'axios';
import { ConsentSuccess } from './ConsentSuccess';
import { ConsentDeclined } from './ConsentDeclined';

enum IStep {
  Idle = 'idle',
  Success = 'success',
  Declined = 'declined',
}

export const Consent = () => {
  const idTokenRef = useRef<string>('');
  const [step, setStep] = useState<IStep>(IStep.Idle);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  useEffect(() => {
    useAppStore.getState().setLoading(false);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    idTokenRef.current = params.get('id_token') || '';
  }, []);

  const handleDecline = useCallback(() => {
    setStep(IStep.Declined);
  }, []);

  const handleGiveConsent = useCallback((): Promise<void> => {
    return platformRepository
      .consentMyWhoosh({
        id_token: idTokenRef.current,
      })
      .then(() => {
        setStep(IStep.Success);
      })
      .catch((e: AxiosError<{ message: string }>) => {
        const message = e.response?.data?.message;
        Toast.error({
          title: message,
        });
      });
  }, [platformRepository]);

  return (
    <div className="analog-integration-consent">
      {step === IStep.Idle && (
        <ConsentDialog
          onDismiss={() => {}}
          onDecline={handleDecline}
          onSubmit={handleGiveConsent}
          showDismissButton={false}
        />
      )}
      {step === IStep.Success && <ConsentSuccess />}
      {step === IStep.Declined && <ConsentDeclined />}
    </div>
  );
};
