import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { SmartChartsRepository } from '../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../api/smart-charts/service';
import {
  ISmartChartConfig,
  ISmartChartTurn,
} from '../../../types/smart-charts';
import { useSmartChartsStore } from '../store/slice';
import { useParams } from 'react-router';
import { AbortError } from '../../../constants';

type IProps = {
  isNewConversation: RefObject<boolean>;
  conversationBuffer: RefObject<ISmartChartTurn[]>;
};

export const useFetchConversation = ({
  isNewConversation,
  conversationBuffer,
}: IProps) => {
  const { chartId } = useParams();
  const abortController = useRef<AbortController | null>(null);

  const setConfig = useSmartChartsStore((s) => s.setConfig);
  const setChartLoaded = useSmartChartsStore((s) => s.setChartLoaded);
  const reloadChartCounter = useSmartChartsStore((s) => s.reloadChartCounter);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const fetchConversation = useCallback(() => {
    if (!chartId) {
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    const signal = abortController.current.signal;

    setChartLoaded(false);
    const payload = {
      graphId: chartId,
    };
    let withComputedData = false;

    smartChartsRepository
      .getConfiguration(payload, { signal })
      .then((config: ISmartChartConfig) => {
        if (isNewConversation.current) {
          isNewConversation.current = false;
          const nextConfig: ISmartChartConfig = {
            ...config,
            conversation_turns: {
              turns: [...conversationBuffer.current],
              page_no: 1,
              limit: 1,
            },
          };
          setChartLoaded(true);
          conversationBuffer.current = [];
          return nextConfig;
        }
        withComputedData = true;
        setConfig(config);

        return smartChartsRepository.getComputeData(payload, { signal });
      })
      .then((config: ISmartChartConfig) => {
        setConfig(config);
        if (withComputedData) {
          setChartLoaded(true);
        }
        abortController.current = null;
      })
      .catch((e) => {
        if (e.name !== AbortError) {
          abortController.current = null;
          if (withComputedData) {
            setChartLoaded(true);
          }
        }
      });
  }, [
    chartId,
    conversationBuffer,
    isNewConversation,
    setChartLoaded,
    setConfig,
    smartChartsRepository,
    reloadChartCounter,
  ]);

  useEffect(() => {
    fetchConversation();
  }, [fetchConversation]);

  return {
    fetchConversation,
  };
};
