import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { AthletesRepository } from '../../../../api/athletes/repository';
import { AthletesService } from '../../../../api/athletes/service';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IProfileDetailForm } from './types';
import { IAthlete } from '../../../../types/athletes';
import {
  localWithUTCMidnightTimestamp,
  UTCMidnightToSameDate,
} from '../../../../utils/date-time';
import { AxiosError } from 'axios';
import { Toast } from '@cycling-web/analog-ui';
import { ApiErrorCode } from '../../../../constants';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useUsersStore } from '../../../../store/users/slice';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { CoachRepository } from '../../../../api/coach/repository';
import { CoachService } from '../../../../api/coach/service';
import { useStaffStore } from '../../../../store/staff/slice';
import { IStaff } from '../../../../types/coach';

type IProps = {
  onDismiss: () => void;
};

export const useViewModel = ({ onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { isAthlete } = useUserRole();
  const athlete = useAthleteStore((s) => s.athlete);
  const staff = useStaffStore((s) => s.staff);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const coachRepository = useMemo(() => {
    return new CoachRepository(new CoachService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        firstName: z.string().min(1, { message: t('validation.required') }),
        lastName: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const defaultValues = useMemo(() => {
    const firstName = isAthlete ? athlete?.firstName : staff?.firstName;
    const lastName = isAthlete ? athlete?.lastName : staff?.lastName;
    const phone = isAthlete ? athlete?.phone : staff?.phone;
    const dobTimeStampUTC = isAthlete ? athlete?.dobTimeStampUTC : undefined;
    const timezone = isAthlete ? athlete?.timezone : undefined;
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      phone: phone || '',
      dobTimeStampUTC: dobTimeStampUTC
        ? UTCMidnightToSameDate(dobTimeStampUTC)
        : undefined,
      timezone: timezone || '',
    };
  }, [isAthlete, athlete, staff]);

  const form = useForm<IProfileDetailForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const formatDate = useCallback((date: Date) => {
    return date;
  }, []);

  const updateAthlete = useCallback(
    (formData: IProfileDetailForm): Promise<void> => {
      if (!athlete) {
        return Promise.reject();
      }

      return athletesRepository
        .updateProfile({
          ...athlete,
          ...formData,
          fullName: `${formData?.firstName} ${formData?.lastName}`,
          dobTimeStampUTC: localWithUTCMidnightTimestamp(
            formData.dobTimeStampUTC
          ),
        })
        .then((updatedAthlete: IAthlete) => {
          useAthleteStore.getState().updateAthlete({
            ...athlete,
            ...updatedAthlete,
          });
        });
    },
    [athlete, athletesRepository]
  );

  const updateStaff = useCallback(
    (formData: IProfileDetailForm): Promise<void> => {
      if (!staff) {
        return Promise.reject();
      }

      return coachRepository
        .updateProfile({
          ...staff,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          fullName: `${formData?.firstName} ${formData?.lastName}`,
        })
        .then((updatedAthlete: IStaff) => {
          useStaffStore.getState().updateProfile({
            ...staff,
            ...updatedAthlete,
          });
        });
    },
    [staff, coachRepository]
  );

  const onSubmit = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      handleSubmit(
        (formData: IProfileDetailForm) => {
          setLoading(true);

          const response = isAthlete
            ? updateAthlete(formData)
            : updateStaff(formData);

          response
            .then(() => {
              useUsersStore.getState().updateUserProfile({
                firstName: formData.firstName,
                lastName: formData.lastName,
                fullName: `${formData.firstName} ${formData.lastName}`,
              });
              resolve(true);
              onDismiss();
            })
            .catch((error: AxiosError) => {
              if (error?.response?.status !== ApiErrorCode.Unauthorized) {
                Toast.error({
                  title: t('error.update_profile_title'),
                  message: t('error.update_profile_message'),
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        },
        () => {
          return resolve(false);
        }
      )();
    });
  }, [handleSubmit, isAthlete, onDismiss, t, updateAthlete, updateStaff]);

  return {
    form,
    onSubmit,
    formatDate,
    loading,
    onDismiss,
    t,
    formState,
    isAthlete,
  };
};
