import './index.css';
import { FormProvider } from 'react-hook-form';
import { Dialog, FormElement } from '@cycling-web/analog-ui';
import { DatepickerControl, InputControl } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { PhoneControl } from '../../../../components/PhoneControl';
import { TimezoneControl } from '../../../../components/controls/TimezoneControl';

type IProps = {
  onDismiss: () => void;
};

export const ProfileDetailsFormModal = (props: IProps) => {
  const { t, form, onDismiss, onSubmit, loading, formState, isAthlete } =
    useViewModel(props);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.user_info')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="profile-details__form-modal">
          <FormElement
            label={t('label.first_name')}
            message={formState.errors.firstName?.message}
          >
            <InputControl
              name="firstName"
              placeholder={t('placeholder.first_name')}
              invalid={!!formState.errors.firstName}
            />
          </FormElement>

          <FormElement
            label={t('label.last_name')}
            message={formState.errors.lastName?.message}
          >
            <InputControl
              name="lastName"
              placeholder={t('placeholder.last_name')}
              invalid={!!formState.errors.lastName}
            />
          </FormElement>

          {isAthlete && (
            <FormElement
              label={t('label.date_of_birth')}
              message={formState.errors.dobTimeStampUTC?.message}
            >
              <DatepickerControl
                name="dobTimeStampUTC"
                placeholder={t('placeholder.date')}
                invalid={!!formState.errors.dobTimeStampUTC}
              />
            </FormElement>
          )}

          <FormElement
            label={t('label.phone_number')}
            message={formState.errors.phone?.message}
          >
            <PhoneControl
              name="phone"
              placeholder={t('placeholder.phone_number')}
              invalid={!!formState.errors.phone}
            />
          </FormElement>

          {isAthlete && <TimezoneControl />}
        </div>
      </Dialog>
    </FormProvider>
  );
};
