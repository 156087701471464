import {
  IEChartGridOption,
  IEChartXAXisOption,
  IEChartYAXisOption,
} from '../../../components/Chart';

type IProps = {
  xAxis: IEChartXAXisOption[];
  yAxis: IEChartYAXisOption[];
};

export const computeGrid = (props: IProps): IEChartGridOption => {
  const { yAxis } = props;
  const xOffset = 60;
  const topOffset = 24;
  const bottomOffset = 42;
  let leftOffset = 0;
  let rightOffset = 0;

  yAxis.forEach((axis) => {
    if (axis.position === 'left') {
      leftOffset += xOffset;
    } else {
      rightOffset += xOffset;
    }
  });

  return {
    top: topOffset,
    right: rightOffset,
    bottom: bottomOffset,
    left: leftOffset,
  };
};
