import * as echarts from 'echarts';
import {
  IEChartGridOption,
  IEChartXAXisOption,
  IEChartYAXisOption,
} from '../types';

export const changeAfterRender = (chart: echarts.EChartsType) => {
  const appliedOption = chart.getOption();

  if (appliedOption.radar) {
    return;
  }

  appliedOption.grid = (appliedOption.grid as IEChartGridOption[]).map(
    (grid) => {
      (appliedOption.yAxis as IEChartYAXisOption[]).forEach((axis) => {
        if (axis.offset) {
          if (typeof grid.right === 'number' && axis.position === 'right') {
            grid.right = (grid.right || 0) + axis.offset;
          }
          if (typeof grid.left === 'number' && axis.position === 'left') {
            grid.left = (grid.left || 0) + axis.offset;
          }
        }
      });

      (appliedOption.xAxis as IEChartXAXisOption[]).forEach((axis) => {
        if (axis.offset) {
          if (typeof grid.top === 'number' && axis.position === 'top') {
            grid.top = (grid.top || 0) + axis.offset;
          }
          if (typeof grid.bottom === 'number' && axis.position === 'bottom') {
            grid.bottom = (grid.bottom || 0) + axis.offset;
          }
        }
      });

      return grid;
    }
  );

  chart.setOption(appliedOption);
};
