import { use, useCallback, useMemo } from 'react';
import { AuthRepository } from '../../api/auth/repository';
import { AuthPageContext } from '../../context/AuthPageContext';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../router/routes';
import { OtpForm } from '../OtpForm';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordOtp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { continuationTokenRef } = use(AuthPageContext);
  const { authOptions } = useAnalogAuthContext();

  const authRepository = useMemo(() => {
    return AuthRepository.getInstance(authOptions);
  }, [authOptions]);

  const sendNewCode = useCallback(() => {
    return authRepository
      .resetPasswordChallenge({
        challenge_type: 'oob redirect',
        continuation_token: continuationTokenRef.current,
      })
      .then(() => {
        //
      });
  }, [authRepository, continuationTokenRef]);

  const onSubmit = useCallback(
    (code: string): Promise<void> => {
      return authRepository
        .resetPasswordContinue({
          continuation_token: continuationTokenRef.current,
          grant_type: 'oob',
          oob: code,
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          navigate(`/${ROUTES.AUTH}/${ROUTES.NEW_PASSWORD}`, {
            replace: true,
          });
        });
    },
    [authRepository, continuationTokenRef, navigate]
  );

  return (
    <OtpForm
      title={t('label.forgot_password')}
      onSuccess={onSubmit}
      sendNewCode={sendNewCode}
      backUrl={`/${ROUTES.AUTH}/${ROUTES.FORGOT_PASSWORD}`}
    />
  );
};
