import {
  ISmartChartAxis,
  ISmartChartConfig,
  ISmartChartPlot,
} from '../../../types/smart-charts';
import { IChartSource, IEChartYAXisOption } from '../../../components/Chart';
import { IAthlete } from '../../../types/athletes';

type IProps = {
  config: ISmartChartConfig;
  source: IChartSource;
  isLogScale: boolean;
  athletes: IAthlete[];
};

export const getYAxisPosition = (id: string, yAxisMetaData: any) => {
  const yAxisData = yAxisMetaData?.find((axis: any) => {
    return axis?.linked_axis_ids?.includes(id);
  });
  return yAxisData?.position;
};

/** Compute Y Axis */
export const computeYAxis = (props: IProps): IEChartYAXisOption[] => {
  const { config } = props;
  const graph = config?.ai_graph_in_scope?.ai_generated_graphs[0];
  const plots = graph?.plots || [];
  const computedData = graph?.computed_data;

  if (!graph) {
    return [];
  }

  // @ts-ignore
  return graph.y_axes.map((axis: ISmartChartAxis, index: number) => {
    const matchingPlot = plots?.find((plot: ISmartChartPlot) => {
      return axis.linked_plot_ids?.includes(plot.id);
    });

    return {
      type: 'value',
      name: axis.unit_readable_short_name,
      nameTextStyle: {
        opacity: 1,
        color: matchingPlot?.plot_view_metadata?.color_name,
      },
      nameGap: 8,
      position: getYAxisPosition(axis.id, computedData?.y_axes_data),
      axisLabel: {
        formatter: `{value}`,
        color: matchingPlot?.plot_view_metadata?.color_name,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: matchingPlot?.plot_view_metadata?.color_name,
        },
      },
      ...(index > 1 && { offset: 30 * index }),
      min: axis.range_automatic_min_value ? undefined : axis.range_min_value,
      max: axis.range_automatic_max_value ? undefined : axis.range_max_value,
    };
  });
};
