import {
  ISmartChartConfig,
  ISmartChartPlot,
} from '../../../types/smart-charts';
import { IAthlete } from '../../../types/athletes';
import { IChartSource } from '../../../components/Chart';
import { CurveThresholds, smoothCurveDelegate } from '../../../utils/charts';

type IProps = {
  config: ISmartChartConfig;
  athletes: IAthlete[];
};

export const computeSource = (props: IProps): IChartSource => {
  const { config, athletes } = props;
  const graph = config?.ai_graph_in_scope?.ai_generated_graphs[0];

  if (!graph?.computed_data) {
    return [];
  }

  const plotsMetaMap: Record<string, ISmartChartPlot> = {};

  graph.plots.forEach((plot) => {
    plotsMetaMap[plot.id] = plot;
  });

  const isLogScale = graph?.x_axes[0]?.range_log_scaled;
  const series: IChartSource = [];
  const indices: number[] = [];

  graph.computed_data.x_axes_data.forEach((data) => {
    series.push([data.linked_axis_ids[0]]);
  });

  if (isLogScale) {
    const data = graph.computed_data.plots_data[0];
    const dataPoints = Object.values(data.series_key_value_data);

    let currentThresholdIndex = 0;

    for (let i = 1; i < dataPoints.length; i++) {
      while (i >= CurveThresholds[currentThresholdIndex].seconds) {
        currentThresholdIndex++;
        if (currentThresholdIndex >= CurveThresholds.length) break;
      }

      if (i % CurveThresholds[currentThresholdIndex].step === 0) {
        series.push([Math.log2(i)]);
        indices.push(i);
      }
    }
  } else {
    graph.computed_data.x_axes_data.forEach((data) => {
      data.series_only_values_data?.forEach((value) => {
        series.push([value]);
      });
    });
  }

  graph.computed_data.plots_data.forEach((data, index) => {
    if (!series[0][index + 1]) {
      series[0][index + 1] =
        athletes.length > 1 ? data.readable_name_with_datasource : data.name;
    }
    const dataPoints = Object.values(data.series_key_value_data);

    if (isLogScale) {
      const needsSmoothening =
        plotsMetaMap[data.linked_plot_id]?.plot_view_metadata?.is_smoothening;

      function append(i: number, actualIndex: number) {
        if (needsSmoothening) {
          series[i].push(smoothCurveDelegate(dataPoints, actualIndex));
        } else {
          series[i].push(dataPoints[actualIndex]);
        }
      }

      for (let i = 1; i < series.length; i++) {
        // i-1, because indices start with 0, but this loop starts with 1
        // another -1 is because "dataPoints" start with 0, but "series" values start with 1
        const actualIndex = indices[i - 1] - 1;
        append(i, actualIndex);
      }
    } else {
      dataPoints.forEach((value, index) => {
        series[index + 1].push(value);
      });
    }
  });

  return series;
};
