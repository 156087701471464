import React, { ErrorInfo, useCallback, useMemo } from 'react';
import AnalogSportLogoDark from './assets/illustrations/analog-sport-logo-lg.svg?react';
import type {
  IAnalogAuthProviderProps,
  IOAuthTokenResponse,
} from '@cycling-web/auth';
import { AnalogAuthProvider } from '@cycling-web/auth';
import { useNavigate, useRoutes } from 'react-router';
import './App.css';
import './ms/app-insights';
import { IMenuBaseOption, ToastContainer } from '@cycling-web/analog-ui';
import { routes } from './router';
import { ErrorBoundary } from 'react-error-boundary';
import { ILayoutContext, LayoutContext } from '@cycling-web/common';
import { useUsersStore } from './store/users/slice';
import { useTranslation } from 'react-i18next';
import { ROUTES } from './router/routes';
import { useAppStore } from './store/app/slice';
import { SUPPORT_EMAIL } from './constants';
import { Cookies } from './pages/Legal/components/Cookies';
import { CookieContextProvider } from './pages/Legal/context/cookieContext';
import { trackError } from './ms/log-insights';
import { getApiUrl, getCredentials } from './utils/getEnvironment';
import { UsersRepository } from './api/users/repository';
import { UsersService } from './api/users/service';

export const App = () => {
  const { t } = useTranslation();
  const router = useRoutes(routes);
  const user = useUsersStore((s) => s.userProfile);
  const userLoaded = useUsersStore((s) => s.userProfileLoaded);
  const navigate = useNavigate();
  const showCookiesBanner = useAppStore((s) => s.showCookiesBanner);

  const authContext: ILayoutContext = useMemo((): ILayoutContext => {
    return {
      user,
      userLoaded,
      userMenuOptions: [
        {
          id: 'teams',
          text: t('label.teams'),
        },
        {
          id: 'legal',
          text: t('label.legal'),
        },
        {
          id: 'support',
          text: t('label.support'),
        },
        {
          id: 'settings',
          text: t('label.settings'),
        },
      ],
      handleUserMenuChange: (option: IMenuBaseOption) => {
        if (option.id === 'teams') {
          navigate(`/${ROUTES.TEAMS}`);
        }
        if (option.id === 'legal') {
          window.open(`/${ROUTES.LEGAL}`, '_blank');
          return;
        }
        if (option.id === 'settings') {
          const baseUrl = window.location.pathname
            .split('/')
            .filter((_, i: number) => i < 3)
            .join('/');
          navigate(`${baseUrl}/${ROUTES.SETTINGS}`);
        }
        if (option.id === 'support') {
          window.location.href = `mailto:${SUPPORT_EMAIL}`;
        }
      },
      handleSignOut: () => {
        navigate(`/${ROUTES.SIGN_OUT}`);
      },
    };
  }, [navigate, t, user, userLoaded]);

  const onError = useCallback((error: Error, info: ErrorInfo) => {
    trackError(error, info);
  }, []);

  const onLogin = useCallback(
    (response: IOAuthTokenResponse): Promise<void> => {
      const usersRepository = new UsersRepository(new UsersService());
      return usersRepository
        .checkRegistration({
          idToken: response.id_token,
          accessToken: response.access_token,
        })
        .then(() => {
          navigate(`/${ROUTES.TEAMS}`, { replace: true });
        });
    },
    [navigate]
  );

  const analogAuthContext = useMemo((): IAnalogAuthProviderProps => {
    return {
      authOptions: {
        proxyApiUrl: `${getApiUrl()}/v1/proxy`,
        client_id: getCredentials().clientId,
        subdomain: getCredentials().subdomain,
      },
      logo: <AnalogSportLogoDark width={230} />,
      redirectWhenAuthenticated: `/${ROUTES.TEAMS}`,
      translations: {
        signup_welcome_title: t('label.signup_welcome_title'),
        signup_welcome_subtitle: t('label.signup_welcome_text'),
        login_welcome_title: t('label.login_welcome_title'),
        login_welcome_subtitle: t('label.login_welcome_text'),
      },
      onSignIn: onLogin,
      onSignUp: onLogin,
      preserveSearchParams: ['email'],
      signUpRedirectUrls: [`/${ROUTES.TEAM_INVITATION}`],
    };
  }, [onLogin, t]);

  return (
    <AnalogAuthProvider {...analogAuthContext}>
      <LayoutContext.Provider value={authContext}>
        <ErrorBoundary fallback={<div />} onError={onError}>
          <CookieContextProvider>
            <div className="app">
              {router}
              <ToastContainer />
              {showCookiesBanner && <Cookies />}
            </div>
          </CookieContextProvider>
        </ErrorBoundary>
      </LayoutContext.Provider>
    </AnalogAuthProvider>
  );
};
