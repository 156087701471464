import { IUsersSlice, IUsersState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IUserProfile } from '../../types/users';
import { OnboardingStep } from '@cycling-web/common';

export const initialState: IUsersState = {
  userProfile: null,
  userProfileLoaded: false,
};

export const useUsersStore = create<IUsersSlice>()(
  immer((set) => ({
    ...initialState,
    setUserProfile: (userProfile: IUserProfile | null) => {
      set((state) => {
        state.userProfile = userProfile;
        state.userProfileLoaded = true;
      });
    },
    setUserProfileLoaded: (loaded: boolean) => {
      set((state) => {
        state.userProfileLoaded = loaded;
      });
    },
    setOnboardingStep: (step: OnboardingStep) => {
      set((state) => {
        if (state.userProfile) {
          state.userProfile.onboardingStatus = step;
        }
      });
    },
    updateUserProfile: (userProfile: Partial<IUserProfile>) => {
      set((state) => {
        if (state.userProfile) {
          state.userProfile = {
            ...state.userProfile,
            ...userProfile,
          };
        }
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
