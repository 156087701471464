import './index.css';
import { Button, FormElement, Spinner, Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormView } from '../FormView';

type IForm = {
  code: string;
};

type IProps = {
  title?: string;
  backUrl: string;
  onSuccess: (code: string) => Promise<any>;
  sendNewCode: () => Promise<void>;
};

const T = 60;

export const OtpForm = ({ title, onSuccess, sendNewCode, backUrl }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [timer, setTimer] = useState(T);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            setIsDisabled(false);
            clearInterval(interval);
            return T;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isDisabled]);

  const handleResendCode = useCallback(() => {
    if (isDisabled || isFetching) {
      return;
    }
    setIsFetching(true);
    sendNewCode()
      .then(() => {
        setIsDisabled(true);
      })
      .catch(() => {
        Toast.error({
          title: t('error.send_otp_title'),
        });
        setIsDisabled(false);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [isDisabled, isFetching, sendNewCode, t]);

  const schema = useMemo(() => {
    return z.object({
      code: z.string().min(1, { message: t('validation.required') }),
    });
  }, [t]);

  const form = useForm<IForm>({
    defaultValues: {
      code: '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState, setError } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      setLoading(true);
      onSuccess(formData.code)
        .catch((e) => {
          setError('code', {
            message: t('validation.invalid_otp'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onSuccess, setError, t]
  );

  const handleBackClick = useCallback(() => {
    navigate(backUrl, { replace: true });
  }, [navigate, backUrl]);

  return (
    <FormProvider {...form}>
      <FormView title={title} subtitle={t('label.enter_otp_message')}>
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.otp')}
            message={formState.errors.code?.message}
          >
            <InputControl
              name="code"
              size="l"
              placeholder={t('placeholder.otp')}
              autoFocus={true}
              autoComplete="off"
              invalid={!!formState.errors.code}
            />
          </FormElement>

          <div className="analog-auth__otp-suggestion">
            <span className="analog-auth__otp-suggestion-message analog-typography--body">
              {isDisabled
                ? t('label.request_new_otp_await', { n: timer })
                : t('label.did_not_receive_otp')}
            </span>
            {!isDisabled && (
              <button
                type="button"
                className="analog-auth__otp-suggestion-button analog-typography--button-m"
                onClick={handleResendCode}
                disabled={isDisabled}
              >
                {t('action.send_new_code')}
              </button>
            )}
            {!isDisabled && isFetching && <Spinner size="s" />}
          </div>

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              size="l"
              content={t('action.continue')}
              fullWidth
              loading={loading}
            />

            <Button
              type="button"
              size="l"
              variant="quietLayer3"
              content={t('action.back')}
              fullWidth
              onClick={handleBackClick}
            />
          </div>
        </form>
      </FormView>
    </FormProvider>
  );
};
