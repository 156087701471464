import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  offset as floatOffset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFocus,
  useHover,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { useControlledValue } from '../../hooks/useControlledValue';
import { IDropdownContext, IDropdownProps } from './types';
import './index.css';
import { clsx } from 'clsx';
import { DropdownContext } from './context';

export const Dropdown = (props: IDropdownProps) => {
  const {
    isOpen,
    setIsOpen,
    defaultIsOpen,
    anchor,
    content,
    portal = true,
    placement = 'bottom-start',
    offset = 8,
    delay = 200,
    maxWidth,
    minWidth,
    minHeight,
    maxHeight,
    showArrow = false,
    outsidePress = true,
    interactions = ['click', 'dismiss'],
    className,
    contentClassName,
    disabled,
    activeIndex: controlledActiveIndex,
    setActiveIndex: controlledSetActiveIndex,
    nested = false,
    freezeWidth = false,
  } = props;

  const { value: activeIndex, onChange: setActiveIndex } = useControlledValue<
    number | null
  >({
    controlledValue: controlledActiveIndex,
    controlledOnChange: controlledSetActiveIndex,
    defaultValue: null,
  });
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const navigationRef = useCallback((index: number) => {
    return (node: HTMLButtonElement): void => {
      listRef.current[index] = node;
    };
  }, []);

  const [contentWidth, setContentWidth] = useState<string>('auto');
  const arrowRef = useRef<SVGSVGElement>(null);
  const nodeId = useFloatingNodeId();

  const { value: open, onChange: onOpenChange } = useControlledValue<boolean>({
    controlledValue: isOpen,
    controlledOnChange: setIsOpen,
    defaultValue: defaultIsOpen,
  });

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: placement,
    nodeId,
    whileElementsMounted: autoUpdate,
    middleware: [
      floatOffset(offset),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: minWidth,
            minHeight: minHeight,
            maxWidth: maxWidth || `${availableWidth}px`,
            maxHeight: maxHeight || `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const click = useClick(context, {
    enabled: !disabled && interactions.includes('click'),
  });
  const hover = useHover(context, {
    delay,
    enabled: !disabled && interactions.includes('hover'),
  });
  const focus = useFocus(context, {
    enabled: !disabled && interactions.includes('focus'),
  });
  const role = useRole(context);
  const dismiss = useDismiss(context, {
    outsidePress: (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      return outsidePress;
    },
    enabled: !disabled && interactions.includes('dismiss'),
  });
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex: activeIndex ?? null,
    onNavigate: setActiveIndex,
    enabled: !disabled && interactions.includes('listNavigation'),
    nested,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, hover, focus, role, dismiss, listNavigation]
  );

  useEffect((): void => {
    if (!refs.reference.current || !freezeWidth) {
      return;
    }

    const width: number = refs.reference.current.getBoundingClientRect().width;
    setContentWidth(`${width}px`);
  }, [refs, freezeWidth]);

  const contentStyle: CSSProperties = {
    ...floatingStyles,
    width: contentWidth,
  };

  const contentClass = clsx('analog-dropdown__content', contentClassName);
  const dropdownContent = (
    <div
      className={contentClass}
      ref={refs.setFloating}
      style={contentStyle}
      {...getFloatingProps()}
    >
      {showArrow && (
        <FloatingArrow
          ref={arrowRef}
          context={context}
          className="analog-dropdown__floatingArrow"
          fill="red"
        />
      )}
      {content}
    </div>
  );

  const rootClass = clsx('analog-dropdown', className);

  const dropdownContext: IDropdownContext = useMemo(() => {
    return {
      navigationRef,
      getItemProps,
      context,
      activeIndex: activeIndex ?? null,
      contentWidth,
    };
  }, [navigationRef, getItemProps, context, activeIndex, contentWidth]);

  return (
    <FloatingFocusManager context={context}>
      <DropdownContext.Provider value={dropdownContext}>
        <div className={rootClass}>
          <div
            className="analog-dropdown__anchor"
            ref={refs.setReference}
            {...getReferenceProps({
              role: 'button',
              'aria-haspopup': 'menu',
              'aria-expanded': open,
              'aria-controls': 'dropdown-menu',
            })}
          >
            {anchor}
          </div>
          <FloatingNode id={nodeId}>
            {open &&
              (portal ? (
                <FloatingPortal>{dropdownContent}</FloatingPortal>
              ) : (
                dropdownContent
              ))}
          </FloatingNode>
        </div>
      </DropdownContext.Provider>
    </FloatingFocusManager>
  );
};
