import { BodyMap } from '../body-map/body-map';
import { useWatch } from 'react-hook-form';
import { MedicalRecordType } from '../../types/medical';

export const BodyMapView = () => {
  const [area, side, severity] = useWatch({
    name: ['area', 'side', 'severity'],
  });

  return (
    <BodyMap
      recordType={MedicalRecordType.Injury}
      selectedSide={side}
      selectedBodyLocation={''}
      selectedArea={area}
      selectedSymptom=""
      selectedSeverity={severity}
    />
  );
};
