import { IAppSlice, IAppState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AxiosError } from 'axios';
import { LS } from '../../constants';
import { DeepPartial, ICookieConsent } from '@cycling-web/common';
import merge from 'lodash/merge';
import { CURRENT_COOKIES_VERSION } from '../../pages/Legal/context/cookieContext';
import { differenceInYears } from 'date-fns';

const cookiesLS = localStorage.getItem(LS.Cookies);
const cookies: ICookieConsent = cookiesLS
  ? JSON.parse(cookiesLS)
  : {
      essential: {
        all: true,
      },
      optional: {
        analytical: false,
      },
    };
const cookiesVersionLS = localStorage.getItem(LS.CookiesVersion);
const cookiesVersion = cookiesVersionLS
  ? JSON.parse(cookiesVersionLS)
  : undefined;
const noConsentGiven = !localStorage.getItem(LS.Cookies);
const cookieVersionChanged =
  cookiesVersion?.v !== CURRENT_COOKIES_VERSION.toString();
const cookiesExpired = cookiesVersion
  ? differenceInYears(Date.now(), cookiesVersion.d) > 1
  : false;

export const initialState: IAppState = {
  loading: false,
  error: null,
  cookies,
  showCookiesBanner: noConsentGiven || cookieVersionChanged || cookiesExpired,
};

export const useAppStore = create<IAppSlice>()(
  immer((set) => ({
    ...initialState,
    setLoading: (loading: boolean) => {
      set((state) => {
        state.loading = loading;
      });
    },
    setCookies: (nextCookies: DeepPartial<ICookieConsent> | undefined) => {
      set((state) => {
        if (state.cookies) {
          state.cookies = { ...merge(state.cookies, nextCookies) };
        }
      });
    },
    setShowCookiesBanner: (showCookiesBanner: boolean) => {
      set((state) => {
        state.showCookiesBanner = showCookiesBanner;
      });
    },
    setError: (error: AxiosError | null) => {
      set((state) => {
        state.error = error;
      });
    },
  }))
);
