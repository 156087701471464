import { IPinnedTabSlice, IPinnedTabState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DateRange } from '../../types/enums';
import { IPinnedTabsFilters } from '../../pages/PinnedTab/types';
import { IPinnedTab, ISmartChartConfig } from '../../types/smart-charts';
import { DeepPartial } from '@cycling-web/common';
import merge from 'lodash/merge';

export const initialFilters: IPinnedTabsFilters = {
  period: DateRange.ThreeMonth,
};

export const initialState: IPinnedTabState = {
  filters: {
    period: initialFilters.period,
  },
  pinnedTabs: [],
  pinnedTabsLoaded: false,
  charts: [],
  chartsLoaded: false,
};

export const usePinnedTabStore = create<IPinnedTabSlice>()(
  immer((set) => ({
    ...initialState,
    setFilters: (filters: Partial<IPinnedTabsFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    setPinnedTabs: (pinnedTabs: IPinnedTab[]) => {
      set((state) => {
        state.pinnedTabs = pinnedTabs;
        state.pinnedTabsLoaded = true;
      });
    },
    setPinnedTabsLoaded: (pinnedTabsLoaded: boolean) => {
      set((state) => {
        state.pinnedTabsLoaded = pinnedTabsLoaded;
      });
    },
    removeGraphFromTab: (tabId: string, graphId: string) => {
      set((state) => {
        const tab = state.pinnedTabs.find((tab) => tab.tab_id === tabId);
        if (tab) {
          tab.tab_graphs = tab.tab_graphs.filter(
            (graph) => graph.graph_id !== graphId
          );
        }
      });
    },
    setCharts: (charts: ISmartChartConfig[]) => {
      set((state) => {
        state.charts = charts;
        state.chartsLoaded = true;
      });
    },
    deleteChart: (id: string) => {
      set((state) => {
        state.charts = state.charts.filter(
          (chart: ISmartChartConfig) => chart.id !== id
        );
      });
    },
    updateChart: (config: DeepPartial<ISmartChartConfig>) => {
      set((state) => {
        const index = state.charts.findIndex((c) => c.id === config.id);
        if (index >= 0) {
          state.charts[index] = merge({}, state.charts[index], config);
        }
      });
    },
    setChartsLoaded: (loaded: boolean) => {
      set((state) => {
        state.chartsLoaded = loaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
