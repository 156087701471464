import { BodyMap } from '../body-map/body-map';
import { useWatch } from 'react-hook-form';
import { MedicalRecordType } from '../../types/medical';

export const BodyMapView = () => {
  const [symptom, severity] = useWatch({ name: ['symptom', 'severity'] });

  return (
    <BodyMap
      recordType={MedicalRecordType.Illness}
      selectedSide="BOTH"
      selectedBodyLocation={''}
      selectedArea={''}
      selectedSymptom={symptom.join(',')}
      selectedSeverity={severity}
    />
  );
};
