import './index.css';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@cycling-web/analog-ui';
import { RecoveryPopover } from '../RecoveryPopover';
import { Link, useLocation } from 'react-router';

export const MedicalReportDetailsHeader = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const backUrl = pathname.split('/');
  backUrl.pop();

  return (
    <div className="medical-report-details-page__header">
      <div className="medical-report-details-page__left">
        <Link to={backUrl.join('/')}>
          <IconButton variant="quietLayer2" content={<ArrowLeft />} />
        </Link>
        <Typography text={t('label.back_to_reports')} />
      </div>
      <div className="medical-report-details-page__right">
        <RecoveryPopover />
      </div>
    </div>
  );
};
