import { EMPTY } from '../../../constants';
import {
  chartTooltipFormatter,
  IChartSource,
  IEChartTooltipOption,
} from '../../../components/Chart';
import { ISmartChartConfig } from '../../../types/smart-charts';
import { IAthlete } from '../../../types/athletes';
import { formatTime } from '../../../utils/date-time';

type IProps = {
  config: ISmartChartConfig;
  source: IChartSource;
  isLogScale: boolean;
  athletes: IAthlete[];
};

const formatDisplayValue = (
  displayValue: number | string,
  units: string
): string => {
  if (displayValue === EMPTY) {
    return EMPTY;
  }

  if (units.trim().toLowerCase() === 'w') {
    return `${Math.round(Number(displayValue))} ${units}`;
  }

  if (units.trim().toLowerCase() === 'w/kg') {
    return `${Math.round(Number(displayValue) * 100) / 100} ${units}`;
  }

  return `${displayValue}${units}`;
};

export const computeTooltip = (props: IProps): IEChartTooltipOption => {
  const { config, athletes, isLogScale } = props;
  const graph = config.ai_graph_in_scope.ai_generated_graphs[0];
  const series: any = {};

  if (graph?.computed_data) {
    graph.computed_data.plots_data.forEach((item) => {
      const name =
        athletes?.length > 1 ? item?.readable_name_with_datasource : item?.name;
      series[name] = {
        id: item.linked_plot_id,
        units: '',
      };

      const plot = graph.plots.find((plot) => plot.id === item.linked_plot_id);

      if (plot) {
        series[name].units = plot.y_axis_meta_data.unit_readable_short_name;
      }
    });
  }

  const onRenderValue = (value: number, name: string) => {
    const displayValue: number | string = value === null ? EMPTY : value;
    let units = series[name]?.units ? ` ${series[name].units}` : '';
    if (displayValue === EMPTY) {
      units = '';
    }
    return formatDisplayValue(displayValue, units);
  };

  const onRenderHeader = (params) => {
    const value = params[0].value[0];

    if (isLogScale) {
      const parsedValue = Math.round(Math.pow(2, value));
      return formatTime(parsedValue);
    }

    return value;
  };

  return {
    // @ts-ignore
    formatter: chartTooltipFormatter({
      onRenderValue,
      onRenderHeader,
    }),
  };
};
