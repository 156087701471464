import './index.css';
import { Button, Popover } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { useCallback, useState } from 'react';

type IProps = {
  onSelect: (example: string) => void;
};

export const ExamplePrompts = ({ onSelect }: IProps) => {
  const { t } = useTranslation();
  const athlete = useAthleteStore((s) => s.athlete);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const anchor = (
    <Button
      variant="quietLayer2"
      size="xs"
      content={t('label.example_prompts')}
      onClick={() => setIsOpen(true)}
    />
  );

  const examples = [
    `How has ${athlete?.fullName}'s VO2 Max changed over the past six months?`,
    `Analyze ${athlete?.fullName}'s HRV and RHR over the past 30 days in relation to their training load. Identify and discuss any significant trends or patterns, highlighting key insights without detailing daily figures.`,
    `Analyze ${athlete?.fullName}'s performance over the past month.`,
    `Identify ${athlete?.fullName}'s peak modeled Critical Power (mCP) over the past 365 days. Analyze the distribution of training intensity during the 90 days leading up to this peak, providing key insights and observations. Additionally, provide a monthly recap of training intensity distribution, including the percentage of time spent in each zone using the classic training zone model.`,
  ];

  const handleExampleClick = useCallback(
    (example: string) => {
      return () => {
        onSelect(example);
        setIsOpen(false);
      };
    },
    [onSelect]
  );

  const examplesJSX = examples.map((example: string, i: number) => {
    return (
      <button
        className="ai-assistant__suggestions-button analog-typography--body"
        key={i}
        onClick={handleExampleClick(example)}
      >
        {example}
      </button>
    );
  });

  const content = (
    <div className="ai-assistant__suggestions-examples">{examplesJSX}</div>
  );

  return (
    <div className="ai-assistant__suggestions">
      <div className="ai-assistant__suggestions-toggle">
        <Popover
          anchor={anchor}
          content={content}
          placement="top-end"
          offset={4}
          minWidth="342px"
          maxWidth="342px"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  );
};
