import './index.css';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { Avatar, IconButton } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import { ChevronDown } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router';
import { useUserRole } from '../../../../hooks/useUserRole';
import { SingleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useTranslation } from 'react-i18next';

export const AthleteDetailsPageTitle = () => {
  const athlete = useAthleteStore((s) => s.athlete);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onAthleteChange = useCallback(
    (athleteId: number) => {
      const tmp = pathname.split('/');
      tmp[4] = athleteId.toString();
      navigate(tmp.join('/'));
    },
    [navigate, pathname]
  );

  const onRenderAnchor = useCallback(() => {
    return (
      <IconButton size="s" variant="quietLayer2" content={<ChevronDown />} />
    );
  }, []);

  if (isAthlete) {
    return <>{t('label.my_profile')}</>;
  }

  return (
    <div className="athlete-details__page-title">
      <Avatar
        size="l"
        text={athlete?.fullName}
        src={athlete?.picture}
        loading={!athleteLoaded}
      />
      <SingleAthleteFilter
        value={athlete?.id}
        onChange={onAthleteChange}
        selectProps={{ onRenderAnchor }}
        dropdownProps={{
          minWidth: '260px',
        }}
        skeletonProps={{ width: '32px', height: '32px' }}
        style={{ minWidth: '0' }}
      />
    </div>
  );
};
