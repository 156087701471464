import {
  ConfirmDialog,
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { MouseEvent, useCallback, useContext, useMemo } from 'react';
import { Ellipsis, Pencil, Trash2 } from 'lucide-react';
import { IGroup } from '../../../../types/groups';
import { GroupsContext } from '../../context';
import { GroupsRepository } from '../../../../api/groups/repository';
import { GroupsService } from '../../../../api/groups/service';
import { useGroupsStore } from '../../../../store/groups/slice';

type IProps = {
  group: IGroup;
};

export const Actions = ({ group }: IProps) => {
  const { t } = useTranslation();
  const { openGroupFormModal } = useContext(GroupsContext);
  const deleteGroups = useGroupsStore((s) => s.deleteGroups);

  const groupsRepository = useMemo(() => {
    return new GroupsRepository(new GroupsService());
  }, []);

  const {
    value: showDeleteDialog,
    setTrue: openDeleteDialog,
    setFalse: dismissDeleteDialog,
  } = useBoolean(false);

  const options: IMenuOption[] = [
    {
      id: 'edit',
      icon: <Pencil size={16} />,
      text: t('action.edit'),
    },
    {
      id: 'delete',
      icon: <Trash2 size={16} />,
      text: t('action.delete'),
    },
  ];

  const onChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'edit') {
        openGroupFormModal(group)();
      } else if (option.id === 'delete') {
        openDeleteDialog();
      }
    },
    [group, openDeleteDialog, openGroupFormModal]
  );

  const stopPropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const submitAction = useCallback(() => {
    return groupsRepository.deleteGroup({
      ids: [group.id],
    });
  }, [group.id, groupsRepository]);

  const onDeleteResolve = useCallback(() => {
    deleteGroups([group.id]);
  }, [deleteGroups, group.id]);

  return (
    <div onClick={stopPropagation}>
      <Menu
        options={options}
        onChange={onChange}
        dropdownProps={{
          anchor: <IconButton content={<Ellipsis />} variant="quietLayer2" />,
          minWidth: '140px',
          placement: 'bottom-end',
        }}
      />

      {showDeleteDialog && (
        <ConfirmDialog
          onDismiss={dismissDeleteDialog}
          title={t('confirm.delete_group_title')}
          subtitle={t('confirm.delete_group_subtitle', { name: group.name })}
          submitText={t('action.delete')}
          submitAction={submitAction}
          errorTitle={t('error.delete_group_title')}
          errorMessage={t('error.delete_group_message')}
          onResolve={onDeleteResolve}
        />
      )}
    </div>
  );
};
