import { IPerformanceReportSlice, IPerformanceReportState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IPerformanceReport } from '../../../types/performance';
import { format, startOfWeek, subWeeks } from 'date-fns';
import { IPerformanceReportFilters } from '../types';

const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
const previousWeekStart = subWeeks(currentWeekStart, 1);
const week = format(previousWeekStart, 'w');
const year = format(previousWeekStart, 'RRRR');

export const initialFilters: IPerformanceReportFilters = {
  week: `${week}_${year}`,
  search: '',
  athletes: [],
};

export const initialState: IPerformanceReportState = {
  filters: initialFilters,
  reports: [],
  reportsLoaded: false,
};

export const usePerformanceReportStore = create<IPerformanceReportSlice>()(
  immer((set) => ({
    ...initialState,
    setFilters: (filters: Partial<IPerformanceReportFilters>) => {
      set((state) => {
        state.filters = {
          ...state.filters,
          ...filters,
        };
      });
    },
    setReports: (reports: IPerformanceReport[]) => {
      set((state) => {
        state.reports = reports;
        state.reportsLoaded = true;
      });
    },
    setReportsLoaded: (weeklyReportsLoaded: boolean) => {
      set((state) => {
        state.reportsLoaded = weeklyReportsLoaded;
      });
    },
    updateReport: (report: Partial<IPerformanceReport>) => {
      set((state) => {
        const index = state.reports.findIndex(
          (r) => report.athleteId === r.athleteId
        );
        if (index >= 0) {
          state.reports[index] = {
            ...state.reports[index],
            ...report,
          };
        }
      });
    },
    reset: () => {
      set({
        ...initialState,
        filters: initialFilters,
      });
    },
  }))
);
