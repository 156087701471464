import { EmptyState } from '@cycling-web/analog-ui';
import './index.css';
import { useTranslation } from 'react-i18next';
import { useUsersStore } from '../../../../store/users/slice';
import { useSmartChartsContext } from '../../context';

export const WelcomeBanner = () => {
  const { t } = useTranslation();
  const userProfile = useUsersStore((s) => s.userProfile);
  const { openAthletesModal } = useSmartChartsContext();

  const firstName =
    userProfile?.firstName || userProfile?.fullName?.trim().split(' ')[0];

  return (
    <EmptyState
      className="sc2__welcome-banner"
      title={t('smart_charts.label.welcome_name', { name: firstName })}
      text={t('smart_charts.label.welcome_message')}
      buttonProps={{
        variant: 'layer2',
        content: t('smart_charts.action.select_athletes'),
        onClick: openAthletesModal,
      }}
    />
  );
};
