import './index.css';
import * as echarts from 'echarts';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { getEventName } from '../../utils/getEventName';
import { prepareChart } from '../../utils/prepareChart';
import { changeAfterRender } from '../../utils/changeAfterRender';
import { useChartContext } from '../../context';
import { ChartFixedCursor } from '../ChartFixedCursor';
import { useFixedCursor } from './useFixedCursor';
import debounce from 'lodash/debounce';

export const ChartCore = () => {
  const {
    option,
    events,
    chartRef: outerChartRef,
    setReady,
    group,
    fixedCursors,
  } = useChartContext();

  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const localChartRef = useRef<echarts.EChartsType | null>(null);
  const chartRef =
    (outerChartRef as RefObject<echarts.EChartsType | null>) || localChartRef;

  useEffect(() => {
    if (!chartWrapperRef.current) {
      return;
    }

    chartRef.current = echarts.init(chartWrapperRef.current);

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (chartRef.current) {
          chartRef.current.resize();
        }
      }, 100)
    );

    resizeObserver.observe(chartWrapperRef.current);

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
      resizeObserver.disconnect();
    };
  }, [chartRef]);

  useEffect(() => {
    if (!group || !chartRef.current) {
      return;
    }

    chartRef.current.group = group;
    echarts.connect(group);

    return () => {
      if (group) {
        echarts.disconnect(group);
      }
    };
  }, [chartRef, group]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.setOption(prepareChart(option), { lazyUpdate: true });
      changeAfterRender(chartRef.current);
      setReady(true);
    }
  }, [chartRef, option, setReady]);

  useEffect(() => {
    if (events) {
      Object.keys(events).forEach((eventName) => {
        if (chartRef.current) {
          chartRef.current.on(getEventName(eventName), events[eventName]);
        }
      });
      return () => {
        Object.keys(events).forEach((eventName) => {
          if (chartRef.current) {
            chartRef.current.off(getEventName(eventName), events[eventName]);
          }
        });
      };
    }
  }, [chartRef, events]);

  useFixedCursor();

  const fixedCursorTooltipJSX = useMemo(
    () =>
      fixedCursors.map((cursor) => (
        <ChartFixedCursor key={cursor.id} cursor={cursor} />
      )),
    [fixedCursors]
  );

  return (
    <>
      <div className="chart-core" ref={chartWrapperRef} />
      {fixedCursorTooltipJSX}
    </>
  );
};
