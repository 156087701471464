import './index.css';
import { IMedicalReport } from '../../../../types/medical';
import { BodyMap } from '../../../../components/body-map/body-map';
import React from 'react';

type IProps = {
  athleteReport: IMedicalReport;
};

export const ReportBodyMap = ({ athleteReport }: IProps) => {
  return (
    <div className="medical-report__body-map">
      <BodyMap
        recordType={athleteReport.recordType}
        selectedSide={athleteReport.side || 'BOTH'}
        selectedBodyLocation={athleteReport.bodyLocation || ''}
        selectedArea={athleteReport.area || ''}
        selectedSymptom={athleteReport.symptom || ''}
        selectedSeverity={athleteReport.severity || ''}
      />
    </div>
  );
};
