import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import {
  IAddTrainingPeaksAccountRequest,
  IAddTrainingPeaksAccountResponse,
  IAddUltrahumanAccountRequest,
  IAddUltrahumanAccountResponse,
  IAddWahooAccountRequest,
  IAddWahooAccountResponse,
  IAddWhoopAccountRequest,
  IAddWhoopAccountResponse,
  IConsentMyWhooshRequest,
  IConsentMyWhooshResponse,
  IDeleteUltrahumanAccountRequest,
  IDeleteUltrahumanAccountResponse,
  IDeleteWahooAccountResponse,
  IDeleteWhoopAccountResponse,
  IEraseDataResponse,
  IGetPlatformRequest,
  IGetPlatformResponse,
  IGetTrainingPeaksAccountResponse,
  IGetUltrahumanClientIdResponse,
  IGetWahooClientIdResponse,
  IGetWhoopClientIdResponse,
  IRemoveMyWhooshAccountResponse,
  IRemoveTrainingPeaksAccountResponse,
} from './types';
import qs from 'qs';

interface IPlatformService {
  getPlatforms: (p: IGetPlatformRequest) => R<IGetPlatformResponse>;

  getTrainingPeaksAccount: () => R<IGetTrainingPeaksAccountResponse>;
  removeTrainingPeaksAccount: () => R<IRemoveTrainingPeaksAccountResponse>;
  addTrainingPeaksAccount: (
    p: IAddTrainingPeaksAccountRequest
  ) => R<IAddTrainingPeaksAccountResponse>;

  addWahooAccount: (p: IAddWahooAccountRequest) => R<IAddWahooAccountResponse>;
  getWahooClientId: () => R<IGetWahooClientIdResponse>;
  deleteWahooAccount: () => R<IDeleteWahooAccountResponse>;

  addWhoopAccount: (p: IAddWhoopAccountRequest) => R<IAddWhoopAccountResponse>;
  getWhoopClientId: () => R<IGetWhoopClientIdResponse>;
  deleteWhoopAccount: () => R<IDeleteWhoopAccountResponse>;

  addUltrahumanAccount: (
    p: IAddUltrahumanAccountRequest
  ) => R<IAddUltrahumanAccountResponse>;
  getUltrahumanAccessCode: () => R<IGetUltrahumanClientIdResponse>;
  deleteUltrahumanAccount: (
    p: IDeleteUltrahumanAccountRequest
  ) => R<IDeleteUltrahumanAccountResponse>;

  consentMyWhoosh: (p: IConsentMyWhooshRequest) => R<IConsentMyWhooshResponse>;
  removeMyWhooshAccount: () => R<IRemoveMyWhooshAccountResponse>;

  eraseData: () => R<IEraseDataResponse>;
}

export class PlatformService implements IPlatformService {
  async getPlatforms(): R<IGetPlatformResponse> {
    try {
      return axiosInstance.get('/v1/platform/devices');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTrainingPeaksAccount(): R<IGetTrainingPeaksAccountResponse> {
    try {
      return axiosInstance.get('/v1/platform/tp/clientId');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeTrainingPeaksAccount(): R<IRemoveTrainingPeaksAccountResponse> {
    try {
      return axiosInstance.delete('/v1/platform/tp/removeAccount');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addTrainingPeaksAccount(
    p: IAddTrainingPeaksAccountRequest
  ): R<IAddTrainingPeaksAccountResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.post(`/v1/platform/tp/addAccount?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addWahooAccount(
    p: IAddWahooAccountRequest
  ): R<IAddWahooAccountResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.post(`/v1/platform/wahoo/addAccount?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getWahooClientId(): R<IGetWahooClientIdResponse> {
    try {
      return axiosInstance.get('/v1/platform/wahoo/clientId');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteWahooAccount(): R<IDeleteWahooAccountResponse> {
    try {
      return axiosInstance.delete('/v1/platform/wahoo/removeAccount');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addUltrahumanAccount(
    p: IAddUltrahumanAccountRequest
  ): R<IAddUltrahumanAccountResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.post(`/v1/platform/ultrahuman/addAccount?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUltrahumanAccessCode(): R<IGetUltrahumanClientIdResponse> {
    try {
      return axiosInstance.get('/v1/platform/ultrahuman/accessCode');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteUltrahumanAccount(
    p: IDeleteUltrahumanAccountRequest
  ): R<IDeleteUltrahumanAccountResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.delete(
        `/v1/platform/ultrahuman/removeAccount?${params}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addWhoopAccount(
    p: IAddWhoopAccountRequest
  ): R<IAddWhoopAccountResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.post(`/v1/platform/whoop/addAccount?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getWhoopClientId(): R<IGetWhoopClientIdResponse> {
    try {
      return axiosInstance.get('/v1/platform/whoop/clientId');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteWhoopAccount(): R<IDeleteWhoopAccountResponse> {
    try {
      return axiosInstance.delete('/v1/platform/whoop/removeAccount');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async consentMyWhoosh(
    p: IConsentMyWhooshRequest
  ): R<IConsentMyWhooshResponse> {
    try {
      return axiosInstance.post('/v1/mywoosh/consent', null, {
        headers: {
          Authorization: `Bearer ${p.id_token}`,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeMyWhooshAccount(): R<IRemoveMyWhooshAccountResponse> {
    try {
      return axiosInstance.delete('/v1/platform/mywoosh/removeAccount');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async eraseData(): R<IEraseDataResponse> {
    try {
      return axiosInstance.delete('/v1/platform/eraseData');
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
