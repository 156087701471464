import { useForm } from 'react-hook-form';
import { Toast } from '@cycling-web/analog-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { IPersonalInfoForm } from '../types';
import { IAthlete } from '../../../../../types/athletes';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../../constants';
import { AthletesRepository } from '../../../../../api/athletes/repository';
import { AthletesService } from '../../../../../api/athletes/service';

type IProps = {
  athlete: IAthlete;
};

export const useViewModel = ({ athlete }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        firstName: z.string().min(1, { message: t('validation.required') }),
        lastName: z.string().min(1, { message: t('validation.required') }),
        // phone: z.string().min(1, { message: t('validation.required') }),
        countryCode: z.string().min(1, { message: t('validation.required') }),
        gender: z.string().min(1, { message: t('validation.required') }),
        timezone: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IPersonalInfoForm>({
    defaultValues: {
      firstName: athlete.firstName || '',
      lastName: athlete.lastName || '',
      phone: athlete.phone || '',
      country: athlete.country || '',
      countryCode:
        athlete.countryCode ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        '',
      gender: athlete.gender || '',
      timezone: athlete.timezone || '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit } = form;

  const formatDate = useCallback((date: Date) => {
    return date;
  }, []);

  const onSubmit = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      handleSubmit(
        (formData: IPersonalInfoForm) => {
          setLoading(true);

          athletesRepository
            .updateProfile({
              ...athlete,
              ...formData,
              fullName: `${formData.firstName} ${formData.lastName}`,
            })
            .then(() => {
              resolve(true);
            })
            .catch((error: AxiosError) => {
              if (error?.response?.status !== ApiErrorCode.Unauthorized) {
                Toast.error({
                  title: t('error.update_profile_title'),
                  message: t('error.update_profile_message'),
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        },
        () => {
          return resolve(false);
        }
      )();
    });
  }, [athlete, athletesRepository, handleSubmit, t]);

  return {
    form,
    onSubmit,
    formatDate,
    loading,
  };
};
