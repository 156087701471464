import { IMedicalReport, MedicalRecordType } from '../types/medical';
import { EMPTY } from '../constants';

export const deriveMedicalReportIssue = (
  medicalReport: IMedicalReport | undefined
): string => {
  if (!medicalReport) {
    return EMPTY;
  }

  if (medicalReport.recordType === MedicalRecordType.Physio) {
    return medicalReport.bodyLocation || EMPTY;
  } else if (medicalReport.recordType === MedicalRecordType.Injury) {
    return medicalReport.area || EMPTY;
  }

  return medicalReport.symptom || EMPTY;
};
