import {
  ISmartChartAxis,
  ISmartChartComputedDataAxes,
  ISmartChartComputedDataPlot,
  ISmartChartConfig,
  ISmartChartPlot,
} from '../../../types/smart-charts';
import { IChartSource, IEChartSeriesOption } from '../../../components/Chart';
import { IAthlete } from '../../../types/athletes';

type IProps = {
  config: ISmartChartConfig;
  source: IChartSource;
  isLogScale: boolean;
  athletes: IAthlete[];
};

export const computeSeries = (props: IProps): IEChartSeriesOption[] => {
  const { config, isLogScale, athletes, source } = props;
  const graph = config?.ai_graph_in_scope?.ai_generated_graphs[0];
  const plots = graph?.plots || [];
  const computedData = graph?.computed_data;

  if (!graph) {
    return [];
  }

  const checkIfLinkedYAxisExists = (currentPlotId: string): boolean => {
    const matchingYAxes = graph?.y_axes?.find((axis: ISmartChartAxis) =>
      axis.linked_plot_ids.includes(currentPlotId)
    );
    if (matchingYAxes) {
      const yAxisDataElement = computedData?.y_axes_data?.find(
        (axis: ISmartChartComputedDataAxes) => {
          return axis.linked_axis_ids[0] === matchingYAxes.id;
        }
      );
      return !!yAxisDataElement;
    }
    return false;
  };

  const getYAxisIndex = (currentPlotId: string) => {
    let yAxisIndex: number | null = null;
    const matchingYAxes = graph?.y_axes?.find((axis: ISmartChartAxis) =>
      axis.linked_plot_ids.includes(currentPlotId)
    );
    if (matchingYAxes) {
      computedData?.y_axes_data?.forEach(
        (axis: ISmartChartComputedDataAxes, index: number) => {
          if (axis?.linked_axis_ids?.[0] === matchingYAxes?.id) {
            yAxisIndex = index;
          }
        }
      );
    }
    return yAxisIndex;
  };

  // @ts-ignore
  return graph.computed_data?.plots_data?.map(
    (plotData: ISmartChartComputedDataPlot, index: number) => {
      const matchingPlot = plots.find(
        (plot: ISmartChartPlot) => plot.id === plotData.linked_plot_id
      );

      const isLine = matchingPlot?.plot_view_metadata.type === 'line';

      const encode =
        isLogScale && source[0]
          ? {
              encode: {
                x: source[0][0],
                y: source[0][index + 1],
              },
            }
          : {};

      return {
        name:
          athletes?.length > 1
            ? plotData?.readable_name_with_datasource
            : plotData?.name,
        type: matchingPlot?.plot_view_metadata.type,
        symbol:
          matchingPlot?.plot_view_metadata.line_mark_points_shape ||
          'emptyCircle',
        large: isLogScale,
        ...encode,
        symbolSize: isLine ? 0 : 4,
        smooth: matchingPlot?.plot_view_metadata?.is_smoothening,
        color: matchingPlot?.plot_view_metadata.color_name,
        showSymbol: matchingPlot?.plot_view_metadata.is_show_on_legend,
        connectNulls: true,
        itemStyle: {
          color: matchingPlot?.plot_view_metadata.color_name,
        },
        lineStyle: {
          color: matchingPlot?.plot_view_metadata.color_name,
          type: matchingPlot?.plot_view_metadata.line_style_type || 'solid',
          opacity: matchingPlot?.plot_view_metadata?.is_smoothening ? 0.6 : 1,
        },
        backgroundStyle: {
          color: matchingPlot ? 'rgba(35, 41, 50, 0.6)' : 'rgba(0, 0, 0, 0)',
        },
        ...(graph?.y_axes?.length > 1 &&
          index !== 0 &&
          checkIfLinkedYAxisExists(plotData?.linked_plot_id) && {
            yAxisIndex: getYAxisIndex(plotData?.linked_plot_id),
          }),
        ...(matchingPlot?.plot_view_metadata?.is_area_shaded_plot && {
          areaStyle: {
            color: matchingPlot?.plot_view_metadata?.area_shaded_color_name,
            opacity: 0.3,
          },
        }),
      };
    }
  );
};
