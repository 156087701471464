import './index.css';
import { Header } from './components/Header';
import { RoleSwitch } from './components/RoleSwitch';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAdminTeamMemberStore } from '../AdminPanel/store/slice';
import { useEffect, useMemo } from 'react';
import { IUserBase } from '../../types/users';
import { UserRole } from '@cycling-web/common';
import { setTitle } from '../../hooks/useTitle';
import { ROUTES } from '../../router/routes';

export const AdminTeamMemberDetails = () => {
  const { t } = useTranslation();

  const { userId, role } = useParams();
  const users = useAdminTeamMemberStore((s) => s.users);
  const currentUser = useMemo(() => {
    return users.find((user: IUserBase) => {
      if (!userId) {
        return undefined;
      }

      if (role === ROUTES.STAFF) {
        return user.staffId === +userId;
      }
      return user.athleteId === +userId;
    });
  }, [role, userId, users]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    setTitle(`${t('label.admin_panel')} - ${currentUser.name}`);
    useAdminTeamMemberStore.getState().setCurrentUser(currentUser);
  }, [currentUser, role, t, userId, users]);

  useEffect(() => {
    return () => {
      useAdminTeamMemberStore.getState().setCurrentUser(null);
    };
  }, []);

  const roles = useMemo(() => {
    return [
      {
        id: UserRole.Admin,
        name: t('label.admin'),
        description: t('label.admin_description'),
      },
      {
        id: UserRole.Nutritionist,
        name: t('label.nutritionist'),
        description: t('label.nutritionist_description'),
      },
      {
        id: UserRole.SportsDirector,
        name: t('label.sports_director'),
        description: t('label.sports_director_description'),
      },
      {
        id: UserRole.Physiotherapist,
        name: t('label.physiotherapist'),
        description: t('label.physiotherapist_description'),
      },
      {
        id: UserRole.Coach,
        name: t('label.coach'),
        description: t('label.coach_description'),
      },
      {
        id: UserRole.ExternalCoach,
        name: t('label.external_coach'),
        description: t('label.external_coach_description'),
      },
      {
        id: UserRole.MentalCoach,
        name: t('label.mental_coach'),
        description: t('label.mental_coach_description'),
      },
      {
        id: UserRole.Doctor,
        name: t('label.doctor'),
        description: t('label.doctor_description'),
      },
      {
        id: UserRole.Athlete,
        name: t('label.athlete'),
        description: t('label.athlete_description'),
      },
      {
        id: UserRole.SecondaryDoctor,
        name: t('label.secondary_doctor'),
        description: t('label.secondary_doctor_description'),
      },
      {
        id: UserRole.Management,
        name: t('label.management'),
        description: t('label.management_description'),
      },
    ];
  }, [t]);

  const rolesJSX = roles.map((role) => {
    return <RoleSwitch key={role.name} role={role} />;
  });

  return (
    <div className="admin-team-member-details-page">
      <Header />
      <div className="team-member__roles">{rolesJSX}</div>
    </div>
  );
};
