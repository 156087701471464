import './index.css';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';

type IProps = {
  password: string;
};

export const PasswordRequirementMessage = ({ password }: IProps) => {
  const { t } = useTranslation();

  const rules = [
    {
      test: (p: string) => p.length >= 8,
      messageKey: 'label.password_requirement_message_1',
    },
    {
      test: (p: string) => /[a-z]/.test(p),
      messageKey: 'label.password_requirement_message_2',
    },
    {
      test: (p: string) => /[A-Z]/.test(p),
      messageKey: 'label.password_requirement_message_3',
    },
    {
      test: (p: string) => /\d/.test(p),
      messageKey: 'label.password_requirement_message_4',
    },
    {
      test: (p: string) => /[@#$%^&*\-_!+=[\]{}|\\:'",.?/`~();<> ]/.test(p),
      messageKey: 'label.password_requirement_message_5',
    },
  ];

  const requirementsJSX = rules.map((rule, index) => {
    const isValid = rule.test(password);
    return (
      <li
        key={index}
        className={clsx(
          isValid && 'analog-auth-common-form__password-requirement-met'
        )}
      >
        {t(rule.messageKey)}
      </li>
    );
  });

  return (
    <div className="analog-auth-common-form__password-message analog-typography--body">
      <ul>{requirementsJSX}</ul>
    </div>
  );
};
