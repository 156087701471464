export const ROUTES = {
  AUTH: 'auth',
  SIGN_UP: 'sign-up',
  SIGN_IN: 'sign-in',
  SIGN_OUT: 'logout',
  OTP: 'sign-in-otp',
  PASSWORD: 'password',
  FORGOT_PASSWORD: 'forgot-password',
  FORGOT_PASSWORD_OTP: 'forgot-password-otp',
  NEW_PASSWORD: 'new-password',
};
