import './index.css';
import {
  ConfirmDialog,
  Switch,
  Toast,
  Typography,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';
import { UserRole } from '@cycling-web/common';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { IUpdateStaffRoleRequest } from '../../../../api/users/types';
import { useTranslation } from 'react-i18next';
import { EMPTY } from '../../../../constants';

type IProps = {
  role: {
    id: UserRole;
    name: string;
    description: string;
  };
};

export const RoleSwitch = ({ role }: IProps) => {
  const { t } = useTranslation();
  const currentUser = useAdminTeamMemberStore((s) => s.currentUser);
  const updateCurrentUser = useAdminTeamMemberStore((s) => s.updateCurrentUser);
  const payloadRef = useRef<IUpdateStaffRoleRequest | null>(null);

  const {
    value: showConfirmRevokeDialog,
    setTrue: openConfirmRevokeDialog,
    setFalse: dismissConfirmRevokeDialog,
  } = useBoolean(false);

  const {
    value: showConfirmSwitchCoachRoleDialog,
    setTrue: openConfirmSwitchCoachRoleDialog,
    setFalse: dismissConfirmSwitchCoachRoleDialog,
  } = useBoolean(false);

  const disabled =
    currentUser?.athleteId === undefined ? role.id === UserRole.Athlete : true;

  const { value: checked, toggle, setTrue, setFalse } = useBoolean(false);

  useEffect(() => {
    if (currentUser?.roles?.includes(role.id)) {
      setTrue();
    } else {
      setFalse();
    }
  }, [currentUser?.roles, role.id, setFalse, setTrue]);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const handleUpdateStaffRole = useCallback(
    (payload: IUpdateStaffRoleRequest) => {
      toggle();
      return usersRepository
        .updateStaffRole(payload)
        .then(() => {
          updateCurrentUser({
            roles: payload.roles,
          });
          payloadRef.current = null;
        })
        .catch((e) => {
          Toast.error({
            title: t('error.update_member_role'),
            message: e.message || '',
          });
          toggle();
        });
    },
    [t, toggle, updateCurrentUser, usersRepository]
  );

  const onChange = useCallback(() => {
    if (disabled || !currentUser?.staffId) {
      return;
    }

    const payload: IUpdateStaffRoleRequest = {
      staffId: currentUser.staffId,
      roles: [...currentUser.roles],
    };

    if (payload.roles.includes(role.id)) {
      payload.roles = payload.roles.filter((r: UserRole) => r !== role.id);
      payloadRef.current = payload;
      openConfirmRevokeDialog();
    } else {
      const isCoach = role.id === UserRole.Coach;
      const isExtCoach = role.id === UserRole.ExternalCoach;
      let isSwitchBetweenCoachRoles = false;

      if (isCoach && payload.roles.includes(UserRole.ExternalCoach)) {
        isSwitchBetweenCoachRoles = true;
        payload.roles = payload.roles.filter(
          (r: UserRole) => r !== UserRole.ExternalCoach
        );
      }
      if (isExtCoach && payload.roles.includes(UserRole.Coach)) {
        isSwitchBetweenCoachRoles = true;
        payload.roles = payload.roles.filter(
          (r: UserRole) => r !== UserRole.Coach
        );
      }
      payload.roles.push(role.id);

      if (isSwitchBetweenCoachRoles) {
        payloadRef.current = payload;
        openConfirmSwitchCoachRoleDialog();
      } else {
        handleUpdateStaffRole(payload);
      }
    }
  }, [
    disabled,
    currentUser?.staffId,
    currentUser?.roles,
    role.id,
    openConfirmRevokeDialog,
    openConfirmSwitchCoachRoleDialog,
    handleUpdateStaffRole,
  ]);

  const handleConfirmApplyRoles = useCallback((): Promise<void> => {
    if (!payloadRef.current) {
      return Promise.resolve();
    }
    return handleUpdateStaffRole(payloadRef.current);
  }, [handleUpdateStaffRole]);

  return (
    <div className="team-member__role-switch">
      <div className="team-member__role-details">
        <Typography
          text={role.name}
          weight="bold"
          className="team-member__role-name"
        />
        <Typography
          text={role.description}
          variant="subtitle"
          weight="regular"
          className="team-member__role-description"
        />
      </div>

      <div className="team-member__role-actions">
        <Switch
          checked={checked}
          onChange={onChange}
          size="s"
          variant="primaryDark"
          disabled={disabled}
        />
      </div>
      {disabled && <div className="team-member__role-overlay" />}

      {showConfirmRevokeDialog && (
        <ConfirmDialog
          onDismiss={dismissConfirmRevokeDialog}
          title={t('confirm.revoke_role_title')}
          subtitle={t('confirm.revoke_role_message', {
            role: role.name,
            user: currentUser?.name || EMPTY,
          })}
          submitText={t('action.confirm')}
          submitAction={handleConfirmApplyRoles}
        />
      )}

      {showConfirmSwitchCoachRoleDialog && (
        <ConfirmDialog
          onDismiss={dismissConfirmSwitchCoachRoleDialog}
          title={t('confirm.change_coach_role_title')}
          subtitle={t('confirm.change_coach_role_message')}
          submitText={t('action.confirm')}
          submitAction={handleConfirmApplyRoles}
        />
      )}
    </div>
  );
};
