import { ITextFieldSize, ITextFieldVariant } from '../types';
import { IButtonSize, IButtonVariant } from '../components/Button/types';

export const textFieldButtonVariant: Record<ITextFieldVariant, IButtonVariant> =
  {
    light: 'quietLayer2',
    dark: 'quietLayer2',
    layer1: 'quietLayer3',
    layer2: 'quietLayer2',
    layer3: 'quietLayer3',
  };

export const textFieldButtonSize: Record<ITextFieldSize, IButtonSize> = {
  s: 'xs',
  m: 's',
  l: 'm',
};
