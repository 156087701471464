import { ISmartChartsSlice, ISmartChartsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ISmartChartConfig,
  ISmartChartConfigBase,
  ISmartChartTurn,
} from '../../../types/smart-charts';
import { IAthlete } from '../../../types/athletes';
import merge from 'lodash/merge';
import { DeepPartial } from '@cycling-web/common';

export const initialState: ISmartChartsState = {
  selectedAthletes: [],
  history: [],
  historyLoaded: false,
  config: undefined,
  configLoaded: false,
  chartLoaded: false,
  processingBufferMessage: undefined,
  reloadChartCounter: 0,
};

export const useSmartChartsStore = create<ISmartChartsSlice>()(
  immer((set) => ({
    ...initialState,
    setSelectedAthletes: (selectedAthletes: IAthlete[]) => {
      set((state) => {
        state.selectedAthletes = selectedAthletes;
      });
    },
    setHistory: (history: ISmartChartConfigBase[]) => {
      set((state) => {
        state.history = history;
        state.historyLoaded = true;
      });
    },
    setHistoryLoaded: (loaded: boolean) => {
      set((state) => {
        state.historyLoaded = loaded;
      });
    },
    appendHistory: (item: ISmartChartConfigBase) => {
      set((state) => {
        state.history = [item, ...state.history];
      });
    },
    updateHistory: (item: DeepPartial<ISmartChartConfigBase>) => {
      set((state) => {
        const index = state.history.findIndex(
          (message) => message.id === item.id
        );

        if (index >= 0) {
          state.history[index] = merge({}, state.history[index], item);
        }
      });
    },
    startConversation: () => {
      set((state) => {
        state.selectedAthletes = initialState.selectedAthletes;
        state.config = initialState.config;
        state.configLoaded = initialState.configLoaded;
        state.chartLoaded = initialState.chartLoaded;
        state.processingBufferMessage = initialState.processingBufferMessage;
      });
    },
    deleteConversation: (id: string) => {
      set((state) => {
        state.history = state.history.filter((item) => item.id !== id);
        if (state.config?.id === id) {
          state.selectedAthletes = initialState.selectedAthletes;
          state.config = initialState.config;
          state.configLoaded = initialState.configLoaded;
          state.chartLoaded = initialState.chartLoaded;
          state.processingBufferMessage = initialState.processingBufferMessage;
        }
      });
    },
    setConfig: (config: ISmartChartConfig | undefined) => {
      set((state) => {
        state.config = config;
        state.configLoaded = true;
      });
    },
    setConfigLoaded: (loaded: boolean) => {
      set((state) => {
        state.configLoaded = loaded;
      });
    },
    setChartLoaded: (loaded: boolean) => {
      set((state) => {
        state.chartLoaded = loaded;
      });
    },
    updateConfig: (config: DeepPartial<ISmartChartConfig>) => {
      set((state) => {
        if (state.config) {
          state.config = merge({}, state.config, config);
        }
      });
    },
    appendMessages: (messages: ISmartChartTurn[]) => {
      set((state) => {
        if (state.config) {
          state.config.conversation_turns.turns = [
            ...state.config.conversation_turns.turns,
            ...messages,
          ];
        }
      });
    },
    setProcessingBufferMessage: (message: ISmartChartTurn | undefined) => {
      set((state) => {
        state.processingBufferMessage = message;
      });
    },
    reloadChart: () => {
      set((state) => {
        state.reloadChartCounter++;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
