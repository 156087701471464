import { useCallback, useMemo, useState } from 'react';
import './index.css';
import { IAthlete } from '../../../../types/athletes';
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  EmptyState,
  Input,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { User } from 'lucide-react';
import { useAthletesStore } from '../../../../store/athletes/slice';

type IProps = {
  onDismiss: () => void;
  onSubmit: (athletes: IAthlete[]) => void;
  selectedAthletes: IAthlete[];
};

export const AthleteModal = ({
  onDismiss,
  onSubmit,
  selectedAthletes,
}: IProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const athletes = useAthletesStore((s) => s.athletes);
  const [localSelectedAthletes, setLocalSelectedAthletes] = useState(
    () => selectedAthletes
  );

  const filteredAthletes = useMemo(() => {
    return athletes.filter((athlete: IAthlete) => {
      return athlete.fullName.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [athletes, searchValue]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(localSelectedAthletes);
  }, [localSelectedAthletes, onSubmit]);

  const handleSelect = useCallback((athlete: IAthlete) => {
    setLocalSelectedAthletes((athletes: IAthlete[]) => {
      const index = athletes.findIndex((a: IAthlete) => {
        return a.id === athlete.id;
      });

      if (index >= 0) {
        return athletes.filter((a: IAthlete) => {
          return a.id !== athlete.id;
        });
      }

      return [...athletes, athlete];
    });
  }, []);

  const handleClearAll = useCallback(() => {
    setLocalSelectedAthletes([]);
  }, []);

  const athletesJSX = filteredAthletes.map((a: IAthlete) => {
    const isSelected = !!localSelectedAthletes?.find(
      (b: IAthlete) => a.id === b.id
    );

    return (
      <Checkbox
        key={a.id}
        checked={isSelected}
        onChange={() => handleSelect(a)}
        onRenderLabel={() => (
          <Avatar size="m" src={a.picture} text={a.fullName} />
        )}
      />
    );
  });

  const onRenderFooter = useCallback(() => {
    return (
      <div className="sc1__athletes-modal-footer">
        <Typography
          as="div"
          variant="body"
          weight="light"
          className="sc1__athletes-modal-footer-selected"
          text={t('label.selected_n_athletes', {
            n: localSelectedAthletes.length,
          })}
        />
        <div className="sc1__athletes-modal-footer-actions">
          <Button
            variant="quietLayer3"
            content={t('action.clear')}
            onClick={handleClearAll}
          />
          <div className="sc1__athletes-modal-footer__right">
            <Button
              variant="quietLayer3"
              content={t('action.cancel')}
              onClick={onDismiss}
            />
            <Button
              content={t('action.save')}
              disabled={localSelectedAthletes.length === 0}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }, [
    handleClearAll,
    handleSubmit,
    localSelectedAthletes.length,
    onDismiss,
    t,
  ]);

  return (
    <Dialog
      className="sc1__athletes-modal-wrapper"
      onDismiss={onDismiss}
      title={t('label.athletes')}
      preventDefaultOnClick={false}
      onRenderFooter={onRenderFooter}
    >
      <div className="sc1__athletes-modal">
        <div className="sc1__athletes-modal-filters">
          <Input
            value={searchValue}
            placeholder={t('placeholder.search')}
            onChange={handleSearchChange}
            onClear={handleSearchClear}
          />
        </div>

        <div className="sc1__athletes-modal-list">
          {filteredAthletes?.length > 0 ? (
            athletesJSX
          ) : (
            <EmptyState
              icon={<User size={32} />}
              title={t('banner.empty_athletes_modal_title')}
              text={t('banner.empty_athletes_modal_message')}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
