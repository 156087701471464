import { Button, FormElement, Message } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import { use, useCallback, useMemo, useState } from 'react';
import { AuthRepository } from '../../api/auth/repository';
import { Link, useNavigate } from 'react-router';
import { ROUTES } from '../../router/routes';
import { AuthPageContext } from '../../context/AuthPageContext';
import { FormView } from '../FormView';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';

type IForm = {
  email: string;
};

export const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { continuationTokenRef, usernameRef } = use(AuthPageContext);
  const { authOptions, translations } = useAnalogAuthContext();

  const authRepository = useMemo(() => {
    return AuthRepository.getInstance(authOptions);
  }, [authOptions]);

  const schema = useMemo(() => {
    return z.object({
      email: z
        .string()
        .min(1, { message: t('validation.required') })
        .email({ message: t('validation.invalid_email') }),
    });
  }, [t]);

  const defaultValues = useMemo((): IForm => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email') || '';

    return {
      email,
    };
  }, []);

  const form = useForm<IForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      setLoading(true);
      usernameRef.current = formData.email;

      authRepository
        .signUpStart({
          challenge_type: 'oob password redirect',
          username: formData.email,
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          return authRepository.signUpChallenge({
            challenge_type: 'oob password redirect',
            continuation_token: continuationTokenRef.current,
          });
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          navigate(`/${ROUTES.AUTH}/${ROUTES.OTP}`, { replace: true });
        })
        .catch((e: { error: string }) => {
          const error = e?.error || 'invalid_email';
          setMessage(t(`validation.${error}`));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [authRepository, continuationTokenRef, navigate, t, usernameRef]
  );

  return (
    <FormProvider {...form}>
      <FormView
        title={translations?.signup_welcome_title}
        subtitle={translations?.signup_welcome_subtitle}
      >
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.email')}
            message={formState.errors.email?.message}
          >
            <InputControl
              name="email"
              size="l"
              placeholder={t('placeholder.email')}
              invalid={!!formState.errors.email}
              autoFocus={true}
            />
          </FormElement>

          {message && (
            <div className="analog-auth-common-form__message">
              <Message text={message} variant="error" />
            </div>
          )}

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              size="l"
              content={t('action.sign_up')}
              fullWidth
              loading={loading}
            />
          </div>
        </form>

        <div className="analog-auth__common-footer">
          <div className="analog-auth__common-footer-message analog-typography--button-m">
            <span>{t('label.have_account_message')}</span>
            <Link
              to={`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`}
              replace={true}
              className="analog-auth__common-footer-button"
            >
              {t('action.login')}
            </Link>
          </div>
        </div>
      </FormView>
    </FormProvider>
  );
};
