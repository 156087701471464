import './index.css';
import { IconButton, useBoolean } from '@cycling-web/analog-ui';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { AIRepository } from '../../../../api/ai/repository';
import { AIService } from '../../../../api/ai/service';
import {
  IChatbotFeedbackType,
  IChatbotHistoryMessage,
} from '../../../../types/chatbot';
import { useAiAssistantStore } from '../../store/slice';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { FeedbackModal } from '../FeedbackModal';

type IProps = {
  message: IChatbotHistoryMessage;
};

export const MessageFeedback = ({ message }: IProps) => {
  const { t } = useTranslation();
  const config = useAiAssistantStore((s) => s.config);
  const [feedback, setFeedback] = useState<IChatbotFeedbackType | undefined>(
    undefined
  );
  const {
    value: showFeedbackModal,
    setTrue: openFeedbackModal,
    setFalse: dismissFeedbackModal,
  } = useBoolean(false);
  const {
    value: showBadOptions,
    setTrue: openBadOptions,
    setFalse: dismissBadOptions,
  } = useBoolean(false);

  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);

  const onClick = useCallback(
    (feedback_type: IChatbotFeedbackType, tags?: string[]) => {
      const thread_id = config?.thread_id || message.thread_id;
      if (!config?.athlete_id || !thread_id) {
        return;
      }

      if (tags?.[0] === t('label.other')) {
        openFeedbackModal();
        return;
      }

      dismissBadOptions();

      aiRepository
        .sendMessageFeedback({
          athleteId: config.athlete_id,
          body: {
            message_id: message.id,
            thread_id,
            feedback_type,
            tags: tags || [],
            user_message: '',
          },
        })
        .then(() => {
          setFeedback(feedback_type);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [config, t, dismissBadOptions, aiRepository, message, openFeedbackModal]
  );

  const badOptions: string[] = [
    t('label.irrelevant_response'),
    t('label.inaccurate_information_or_data'),
    t('label.calculation_error'),
    t('label.other'),
  ];

  const badOptionsJSX = badOptions.map((option: string) => {
    return (
      <button
        key={option}
        className="assistant__feedback-bad-option analog-typography--subtitle"
        onClick={() => onClick(IChatbotFeedbackType.Bad, [option])}
      >
        {option}
      </button>
    );
  });

  const handleOpenBadOptions = useCallback(() => {
    const messages = useAiAssistantStore.getState().messages || [];
    useAiAssistantStore.getState().setMessages([...messages]);

    openBadOptions();
  }, [openBadOptions]);

  const onSendFeedback = useCallback(() => {
    setFeedback(IChatbotFeedbackType.Bad);
    dismissBadOptions();
  }, [dismissBadOptions]);

  return (
    <div className="ai-assistant__message-feedback">
      <div className="ai-assistant__message-feedback-actions">
        <IconButton
          size="xs"
          variant="quietLayer2"
          className={clsx(
            feedback && 'ai-assistant__feedback-action--readonly',
            feedback === IChatbotFeedbackType.Good &&
              'ai-assistant__feedback-action--active'
          )}
          content={<ThumbsUp />}
          onClick={() => onClick(IChatbotFeedbackType.Good)}
        />
        <IconButton
          size="xs"
          variant="quietLayer2"
          className={clsx(
            feedback && 'ai-assistant__feedback-action--readonly',
            feedback === IChatbotFeedbackType.Bad &&
              'ai-assistant__feedback-action--active'
          )}
          content={<ThumbsDown />}
          onClick={handleOpenBadOptions}
        />
      </div>
      {showBadOptions && (
        <div className="assistant__feedback-bad-options">{badOptionsJSX}</div>
      )}

      {showFeedbackModal && (
        <FeedbackModal
          onDismiss={dismissFeedbackModal}
          messageId={message.id}
          onSuccess={onSendFeedback}
        />
      )}
    </div>
  );
};
