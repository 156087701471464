import { useTranslation } from 'react-i18next';
import {
  IconButton,
  IMenuBaseOption,
  Menu,
  Toast,
} from '@cycling-web/analog-ui';
import { Ellipsis, RefreshCcwIcon, Trash2 } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { IUserBase, UserStatus } from '../../../../types/users';
import { useAdminTeamMemberStore } from '../../../AdminPanel/store/slice';

type IProps = {
  user: IUserBase;
};

export const Actions = ({ user }: IProps) => {
  const { t } = useTranslation();

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const handleResendInvitation = useCallback(() => {
    usersRepository
      .resendInvitation({
        userEmail: user.email,
      })
      .then(() => {
        Toast.success({
          title: t('success.resend_invitation_title'),
        });
      });
  }, [usersRepository, user.email, t]);

  const handleDeleteInvitation = useCallback(() => {
    usersRepository
      .deleteInvitation({
        userEmail: user.email,
      })
      .then(() => {
        useAdminTeamMemberStore.getState().removeUser(user.email);
        Toast.success({
          title: t('success.delete_invitation_title'),
        });
      });
  }, [usersRepository, user.email, t]);

  const menuOptions: IMenuBaseOption[] = useMemo(() => {
    const options: IMenuBaseOption[] = [];

    if (user.status === UserStatus.Pending) {
      options.push(
        {
          id: 'resend',
          icon: <RefreshCcwIcon size={16} />,
          text: t('action.resend_invitation'),
        },
        {
          id: 'delete',
          icon: <Trash2 size={16} />,
          text: t('action.delete_invitation'),
        }
      );
    }

    return options;
  }, [t, user.status]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'resend') {
        handleResendInvitation();
      }

      if (option.id === 'delete') {
        handleDeleteInvitation();
      }
    },
    [handleDeleteInvitation, handleResendInvitation]
  );

  if (menuOptions.length === 0) {
    return null;
  }

  return (
    <Menu
      options={menuOptions}
      onChange={onMenuChange}
      dropdownProps={{
        anchor: <IconButton variant="quietLayer2" content={<Ellipsis />} />,
      }}
    />
  );
};
