import './index.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { User } from 'lucide-react';
import { SmartChartsRepository } from '../../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../../api/smart-charts/service';
import {
  Dialog,
  EmptyState,
  Radio,
  Skeleton,
  Toast,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';
import { useSmartChartsContext } from '../../context';
import {
  IPinnedTab,
  SmartChartsAthletes,
} from '../../../../types/smart-charts';
import { useGroupsStore } from '../../../../store/groups/slice';
import { IGroup } from '../../../../types/groups';

type IProps = {
  onDismiss: () => void;
};

export const PinChartModal = ({ onDismiss }: IProps) => {
  const { t } = useTranslation();
  const { selectedAthletes } = useSmartChartsContext();
  const groups = useGroupsStore((s) => s.groups);
  const groupsMap = useMemo((): Record<number, IGroup> => {
    return groups.reduce((acc, group) => {
      if (acc[group.id] === undefined) {
        acc[group.id] = group;
      }
      return acc;
    }, {});
  }, [groups]);
  const fetchingRef = useRef<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [pinnedTabs, setPinnedTabs] = useState<IPinnedTab[]>([]);
  const [selectedOption, setSelectedOption] = useState<Record<string, string>>(
    {}
  );
  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);
  const { chartId } = useParams();

  const handleRadioChange = (option: string, tabId: string) => {
    setSelectedOption({ [tabId]: option });
  };

  const handleSubmitClick = useCallback(() => {
    if (loading) {
      return;
    }
    if (chartId) {
      setLoading(true);
      smartChartsRepository
        .addPinnedTab({
          graphId: chartId,
          value: Object.values(selectedOption)[0] || pinnedTabs?.[0]?.tab_name,
          tabId: Object.keys(selectedOption)[0] || pinnedTabs?.[0]?.tab_id,
        })
        .then(() => {
          Toast.success({
            title: t('success.pin_chart_title'),
          });
          onDismiss();
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== ApiErrorCode.Unauthorized) {
            Toast.error({
              title: t('error.pin_chart_title'),
              message: t('error.pin_chart_message'),
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    chartId,
    loading,
    selectedOption,
    smartChartsRepository,
    t,
    pinnedTabs,
    onDismiss,
  ]);

  useEffect(() => {
    if (fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;

    smartChartsRepository
      .getPinnedTabs({
        type:
          selectedAthletes?.length > 1
            ? SmartChartsAthletes.Multiple
            : SmartChartsAthletes.Single,
      })
      .then((tabsResponse: IPinnedTab[]) => {
        setPinnedTabs(tabsResponse);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error({
            title: t('error.get_tabs_for_smart_chart_title'),
            message: t('error.get_tabs_for_smart_chart_message'),
          });
        }
      })
      .finally(() => {
        fetchingRef.current = false;
        setFetching(false);
      });
  }, [selectedAthletes, smartChartsRepository, t]);

  const selectedOptions = Object.keys(selectedOption);
  const options = (pinnedTabs || []).filter((option: IPinnedTab) => !!option);

  const optionsJSX = options.map((option: IPinnedTab, index) => {
    const checked =
      selectedOptions.length === 0 && index === 0
        ? true
        : selectedOptions[0] === option?.tab_id;

    const group = option.tab_metadata?.group_id
      ? groupsMap[option.tab_metadata.group_id]
      : null;

    const displayName = group
      ? `${group.name} - ${option.tab_name}`
      : option.tab_name;

    return (
      <Radio
        key={option.tab_id}
        name={option.tab_id}
        value={option.tab_id}
        label={displayName}
        checked={checked}
        onChange={() => handleRadioChange(option.tab_name, option.tab_id)}
      />
    );
  });

  return (
    <Dialog
      onDismiss={onDismiss}
      title={t('label.pin_chart')}
      submitButtonProps={{
        disabled: options.length === 0,
        onClick: handleSubmitClick,
        content: t('action.pin'),
        loading: loading,
      }}
      preventDefaultOnClick={false}
    >
      <div className="sc2_pin-modal-container">
        {fetching ? (
          <div className="sc2_pin-modal-radioButtons">
            <Skeleton height="21px" width="180px" />
            <Skeleton height="21px" width="180px" />
          </div>
        ) : options.length > 0 ? (
          <div className="sc2_pin-modal-radioButtons">{optionsJSX}</div>
        ) : (
          <EmptyState
            icon={<User size={32} color="var(--analog-text-secondary)" />}
            text={t('banner.empty_pinned_tab_message')}
          />
        )}
      </div>
    </Dialog>
  );
};
