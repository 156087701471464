import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import {
  IAddAiGraphPinTabRequest,
  IAddAiGraphPinTabResponse,
  ICreateConversationRequest,
  ICreateConversationResponse,
  IDeleteAiGraphPinTabRequest,
  IDeleteAiGraphPinTabResponse,
  IDeleteSmartChartConversationRequest,
  IDeleteSmartChartConversationResponse,
  IGetAiGraphAllPinTabsRequest,
  IGetAiGraphAllPinTabsResponse,
  IGetComputeDataRequest,
  IGetComputeDataResponse,
  IGetConfigurationRequest,
  IGetConfigurationResponse,
  IGetSimplifiedComputeDataRequest,
  IGetSimplifiedComputeDataResponse,
  IGetSmartChartHistoryResponse,
  ISendMessageRequest,
  ISendMessageResponse,
  IUpdateConfigRequest,
  IUpdateConfigResponse,
  IUpdateMultipleAthleteConfigRequest,
  IUpdateMultipleAthleteConfigResponse,
} from './types';
import qs from 'qs';
import { AxiosRequestConfig } from 'axios';

interface ISmartChartsService {
  getHistory: () => R<IGetSmartChartHistoryResponse>;
  createConversation: (
    p: ICreateConversationRequest
  ) => R<ICreateConversationResponse>;
  deleteConversation: (
    p: IDeleteSmartChartConversationRequest
  ) => R<IDeleteSmartChartConversationResponse>;
  getConfiguration: (
    p: IGetConfigurationRequest,
    c?: AxiosRequestConfig
  ) => R<IGetConfigurationResponse>;
  getComputeData: (
    p: IGetComputeDataRequest,
    c?: AxiosRequestConfig
  ) => R<IGetComputeDataResponse>;

  sendMessage: (
    p: ISendMessageRequest,
    c?: AxiosRequestConfig
  ) => R<ISendMessageResponse>;

  updateConfig: (p: IUpdateConfigRequest) => R<IUpdateConfigResponse>;
  updateMultipleAthleteConfig: (
    p: IUpdateMultipleAthleteConfigRequest
  ) => R<IUpdateMultipleAthleteConfigResponse>;
  getPinnedTabs: (
    p: IGetAiGraphAllPinTabsRequest
  ) => R<IGetAiGraphAllPinTabsResponse>;
  addPinnedTab: (p: IAddAiGraphPinTabRequest) => R<IAddAiGraphPinTabResponse>;

  getSimplifiedComputeData: (
    p: IGetSimplifiedComputeDataRequest
  ) => R<IGetSimplifiedComputeDataResponse>;
}

export class SmartChartsService implements ISmartChartsService {
  async getHistory(): R<IGetSmartChartHistoryResponse> {
    try {
      return axiosInstance.get(`/v1/ai/v1/ai-graph/user_history_panel`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteConversation(
    p: IDeleteSmartChartConversationRequest
  ): R<IDeleteSmartChartConversationResponse> {
    try {
      return axiosInstance.delete(`/v1/ai/v1/ai-graph/delete_graphs`, {
        data: {
          command: 'delete_specific_graph_ids',
          specific_graph_ids: [`${p.chartId}`],
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getConfiguration(
    p: IGetConfigurationRequest,
    c?: AxiosRequestConfig
  ): R<IGetConfigurationResponse> {
    try {
      return axiosInstance.get(
        `/v1/ai/v1/ai-graph/${p.graphId}/configuration`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getComputeData(
    p: IGetComputeDataRequest,
    c?: AxiosRequestConfig
  ): R<IGetComputeDataResponse> {
    try {
      return axiosInstance.post(
        `/v1/ai/v1/ai-graph/${p.graphId}/compute_data`,
        {},
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createConversation(
    p: ICreateConversationRequest
  ): R<ICreateConversationResponse> {
    try {
      return axiosInstance.post(`/v1/ai/v1/ai-graph/new`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendMessage(
    p: ISendMessageRequest,
    c?: AxiosRequestConfig
  ): R<ISendMessageResponse> {
    try {
      return await axiosInstance({
        method: 'POST',
        url: `/v1/ai/v1/ai-graph/${p.graphId}/ask?stream=true`,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
        data: {
          query: p.query,
        },
        signal: c?.signal,
        responseType: 'stream',
        timeout: 0,
        onDownloadProgress: (progressEvent: any) => {
          const reader = progressEvent.event?.currentTarget?.responseText || '';
          p.onChunkReceive(reader);
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateMultipleAthleteConfig(
    p: IUpdateMultipleAthleteConfigRequest
  ): R<IUpdateMultipleAthleteConfigResponse> {
    try {
      const data = {
        ai_graph_in_scope: {
          data_sources: p.dataSources,
        },
      };
      return axiosInstance.patch(
        `/v1/ai/v1/ai-graph/${p.graphId}/configuration_top_bar_selection`,
        data
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateConfig(p: IUpdateConfigRequest): R<IUpdateConfigResponse> {
    try {
      return axiosInstance.patch(`/v1/ai/v1/ai-graph/${p.id}/configuration`, p);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPinnedTabs(
    p: IGetAiGraphAllPinTabsRequest
  ): R<IGetAiGraphAllPinTabsResponse> {
    const params = qs.stringify(
      {
        filtered_tab_type: p.type,
        tab_metadata: {
          group_id: p.groupId,
        },
      },
      { allowDots: true, skipNulls: true }
    );
    try {
      return axiosInstance.get(`/v1/ai/v1/ai-graph/user_tab?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addPinnedTab(
    p: IAddAiGraphPinTabRequest
  ): R<IAddAiGraphPinTabResponse> {
    try {
      const data = {
        tab_graph_relation_metadata: {
          random_value: p.value,
        },
        graph_id: p.graphId,
      };

      return axiosInstance.post(
        `/v1/ai/v1/ai-graph/user_tab/${p.tabId}/add_graph`,
        data
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSimplifiedComputeData(
    p: IGetSimplifiedComputeDataRequest
  ): R<IGetSimplifiedComputeDataResponse> {
    try {
      const data = {
        data_sources: p.dataSources,
      };
      return axiosInstance.post(
        `/v1/ai/v1/ai-graph/${p.graphId}/compute_data_simplified`,
        data
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deletePinnedTab(
    p: IDeleteAiGraphPinTabRequest
  ): R<IDeleteAiGraphPinTabResponse> {
    try {
      const data = {
        graph_id: p.graphId,
      };
      return axiosInstance.post(
        `/v1/ai/v1/ai-graph/user_tab/${p.pinnedTabId}/delete_graph`,
        data
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
