import { AxiosResponse } from 'axios';
import { ReactNode } from 'react';

export type AnyType = any;

export type R<T> = Promise<AxiosResponse<T>>;

export type WithData<T> = {
  code: number;
  data: T;
  message: string;
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type IHandleChangeFilter<T = any> = (
  key: keyof T
) => (value: T[keyof T]) => void;

export type IUserProfileBase = {
  id: number;
  athleteId: number;
  coachId: number;
  sub: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  picture: string;
  roles: UserRole[];
  onboardingStatus?: OnboardingStep;
};

export enum UserRole {
  Admin = 'Admin',
  SportsDirector = 'Sports Director',
  Coach = 'Coach',
  ExternalCoach = 'External Coach',
  Athlete = 'Athlete',
  Doctor = 'Doctor',
  SecondaryDoctor = 'Secondary Doctor',
  Nutritionist = 'Nutritionist',
  Physiotherapist = 'Physiotherapist',
  MentalCoach = 'Mental Coach',
  Management = 'Management',
}

export enum OnboardingStep {
  InvitationAccepted = 'INVITATION_ACCEPTED',
  PrivacyAccepted = 'PRIVACY_ACCEPTED',
  ProfileInfo = 'PERSONAL_INFORMATION',
  PerformanceInfo = 'PERFORMANCE_INFORMATION',
  Platforms = 'ATTACH_PLATFORMS',
  Devices = 'ATTACH_DEVICES',
  Completed = 'COMPLETED',
}

export enum Platform {
  TrainingPeaks = 'training-peaks',
}

export enum Device {
  UltraHuman = 'ultra-human',
  Whoop = 'whoop',
  Wahoo = 'wahoo',
}

export type ICookieConsent = {
  essential: {
    all: boolean;
  };
  optional: {
    analytical: boolean;
  };
};

export type INavigationItem = INavigationLink | INavigationGroup;

export type INavigationLink = {
  url: string;
  text: string;
  icon?: ReactNode;
};

export type INavigationGroup = {
  group: string;
  active: boolean;
  icon: ReactNode;
  text: string;
  items: INavigationLink[];
};

export type IProxyRequest = {
  url: string;
  method: string;
  body: string;
  headers?: Record<string, string>;
};
