import './index.css';
import { useTranslation } from 'react-i18next';
import { Avatar, LabelValue } from '@cycling-web/analog-ui';
import { SeverityTag } from '../../../../components/columns/SeverityTag';
import { IMedicalReport } from '../../../../types/medical';
import { format } from 'date-fns';
import { EMPTY } from '../../../../constants';
import React, { useMemo } from 'react';
import { useDictionaryValue } from '../../../../hooks/useDictionaryValue';
import { useLocation } from 'react-router';
import { ROUTES } from '../../../../router/routes';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useAthleteMedicalReportStore } from '../../store/slice';

type IProps = {
  athleteReport: IMedicalReport;
};

export const ReportDetails = ({ athleteReport }: IProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const showAthleteAvatar = pathname.includes(ROUTES.MEDICAL_REPORT_TABLE);
  const getDictionaryValue = useDictionaryValue();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const report = useAthleteMedicalReportStore((s) => s.report);
  const athlete = report ? athletesMap[report.athleteId] : undefined;
  const labelWidth = '200px';

  const symptoms = useMemo(() => {
    return athleteReport.symptom
      ? athleteReport.symptom
          .split(',')
          .map((symptom: string) => {
            return getDictionaryValue({
              container: 'symptom',
              recordType: athleteReport.recordType,
              key: symptom,
            });
          })
          .join(', ')
      : '';
  }, [athleteReport.recordType, athleteReport.symptom, getDictionaryValue]);

  return (
    <div className="medical-report__details">
      {showAthleteAvatar && athlete && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.athlete')}:`}
          value={<Avatar src={athlete.picture} text={athlete.fullName} />}
        />
      )}

      {athleteReport.bodyLocation && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.body_location')}:`}
          value={getDictionaryValue({
            container: 'bodyLocation',
            recordType: athleteReport.recordType,
            key: athleteReport.bodyLocation,
          })}
        />
      )}

      {athleteReport.area && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.area')}:`}
          value={getDictionaryValue({
            container: 'bodyArea',
            recordType: athleteReport.recordType,
            key: athleteReport.area,
          })}
        />
      )}

      {athleteReport.symptom && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.symptom')}:`}
          value={symptoms}
        />
      )}

      {athleteReport.severity && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.severity')}:`}
          value={<SeverityTag severity={athleteReport.severity} />}
        />
      )}

      {athleteReport.side && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.side')}:`}
          value={getDictionaryValue({
            container: 'side',
            key: athleteReport.side,
          })}
        />
      )}

      {athleteReport.ossicsCode && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.osiics_code')}:`}
          value={getDictionaryValue({
            container: 'ossicsCode',
            key: athleteReport.ossicsCode,
          })}
        />
      )}

      {athleteReport.rehabilitationStage && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.rehabilitation_stage')}:`}
          value={getDictionaryValue({
            container: 'rehabilitationStage',
            key: athleteReport.rehabilitationStage,
          })}
        />
      )}

      {athleteReport.type && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.type')}:`}
          value={getDictionaryValue({
            container: 'type',
            key: athleteReport.type,
          })}
        />
      )}

      {athleteReport.onsetMode && (
        <LabelValue
          labelWidth={labelWidth}
          label={`${t('label.onset_mode')}:`}
          value={getDictionaryValue({
            container: 'onsetMode',
            key: athleteReport.onsetMode,
            isArray: true,
          })}
        />
      )}

      <LabelValue
        labelWidth={labelWidth}
        label={`${t('label.onset_date')}:`}
        value={format(new Date(athleteReport.onsetDate * 1000), 'dd/MM/yyyy')}
      />

      <LabelValue
        labelWidth={labelWidth}
        label={`${t('label.estimate_recovery_date')}:`}
        value={format(
          new Date(athleteReport.estimatedRecovery * 1000),
          'dd/MM/yyyy'
        )}
      />

      <LabelValue
        labelWidth={labelWidth}
        label={`${t('label.added_by')}:`}
        value={athleteReport.createdBy || EMPTY}
      />
    </div>
  );
};
