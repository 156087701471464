import { useContext } from 'react';
import { DialogContext } from './context';
import { clsx } from 'clsx';
import { Button } from '../Button/Button';
import { IDialogFooterProps } from './types';

export const DialogFooter = ({ children }: IDialogFooterProps) => {
  const { onRenderFooter, onDismiss, cancelButtonProps, submitButtonProps } =
    useContext(DialogContext);

  if (onRenderFooter) {
    return onRenderFooter();
  }

  const rootClass = clsx('analog-dialog__footer');

  return (
    <footer className={rootClass}>
      <div className="analog-dialog__footer-buttons">
        {children || (
          <>
            <Button
              {...cancelButtonProps}
              variant={cancelButtonProps?.variant || 'quietLayer3'}
              onClick={cancelButtonProps?.onClick || onDismiss}
              content={cancelButtonProps?.content || 'Cancel'}
            />
            <Button
              {...submitButtonProps}
              content={submitButtonProps?.content || 'Submit'}
            />
          </>
        )}
      </div>
    </footer>
  );
};
