import { AuthService } from './service';
import {
  IAuthServiceOptions,
  IOAuthTokenRequest,
  IOAuthTokenResponse,
  IRefreshTokenRequest,
  IRefreshTokenResponse,
  IResetPasswordChallengeRequest,
  IResetPasswordChallengeResponse,
  IResetPasswordContinueRequest,
  IResetPasswordContinueResponse,
  IResetPasswordStartRequest,
  IResetPasswordStartResponse,
  IResetPasswordSubmitRequest,
  IResetPasswordSubmitResponse,
  ISignInChallengeRequest,
  ISignInChallengeResponse,
  ISignInStartRequest,
  ISignInStartResponse,
  ISignUpChallengeRequest,
  ISignUpChallengeResponse,
  ISignUpContinueRequest,
  ISignUpContinueResponse,
  ISignUpStartRequest,
  ISignUpStartResponse,
} from './types';

export interface IAuthRepository {
  signUpStart: (p: ISignUpStartRequest) => Promise<ISignUpStartResponse>;
  signUpChallenge: (
    p: ISignUpChallengeRequest
  ) => Promise<ISignUpChallengeResponse>;
  signUpContinue: (
    p: ISignUpContinueRequest
  ) => Promise<ISignUpContinueResponse>;
  getOAuthToken: (p: IOAuthTokenRequest) => Promise<IOAuthTokenResponse>;
  refreshToken: (p: IRefreshTokenRequest) => Promise<IRefreshTokenResponse>;

  signInStart: (p: ISignInStartRequest) => Promise<ISignInStartResponse>;
  signInChallenge: (
    p: ISignInChallengeRequest
  ) => Promise<ISignInChallengeResponse>;

  resetPasswordStart: (
    p: IResetPasswordStartRequest
  ) => Promise<IResetPasswordStartResponse>;
  resetPasswordChallenge: (
    p: IResetPasswordChallengeRequest
  ) => Promise<IResetPasswordChallengeResponse>;
  resetPasswordContinue: (
    p: IResetPasswordContinueRequest
  ) => Promise<IResetPasswordContinueResponse>;
  resetPasswordSubmit: (
    p: IResetPasswordSubmitRequest
  ) => Promise<IResetPasswordSubmitResponse>;
}

export class AuthRepository implements IAuthRepository {
  private static instance: AuthRepository;

  constructor(private readonly service: AuthService) {}

  public static getInstance(options?: IAuthServiceOptions): AuthRepository {
    if (!AuthRepository.instance) {
      if (!options) {
        throw new Error(
          'AuthRepository requires options for the first initialization.'
        );
      }
      AuthRepository.instance = new AuthRepository(new AuthService(options));
    }
    return AuthRepository.instance;
  }

  async signUpStart(p: ISignUpStartRequest): Promise<ISignUpStartResponse> {
    return await this.service.signUpStart(p);
  }

  async signUpChallenge(
    p: ISignUpChallengeRequest
  ): Promise<ISignUpChallengeResponse> {
    return await this.service.signUpChallenge(p);
  }

  async signUpContinue(
    p: ISignUpContinueRequest
  ): Promise<ISignUpContinueResponse> {
    return await this.service.signUpContinue(p);
  }

  async getOAuthToken(p: IOAuthTokenRequest): Promise<IOAuthTokenResponse> {
    return await this.service.getOAuthToken({
      ...p,
      scope: 'email openid profile offline_access',
    });
  }

  async refreshToken(p: IRefreshTokenRequest): Promise<IRefreshTokenResponse> {
    return await this.service.refreshToken({
      ...p,
      grant_type: 'refresh_token',
      scope: 'email openid profile offline_access',
    });
  }

  async signInStart(p: ISignInStartRequest): Promise<ISignInStartResponse> {
    return await this.service.signInStart(p);
  }

  async signInChallenge(
    p: ISignInChallengeRequest
  ): Promise<ISignInChallengeResponse> {
    return await this.service.signInChallenge(p);
  }

  async resetPasswordStart(
    p: IResetPasswordStartRequest
  ): Promise<IResetPasswordStartResponse> {
    return await this.service.resetPasswordStart(p);
  }

  async resetPasswordChallenge(
    p: IResetPasswordChallengeRequest
  ): Promise<IResetPasswordChallengeResponse> {
    return await this.service.resetPasswordChallenge(p);
  }

  async resetPasswordContinue(
    p: IResetPasswordContinueRequest
  ): Promise<IResetPasswordContinueResponse> {
    return await this.service.resetPasswordContinue(p);
  }

  async resetPasswordSubmit(
    p: IResetPasswordSubmitRequest
  ): Promise<IResetPasswordSubmitResponse> {
    return await this.service.resetPasswordSubmit(p);
  }
}
