import { useTranslation } from 'react-i18next';
import { Button, FormElement, Typography } from '@cycling-web/analog-ui';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputControl } from '@cycling-web/common';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';

type IProps = {
  onDismiss: () => void;
  onStepChange: (n: number) => () => void;
};

export const UHStep1 = ({ onDismiss, onStepChange }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        email: z
          .string()
          .min(1, { message: t('validation.required') })
          .email({ message: t('validation.invalid_email') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit = useCallback(() => {
    handleSubmit((formData) => {
      setLoading(true);
      platformRepository
        .addUltrahumanAccount(formData)
        .then(() => {
          onStepChange(1)();
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [handleSubmit, onStepChange, platformRepository]);

  return (
    <FormProvider {...form}>
      <div className="uh__step">
        <header className="uh__step-header">
          <Typography
            variant="subtitle"
            weight="regular"
            text={t('label.ultrahuman_step1_title')}
            className="uh__step-header-title"
          />
        </header>
        <div>
          <FormElement
            label={t('label.email')}
            message={formState.errors?.email?.message}
          >
            <InputControl
              name="email"
              placeholder={t('placeholder.ultrahuman_email')}
              invalid={!!formState.errors.email}
            />
          </FormElement>
        </div>
        <footer className="ultrahuman-onboarding-modal__footer">
          <Button
            variant="quietLayer2"
            content={t('action.cancel')}
            onClick={onDismiss}
          />
          <Button
            content={t('action.next')}
            onClick={onSubmit}
            loading={loading}
          />
        </footer>
      </div>
    </FormProvider>
  );
};
