import './index.css';
import type { ITooltipProps } from './types';
import { Dropdown } from '../Dropdown';
import { clsx } from 'clsx';
import { ITextFieldSize } from '../../types';

export const Tooltip = (props: ITooltipProps) => {
  const rootClass = clsx('analog-tooltip', props.className);
  const size = props.size || 'm';
  const delay = props.delay || {
    open: 200,
    close: 0,
  };

  const contentClassMap: Record<ITextFieldSize, string> = {
    s: 'analog-typography--subtitle',
    m: 'analog-typography--body',
    l: 'analog-typography--body',
  };

  const contentClassName = clsx(
    'analog-tooltip__content',
    `analog-tooltip__content--${size}`,
    contentClassMap[size]
  );

  return (
    <Dropdown
      {...props}
      className={rootClass}
      interactions={['hover', 'focus']}
      delay={delay}
      contentClassName={contentClassName}
    />
  );
};
