import { ChartWrapper } from '../../../../components/ChartWrapper';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IPinnedTabsFilters } from '../../types';
import { AnyValue } from '../../../../types/common';
import { usePinnedTabStore } from '../../../../store/pinned-tabs/slice';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { Chart } from '../../../../components/Chart';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { ISmartChartConfig } from '../../../../types/smart-charts';
import { computeGraphOption } from '../../../SmartCharts/utils/graphConfig';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useGroupsStore } from '../../../../store/groups/slice';
import { PinnedChartMenu } from '../PinnedChartMenu';
import { IconButton, Tooltip } from '@cycling-web/analog-ui';
import { Maximize2, Minimize2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IFiltersBarProps } from '@cycling-web/common';

type IProps = {
  chart: ISmartChartConfig;
};

export const PinnedChart = ({ chart }: IProps) => {
  const { t } = useTranslation();
  const athlete = useAthleteStore((s) => s.athlete);
  const filters = usePinnedTabStore((s) => s.filters);
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();
      setExpanded((expanded) => !expanded);
    },
    [setExpanded]
  );

  const setFilters = usePinnedTabStore((s) => s.setFilters);
  const handleChangeFilter = useCallback(
    (key: keyof IPinnedTabsFilters) => {
      return (value: AnyValue) => {
        setFilters({ [key]: value });
      };
    },
    [setFilters]
  );

  const chartsLoaded = usePinnedTabStore((s) => s.chartsLoaded);
  const group = useGroupsStore((s) => s.group);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  const selectedAthletes = useMemo(() => {
    if (!athletesLoaded) {
      return [];
    }

    if (group) {
      return group.athleteIds.map((id: number) => athletesMap[id]);
    }

    if (athlete) {
      return [athlete];
    }

    return [];
  }, [athletesLoaded, group, athlete, athletesMap]);

  const option = useMemo(() => {
    return computeGraphOption(chart, selectedAthletes);
  }, [chart, selectedAthletes]);

  const filtersBarProps: IFiltersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'layer3' }}
      />
    ),
    buttonProps: {
      variant: 'layer3',
    },
  };

  const adornmentLeftJSX = (
    <Tooltip
      anchor={
        <IconButton
          variant="layer3"
          content={expanded ? <Minimize2 /> : <Maximize2 />}
          onClick={toggleExpand}
        />
      }
      content={expanded ? t('action.collapse') : t('action.expand')}
      delay={{
        open: 500,
        close: 0,
      }}
      placement="bottom-end"
    />
  );
  const adornmentRightJSX = <PinnedChartMenu chart={chart} />;

  const chartName = chart.ai_graph_meta_data.title.value;
  const trackingChartName = `${athlete?.fullName} - ${chart?.id}`;

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: chartName,
          filtersBarProps,
          // adornmentLeft: adornmentLeftJSX,
          adornmentRight: adornmentRightJSX,
        }}
        option={option}
        loading={!chartsLoaded}
        events={{
          onMouseOver: handleChartMouseOver({
            name: trackingChartName,
          }),
        }}
      />
    </ChartWrapper>
  );
};
