import './index.css';
import type { IPopoverProps } from './types';
import { Dropdown } from '../Dropdown';
import { clsx } from 'clsx';
import { ITextFieldSize } from '../../types';

export const Popover = (props: IPopoverProps) => {
  const rootClass = clsx('analog-popover', props.className);
  const size = props.size || 'm';

  const contentClassMap: Record<ITextFieldSize, string> = {
    s: 'analog-typography--subtitle',
    m: 'analog-typography--body',
    l: 'analog-typography--body',
  };

  const contentClassName = clsx(
    'analog-popover__content',
    `analog-popover__content--${size}`,
    contentClassMap[size],
    props.contentClassName
  );

  return (
    <Dropdown
      {...props}
      className={rootClass}
      interactions={['click', 'dismiss']}
      contentClassName={contentClassName}
    />
  );
};
