import { useState } from 'react';
import { Input } from './';
import { IInputPasswordProps, IInputSize, IInputVariant } from './types';
import { IconButton } from '../Button/IconButton';
import { Eye, EyeOff } from 'lucide-react';
import {
  textFieldButtonSize,
  textFieldButtonVariant,
} from '../../utils/common';

export const InputPassword = (props: IInputPasswordProps) => {
  const size: IInputSize = props.size || 'm';
  const variant: IInputVariant = props.variant || 'layer2';
  const [visible, setVisible] = useState<boolean>(false);

  const onChangeType = () => {
    setVisible((visible: boolean) => !visible);
  };

  const title: string = visible
    ? props.translations?.hide || 'Hide'
    : props.translations?.show || 'Show';

  return (
    <Input
      {...props}
      placeholder={props.placeholder || 'Password'}
      type={visible ? 'text' : 'password'}
      endAdornment={
        <IconButton
          size={textFieldButtonSize[size]}
          variant={textFieldButtonVariant[variant]}
          onClick={onChangeType}
          title={title}
          content={visible ? <EyeOff /> : <Eye />}
        />
      }
    />
  );
};
