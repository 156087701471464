export const curveFormatTime = (seconds: number): string => {
  if (seconds >= 3600) {
    return Math.round(seconds / 3600) + 'h';
  } else if (seconds >= 60) {
    return Math.round(seconds / 60) + 'm';
  } else {
    return Math.round(seconds) + 's';
  }
};

export const curveDisplayValues: number[] = [
  1, 2, 5, 10, 20, 40, 60, 120, 300, 600, 1200, 2400, 3600, 7200, 14400, 21600,
];

export const smoothCurveDelegate = (data: number[], index: number): number => {
  const maxWindowSize = 1;
  const windowSize = Math.max(maxWindowSize, Math.floor(index / 3) + 3);
  const start = Math.max(0, index - Math.floor(windowSize / 2));
  const end = Math.min(data.length, index + Math.floor(windowSize / 2) + 1);

  let sum = 0;
  for (let i = start; i < end; i++) {
    sum += data[i];
  }

  return sum / (end - start);
};

export const CurveThresholds: { seconds: number; step: number }[] = [
  {
    seconds: 120,
    step: 1,
  },
  {
    seconds: 600,
    step: 5,
  },
  {
    seconds: 1200,
    step: 10,
  },
  {
    seconds: 3600,
    step: 30,
  },
  {
    seconds: 7200,
    step: 60,
  },
  {
    seconds: 14400,
    step: 300,
  },
  {
    seconds: 50000,
    step: 600,
  },
];
