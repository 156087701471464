import { ConfirmDialog } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { IPlatform } from '../../../types/platform';
import { PlatformName } from '../../../constants/platforms';

type IProps = {
  platform: IPlatform | undefined;
  onDismiss: () => void;
  onSubmit: () => Promise<void>;
};

export const DisconnectConfirmDialog = ({
  platform,
  onDismiss,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation();

  const submitText = `${t('label.yes')}, ${t('action.disconnect')}`;
  const cancelText = `${t('label.no')}, ${t('action.cancel')}`;

  return (
    <ConfirmDialog
      onDismiss={onDismiss}
      title={t('confirm.disconnect_device_title', {
        platform: platform?.platformName
          ? PlatformName[platform.platformName]
          : '',
      })}
      subtitle={t('confirm.disconnect_device_message')}
      cancelText={cancelText}
      submitText={submitText}
      submitAction={onSubmit}
    />
  );
};
