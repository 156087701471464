import { useEffect, useMemo, useRef } from 'react';
import { SmartChartsRepository } from '../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../api/smart-charts/service';
import {
  IPinnedTab,
  IPinnedTabGraph,
  ISmartChartConfig,
  SmartChartsDataSource,
} from '../../../types/smart-charts';
import { useParams } from 'react-router';
import { usePinnedTabStore } from '../../../store/pinned-tabs/slice';
import { useGroupsStore } from '../../../store/groups/slice';
import { useAthleteStore } from '../../../store/athlete/slice';
import { useAthletesStore } from '../../../store/athletes/slice';
import { calculateDateRange } from '../../../utils/utils';
import { IAthlete } from '../../../types/athletes';

export const useFetchPinnedCharts = () => {
  const fetchingRef = useRef<boolean>(false);
  const { pinnedTabId } = useParams();
  const tabs = usePinnedTabStore((s) => s.pinnedTabs);
  const setCharts = usePinnedTabStore((s) => s.setCharts);
  const setChartsLoaded = usePinnedTabStore((s) => s.setChartsLoaded);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const selectedTab = useMemo(() => {
    if (!pinnedTabId) {
      return undefined;
    }
    return tabs.find((tab: IPinnedTab) => tab.tab_id === pinnedTabId);
  }, [tabs, pinnedTabId]);

  const group = useGroupsStore((s) => s.group);
  const athlete = useAthleteStore((s) => s.athlete);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  const selectedAthletes = useMemo(() => {
    if (!athletesLoaded) {
      return [];
    }

    if (group) {
      return group.athleteIds.map((id: number) => athletesMap[id]);
    }

    if (athlete) {
      return [athlete];
    }

    return [];
  }, [athletesLoaded, group, athlete, athletesMap]);

  const filters = usePinnedTabStore((s) => s.filters);

  const dataSource = useMemo((): any => {
    if (selectedAthletes.length === 0 || !filters.period) {
      return [];
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    const athletesSource = selectedAthletes.map((a: IAthlete) => {
      return {
        type: SmartChartsDataSource.Athlete,
        athlete_id: a.id.toString(),
        athlete_full_name: a.fullName,
      };
    });

    return [
      ...athletesSource,
      {
        type: SmartChartsDataSource.Date,
        date_name: 'graph_start_date',
        selected_date: startDate?.toISOString()?.slice(0, 10),
      },
      {
        type: SmartChartsDataSource.Date,
        date_name: 'graph_end_date',
        selected_date: endDate?.toISOString()?.slice(0, 10),
      },
    ];
  }, [selectedAthletes, filters.period]);

  useEffect(() => {
    if (!selectedTab || fetchingRef.current || dataSource.length === 0) {
      return;
    }

    fetchingRef.current = true;

    const promises: Promise<ISmartChartConfig>[] = [];
    selectedTab.tab_graphs.forEach((graph: IPinnedTabGraph) => {
      const promise = smartChartsRepository.getSimplifiedComputeData({
        graphId: graph.graph_id,
        dataSources: dataSource,
      });
      promises.push(promise);
    });

    Promise.allSettled(promises)
      .then((results) => {
        const successfulData = results
          .filter(
            (result): result is PromiseFulfilledResult<ISmartChartConfig> =>
              result.status === 'fulfilled'
          )
          .map((result) => result.value);

        setCharts(successfulData);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        fetchingRef.current = false;
        setChartsLoaded(true);
      });
  }, [
    dataSource,
    pinnedTabId,
    smartChartsRepository,
    selectedTab,
    setCharts,
    setChartsLoaded,
  ]);
};
