import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../ChartWrapper';
import React, { useCallback, useMemo } from 'react';
import {
  Chart,
  ChartColor,
  ChartTooltip,
  IChartSource,
  IEChartOption,
} from '../../Chart';
import ReactDOMServer from 'react-dom/server';
import { defaultChartText } from '../../Chart/constants/defaults';
import { getGradient } from '../../Chart/utils/getGradient';
import { handleChartMouseOver } from '../../Chart/utils/chartTracking';
import { ChartAxisName, ChartTrackingNames } from '../../../constants/charts';
import { TsbUtils } from '../../../utils/tsb';
import { TsbStatus } from '../../../constants/performance';

type IProps = {
  source: IChartSource;
  loading?: boolean;
};

const offsetSize = 5;
const markAreaXOffset = 150;

export const TsbChart = ({ source, loading }: IProps) => {
  const { t } = useTranslation();

  const sourceWithOffset = useMemo(() => {
    if (loading) {
      return source;
    }

    const sourceWithOffset: IChartSource = [...source];

    for (let i = 1; i <= offsetSize; i++) {
      sourceWithOffset.splice(1, 0, [`_offset${i}`, null]);
    }

    return sourceWithOffset;
  }, [source, loading]);

  const { yMinValue, yMaxValue } = useMemo(() => {
    const values = {
      yMinValue: -50,
      yMaxValue: 40,
    };

    for (let i = 1; i < source.length; i++) {
      values.yMinValue = Math.min(values.yMinValue, source[i][1] as number);
      values.yMaxValue = Math.max(values.yMaxValue, source[i][1] as number);
    }

    values.yMaxValue = Math.ceil(values.yMaxValue / 10) * 10;
    values.yMinValue = Math.floor(values.yMinValue / 10) * 10;

    return values;
  }, [source]);

  const onRenderTooltipValue = useCallback((value: number, _: string) => {
    return Math.round(value);
  }, []);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source: sourceWithOffset,
      },
      grid: {
        bottom: 110,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 45,
          interval: 0,
          fontSize: 10,
          formatter: (value) => (value.startsWith('_offset') ? '' : value),
        },
        axisLine: {
          show: false,
        },
        interval: 0,
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          min: yMinValue,
          max: yMaxValue,
          axisLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: ChartAxisName.TSB,
          type: 'scatter',
          yAxisIndex: 0,
          z: 2,
          itemStyle: {
            color: (params) => {
              return TsbUtils.getContent(TsbUtils.getStatus(params.value[1]))
                .color;
            },
          },
          markArea: {
            data: [
              [
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.Detraining],
                  itemStyle: getGradient({
                    from: ChartColor.Cyan50,
                    direction: 'to-right',
                  }),
                },
                {
                  x: markAreaXOffset,
                },
              ],
              [
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.CompetitionReady],
                  itemStyle: getGradient({
                    from: ChartColor.LightGreen50,
                    direction: 'to-right',
                  }),
                },
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.Detraining],
                  x: markAreaXOffset,
                },
              ],
              [
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.Transitional],
                  itemStyle: getGradient({
                    from: ChartColor.LightYellow50,
                    direction: 'to-right',
                  }),
                },
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.CompetitionReady],
                  x: markAreaXOffset,
                },
              ],
              [
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.ProductiveTraining],
                  itemStyle: getGradient({
                    from: ChartColor.LightRose50,
                    direction: 'to-right',
                  }),
                },
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.Transitional],
                  x: markAreaXOffset,
                },
              ],
              [
                {
                  itemStyle: getGradient({
                    from: ChartColor.Red50,
                    direction: 'to-right',
                  }),
                },
                {
                  yAxis: TsbUtils.breakpoints[TsbStatus.ProductiveTraining],
                  x: markAreaXOffset,
                },
              ],
            ],
          },
          markLine: {
            data: [
              {
                label: {
                  ...defaultChartText,
                  fontSize: 10,
                  formatter: t('label.detraining'),
                  position: 'insideStartTop',
                  color: ChartColor.AxisLabelText,
                  distance: 6,
                },
                yAxis: TsbUtils.breakpoints[TsbStatus.Detraining],
                lineStyle: {
                  color: ChartColor.LightGreen50,
                },
              },
              {
                label: {
                  ...defaultChartText,
                  fontSize: 10,
                  formatter: t('label.competition_ready', { break: '\n' }),
                  position: 'insideStartTop',
                  color: ChartColor.AxisLabelText,
                  distance: 6,
                },
                yAxis: TsbUtils.breakpoints[TsbStatus.CompetitionReady],
                lineStyle: {
                  color: ChartColor.Yellow50,
                },
              },
              {
                label: {
                  ...defaultChartText,
                  fontSize: 10,
                  formatter: t('label.transitional'),
                  position: 'insideStartTop',
                  color: ChartColor.AxisLabelText,
                  distance: 6,
                },
                yAxis: TsbUtils.breakpoints[TsbStatus.Transitional],
                lineStyle: {
                  color: ChartColor.LightRose50,
                },
              },
              {
                label: {
                  ...defaultChartText,
                  fontSize: 10,
                  formatter: t('label.productive_training', { break: '\n' }),
                  position: 'insideStartTop',
                  color: ChartColor.AxisLabelText,
                  distance: 6,
                },
                yAxis: TsbUtils.breakpoints[TsbStatus.ProductiveTraining],
                lineStyle: {
                  color: ChartColor.Red50,
                },
              },
              {
                label: {
                  ...defaultChartText,
                  fontSize: 10,
                  formatter: t('label.overreaching'),
                  position: 'insideStartTop',
                  color: ChartColor.AxisLabelText,
                  distance: 6,
                },
                yAxis: yMinValue,
                lineStyle: {
                  color: 'transparent',
                },
              },
            ],
          },
        },
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          if (params[0].value[1] === null) {
            return '';
          }
          return ReactDOMServer.renderToString(
            <ChartTooltip
              params={params}
              onRenderValue={onRenderTooltipValue}
            />
          );
        },
      },
    };
  }, [onRenderTooltipValue, sourceWithOffset, t, yMaxValue, yMinValue]);

  return (
    <ChartWrapper minHeight="560px">
      <Chart
        headerProps={{
          title: t('label.tsb'),
        }}
        legendProps={{
          show: false,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.TSB,
          }),
        }}
      />
    </ChartWrapper>
  );
};
