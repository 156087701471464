import './index.css';
import { useCallback, useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import {
  IconButton,
  IInputProps,
  Input,
  ISelectBaseOption,
  ISelectOptionDefault,
  Select,
  Typography,
} from '@cycling-web/analog-ui';
import countries from 'i18n-iso-countries';
import enCountryLocales from 'i18n-iso-countries/langs/en.json';
import metadata from 'libphonenumber-js/metadata.min.json';
import { clsx } from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from '../../constants';

countries.registerLocale(enCountryLocales);

type ISelectOption = ISelectBaseOption & ISelectOptionDefault;

type IProps = IInputProps & {
  name: string;
};

export const PhoneControl = (props: IProps) => {
  const { disabled } = props;
  const { setValue } = useFormContext();
  const phoneValue = useWatch({ name: props.name });
  const [alpha2, code, phone] = phoneValue.split('-');
  const rootClass = clsx('analog-phone-input', props.className);

  const countryCodeOptions = useMemo((): ISelectOption[] => {
    const countryList: ISelectOption[] = Object.keys(metadata.countries).map(
      (isoCode) => {
        const dialCode = `+${metadata.countries[isoCode][0]}`;
        const countryName = countries.getName(isoCode, 'en') || isoCode;
        const flagUrl = `${DEFAULT_CDN_URL}${isoCode.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

        return {
          id: isoCode,
          text: countryName,
          description: { text: dialCode },
          icon: (
            <img
              style={{ width: '18px', height: '18px', borderRadius: '4px' }}
              src={flagUrl}
              alt={countryName}
            />
          ),
        };
      }
    );

    return countryList.sort((a: ISelectOption, b: ISelectOption) => {
      return (a.description?.text as string).localeCompare(
        b.description?.text as string
      );
    });
  }, []);

  const [countryCodeValue, setCountryCodeValue] = useState<
    ISelectOption | undefined
  >(() => {
    return countryCodeOptions.find(
      (o: ISelectOption) =>
        o.id.toLowerCase() === (alpha2 || 'AE').toLowerCase()
    );
  });

  const onCountryCodeChange = useCallback(
    (option: ISelectOption) => {
      const nextValue = `${option?.id}-${option?.description?.text}-${
        phone || ''
      }`;
      setValue(props.name, nextValue);
      setCountryCodeValue(option);
    },
    [phone, props.name, setValue]
  );

  const onRenderAnchor = useCallback(() => {
    return (
      <IconButton
        variant="quietLayer2"
        disabled={disabled}
        size="s"
        content={<ChevronDown />}
      />
    );
  }, [disabled]);

  const handleInputChange = useCallback(
    (value: string) => {
      const nextValue = `${countryCodeValue?.id}-${countryCodeValue?.description?.text}-${value}`;
      setValue(props.name, nextValue);
    },
    [countryCodeValue, props.name, setValue]
  );

  return (
    <Input
      className={rootClass}
      value={phone || ''}
      onChange={handleInputChange}
      regex={/^[0-9]*$/}
      startAdornment={
        <>
          <Select
            options={countryCodeOptions}
            value={countryCodeValue}
            // @ts-ignore
            onChange={onCountryCodeChange}
            onRenderAnchor={onRenderAnchor}
            dropdownProps={{
              minWidth: '352px',
              maxHeight: '260px',
              contentClassName: 'analog-phone-control__dropdown',
              placement: 'top-start',
            }}
            search
          />
          {countryCodeValue && (
            <>
              <img
                className="analog-phone-control__adornment"
                style={{ width: '18px', height: '18px', borderRadius: '4px' }}
                src={`${DEFAULT_CDN_URL}${countryCodeValue?.id.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`}
                alt={countryCodeValue?.text || ''}
              />
              <Typography
                className="analog-phone-control__adornment"
                text={countryCodeValue?.description?.text}
              />
            </>
          )}
        </>
      }
    />
  );
};
