import './index.css';
import { Button, Dialog, Divider, Typography } from '@cycling-web/analog-ui';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { ROUTES } from '../../../router/routes';
import { Link } from 'react-router';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

type IProps = {
  onDismiss: () => void;
  onDecline?: () => void;
  onSubmit: () => Promise<void>;
  showDismissButton?: boolean;
};

export const ConsentDialog = ({
  onDismiss,
  onDecline = onDismiss,
  onSubmit,
  showDismissButton = true,
}: IProps) => {
  const { t } = useTranslation();
  const baseUrl = useBaseUrl();
  const dataSharingPageUrl = `${baseUrl}/${ROUTES.ATHLETE}/${ROUTES.SETTINGS}/${ROUTES.DATA_SHARING}`;

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    onSubmit()
      .then(() => {
        onDismiss();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onDismiss, onSubmit]);

  const onRenderBody = useCallback(() => {
    return (
      <Typography
        variant="subtitle"
        color="text-secondary"
        text={t('label.data_sharing_explain')}
      />
    );
  }, [t]);

  const onRenderFooter = useCallback(() => {
    return (
      <>
        <div className="consent-dialog__actions">
          <Button
            variant="quietLayer3"
            onClick={onDecline}
            content={t('action.decline')}
          />
          <Button
            onClick={handleSubmit}
            content={t('action.agree')}
            loading={loading}
          />
        </div>
        <div className="consent-dialog__footer">
          <Divider />
          <Typography
            as="p"
            variant="subtitle"
            text={
              <Trans i18nKey="legal.data_sharing_p1">
                You can withdraw your consent at any time by returning to this{' '}
                <Link to={dataSharingPageUrl} target="_blank" rel="noreferrer">
                  Data Sharing
                </Link>{' '}
                page and de-selecting this provider, at which point we will stop
                pulling new data from this provider into your Account.
              </Trans>
            }
          />
          <Typography
            as="p"
            variant="subtitle"
            text={
              <Trans i18nKey="legal.data_sharing_p2">
                For more information on how we process your Personal Data, you
                can refer to our{' '}
                <a
                  href={`${window.location.origin}/${ROUTES.LEGAL}/${ROUTES.PRIVACY_NOTICE}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Notice
                </a>
                .
              </Trans>
            }
          />
        </div>
      </>
    );
  }, [dataSharingPageUrl, handleSubmit, loading, onDecline, t]);

  return (
    <Dialog
      showDismissButton={showDismissButton}
      title={t('label.data_sharing')}
      subtitle={t('label.data_sharing_consent')}
      onDismiss={onDismiss}
      outsidePress={false}
      preventDefaultOnClick={false}
      className="consent-dialog"
      onRenderBody={onRenderBody}
      onRenderFooter={onRenderFooter}
    />
  );
};
