import { SelectSingleControl } from '@cycling-web/common';
import { FormElement, ISelectOption } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

export const GenderControl = () => {
  const { t } = useTranslation();
  const { formState } = useFormContext<{
    gender?: string | null;
  }>();

  const genderOptions: ISelectOption[] = useMemo(() => {
    return [
      {
        id: 'men',
        text: t('label.male'),
      },
      {
        id: 'women',
        text: t('label.female'),
      },
      {
        id: 'prefer_not_to_say',
        text: t('label.prefer_not_to_say'),
      },
    ];
  }, [t]);

  return (
    <FormElement
      label={t('label.gender')}
      message={formState.errors.gender?.message}
    >
      <SelectSingleControl
        options={genderOptions}
        name="gender"
        placeholder={t('placeholder.gender')}
        invalid={!!formState.errors.gender}
      />
    </FormElement>
  );
};
