import {
  format,
  isToday,
  isWithinInterval,
  isYesterday,
  parseISO,
  startOfDay,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';

export function hoursToHHMMSS(value: number): string {
  const hours = Math.floor(value);
  const minutes = Math.floor((value - hours) * 60);
  const seconds = Math.floor(((value - hours) * 60 - minutes) * 60);

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':');
}

export function formatTime(numberOfSeconds: number): string {
  const hours = Math.floor(numberOfSeconds / 3600);
  const minutes = Math.floor((numberOfSeconds % 3600) / 60);
  const seconds = numberOfSeconds % 60;

  const hoursStr = hours > 0 ? `${hours}h` : '';
  const minutesStr = minutes > 0 ? `${minutes}m` : '';
  const secondsStr = seconds > 0 ? `${seconds}s` : '';

  return [hoursStr, minutesStr, secondsStr].filter(Boolean).join(' ');
}

export type ITimeZone = {
  offset: string;
  zone: string;
  displayName: string;
};

export function getTimeZones(): ITimeZone[] {
  // @ts-ignore
  const timeZones = Intl.supportedValuesOf('timeZone');
  const now = new Date();

  const timeZoneData = timeZones.map((timeZone) => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      timeZoneName: 'short',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(now);
    const gmtPart = parts.find((part) => part.type === 'timeZoneName');
    const gmtMatch = gmtPart?.value.match(/GMT([+-]\d{1,2}(:\d{2})?)/);

    const gmtOffset = gmtMatch ? gmtMatch[1] : '+0';

    return {
      offset: gmtOffset,
      zone: timeZone,
      displayName: `GMT${gmtOffset} - ${timeZone}`,
    };
  });

  timeZoneData.sort(
    (a, b) =>
      parseFloat(a.offset.replace(':', '.')) -
      parseFloat(b.offset.replace(':', '.'))
  );

  return timeZoneData;
}

export function localWithUTCMidnightString(d: Date | undefined): string {
  const date = d || new Date();

  const dd = date.getDate().toString().padStart(2, '0');
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}T00:00:00.000Z`;
}

export function localWithUTCMidnightTimestamp(d: Date | undefined): number {
  const utcMidnightString = localWithUTCMidnightString(d);
  const utcMidnightDate = new Date(utcMidnightString);
  return utcMidnightDate.getTime();
}

export function UTCMidnightToSameDate(timestamp: number): Date {
  const timezoneOffset = new Date().getTimezoneOffset();
  const adjustedTimestamp = timestamp + timezoneOffset * 60 * 1000;
  return new Date(adjustedTimestamp);
}

export function getUTCMidnight(date?: Date) {
  const now = date || new Date();

  const utcYear = now.getUTCFullYear();
  const utcMonth = now.getUTCMonth();
  const utcDate = now.getUTCDate();

  return new Date(Date.UTC(utcYear, utcMonth, utcDate));
}

export function getMonthFromWeek(weekNumber: number, year: number): string {
  const firstDayOfYear = new Date(year, 0, 1);
  const firstWeekStart = startOfWeek(firstDayOfYear, { weekStartsOn: 0 });
  const weekStartDate = new Date(firstWeekStart);
  weekStartDate.setDate(weekStartDate.getDate() + (weekNumber - 1) * 7);

  return format(weekStartDate, 'MMM');
}

export function groupItemsByDate<T>(
  items: T[],
  datePath: string | string[]
): Record<string, T[]> {
  const today = startOfDay(new Date());
  const yesterday = startOfDay(subDays(today, 1));
  const sevenDaysAgo = startOfDay(subDays(today, 7));
  const oneMonthAgo = startOfDay(subMonths(today, 1));

  const grouped: Record<string, T[]> = {
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
  };

  // Utility function to access nested properties dynamically
  const getValueByPath = (obj: T, path: string | string[]): string | null => {
    const keys = Array.isArray(path) ? path : path.split('.');
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
      obj
    ) as string | null;
  };

  items.forEach((item) => {
    const dateValue = getValueByPath(item, datePath);

    if (!dateValue) return;

    let date: Date;
    try {
      date = parseISO(dateValue);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
    } catch {
      return;
    }

    if (isToday(date)) {
      grouped.today.push(item);
    } else if (isYesterday(date)) {
      grouped.yesterday.push(item);
    } else if (
      isWithinInterval(date, { start: sevenDaysAgo, end: yesterday })
    ) {
      grouped.previous7Days.push(item);
    } else if (
      isWithinInterval(date, { start: oneMonthAgo, end: sevenDaysAgo })
    ) {
      grouped.previous30Days.push(item);
    } else {
      // Dynamically group by month name for older items
      const monthName = format(date, 'MMMM yyyy');
      if (!grouped[monthName]) {
        grouped[monthName] = [];
      }
      grouped[monthName].push(item);
    }
  });

  return grouped;
}
