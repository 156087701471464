import { useCallback, useEffect } from 'react';
import { useChartContext } from '../../context';
import { IChartFixedCursor, IEChartSeriesOption } from '../../types';
import { ChartColor } from '../../constants/colors';
import sortedIndexBy from 'lodash/sortedIndexBy';

export const useFixedCursor = () => {
  const {
    option,
    onPlotClick,
    chartRef,
    fixedCursors,
    setFixedCursors,
    fixedCursorProps,
  } = useChartContext();

  // Optimize findNearestPoint using binary search for sorted data
  const findNearestPoint = useCallback(
    (source: any[], targetX: number): any[] | null => {
      if (!source?.length) {
        return null;
      }

      const data = source.slice(1); // Exclude the header row
      const firstValue = source[1]?.[0]; // Check the type of the first data point

      if (typeof firstValue === 'number') {
        // Numeric x-axis: Use binary search for nearest value
        const index = sortedIndexBy(data, [targetX], (point) => point[0]);

        if (index === 0) {
          return data[0];
        }
        if (index === data.length) {
          return data[data.length - 1];
        }

        const before = data[index - 1];
        const after = data[index];

        return Math.abs(before[0] - targetX) < Math.abs(after[0] - targetX)
          ? before
          : after;
      } else if (typeof firstValue === 'string') {
        // String x-axis: Find the closest index to targetX
        const closestIndex = Math.round(targetX);
        if (closestIndex < 0 || closestIndex >= data.length) {
          return null;
        }
        return data[closestIndex];
      }

      return null;
    },
    []
  );

  // Extract click handler logic
  const handleChartClick = useCallback(
    (event: echarts.ECElementEvent, chartInstance: echarts.ECharts) => {
      try {
        const pointInGrid = chartInstance.convertFromPixel('grid', [
          event.offsetX,
          event.offsetY,
        ]);

        const x = pointInGrid[0];
        const y = pointInGrid[1];

        if (x === undefined || y === undefined) {
          return;
        }

        const series = chartInstance.getOption()
          .series as IEChartSeriesOption[];
        const dataset = chartInstance.getOption().dataset || [];
        const source = dataset[0]?.source || [];

        const nearest = findNearestPoint(source, x);
        if (!nearest) return;

        const cursor: IChartFixedCursor = {
          id: `cursor_${nearest[0]}`,
          event,
          pixels: chartInstance.convertToPixel('grid', [x, y]),
          coords: [x, y],
          data: nearest,
          dimensionNames: source[0],
          series,
        };

        if (fixedCursorProps?.show) {
          setFixedCursors((prevCursors: IChartFixedCursor[]) => {
            const max = fixedCursorProps.count || 1;
            const id = cursor.id;

            if (prevCursors.length === max) return [];

            const existingIndex = prevCursors.findIndex((c) => c.id === id);
            if (existingIndex >= 0) {
              return prevCursors.filter((c) => c.id === id);
            }

            return [...prevCursors, cursor];
          });
        }

        onPlotClick?.(cursor);
      } catch (error) {
        console.error('Error handling chart click:', error);
      }
    },
    [findNearestPoint, fixedCursorProps, onPlotClick, setFixedCursors]
  );

  // Click event handler
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart || (!onPlotClick && !fixedCursorProps?.show)) {
      return;
    }

    const clickHandler = (event: echarts.ECElementEvent) => {
      handleChartClick(event, chart);
    };

    chart.getZr().on('click', clickHandler);
    return () => {
      chart.getZr()?.off('click', clickHandler);
    };
  }, [chartRef, handleChartClick, fixedCursorProps, onPlotClick]);

  // Update cursors on data change
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const currentDataset = chart.getOption().dataset || [];

    setFixedCursors((prevCursors: IChartFixedCursor[]) => {
      const source = currentDataset[0]?.source || [];

      return prevCursors.map((cursor) => {
        const nearest = findNearestPoint(source, cursor.coords[0]);
        if (!nearest) return cursor;

        return {
          ...cursor,
          data: nearest,
          value: nearest,
          dimensionNames: source[0],
        };
      });
    });
  }, [chartRef, findNearestPoint, option, setFixedCursors]);

  // Apply visual cursors
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const series = chart.getOption().series as IEChartSeriesOption[];
    if (!series?.[0]) return;

    const firstSeries = series[0];
    const existingMarkLines = firstSeries.markLine?.data || [];

    const newMarkLines = fixedCursors.map((cursor) => ({
      id: cursor.id,
      xAxis: cursor.data[0],
      label: { show: false },
      lineStyle: { color: ChartColor.AxisLine },
    }));

    const nextMarkLineData = [
      ...existingMarkLines.filter((ml) => {
        const newMarkLineIds = newMarkLines.map((nml) => nml.id);
        return (
          !ml.id || (ml.id.includes('cursor') && newMarkLineIds.includes(ml.id))
        );
      }),
      ...newMarkLines,
    ];

    try {
      chart.setOption({
        series: [
          {
            ...firstSeries,
            markLine: {
              ...firstSeries.markLine,
              data: nextMarkLineData,
            },
          },
        ],
      });
    } catch (error) {
      console.error('Error updating chart markLines:', error);
    }
  }, [chartRef, fixedCursors, option]);
};
