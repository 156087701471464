import './index.css';
import MenuCollapse from '../../assets/menu-collapse';
import { IconButton } from '@cycling-web/analog-ui';
import { useSmartChartsContext } from '../../context';
import { useSmartChartsStore } from '../../store/slice';
import {
  ISmartChartConfigBase,
  ISmartChartHistoryGroup,
} from '../../../../types/smart-charts';
import { HistoryItem } from '../HistoryItem';
import { useMemo, useRef, useState } from 'react';
import { useOutsideClick } from 'rooks';
import { groupItemsByDate } from '../../../../utils/date-time';
import { useTranslation } from 'react-i18next';

export const History = () => {
  const { t } = useTranslation();
  const { dismissHistory } = useSmartChartsContext();
  const history = useSmartChartsStore((s) => s.history);
  const [focusedItem, setFocusedItem] = useState<string>('');
  const rootRef = useRef<HTMLDivElement>(null);

  const groupedHistory: ISmartChartHistoryGroup[] = useMemo(() => {
    const groupedHistory: ISmartChartHistoryGroup[] = [];
    const groups = groupItemsByDate<ISmartChartConfigBase>(
      history,
      'updated_at'
    );

    const nameMap: Record<string, string> = {
      today: t('label.today'),
      yesterday: t('label.yesterday'),
      previous7Days: t('label.previous_n_days', { n: 7 }),
      previous30Days: t('label.previous_n_days', { n: 30 }),
    };

    Object.keys(groups).forEach((name) => {
      if (groups[name].length > 0) {
        groupedHistory.push({
          name: nameMap[name] || name,
          items: groups[name],
        });
      }
    });
    return groupedHistory;
  }, [history, t]);

  useOutsideClick(rootRef, () => {
    setFocusedItem('');
  });

  const historyJSX = groupedHistory.map((item: ISmartChartHistoryGroup) => {
    const itemsJSX = item.items.map((conversation: ISmartChartConfigBase) => {
      return (
        <HistoryItem
          key={conversation.id}
          item={conversation}
          focusedItem={focusedItem}
          setFocusedItem={setFocusedItem}
        />
      );
    });

    return (
      <div key={item.name} className="sc2__history-group">
        <div className="sc2__history-group-name analog-typography--subtitle">
          {item.name}
        </div>
        <div className="sc2__history-group-list">{itemsJSX}</div>
      </div>
    );
  });

  return (
    <div className="sc2__history">
      <div className="sc2__history-header">
        <IconButton
          variant="quietLayer2"
          content={<MenuCollapse />}
          onClick={dismissHistory}
        />
      </div>
      <div className="sc2__history-list" ref={rootRef}>
        {historyJSX}
      </div>
    </div>
  );
};
