import {
  ISmartChartAxis,
  ISmartChartConfig,
} from '../../../types/smart-charts';
import { createAxisLabelFormatter } from './curve-formatter';
import { formatTime } from '../../../utils/utils';
import { IChartSource, IEChartXAXisOption } from '../../../components/Chart';
import { IAthlete } from '../../../types/athletes';

type IProps = {
  config: ISmartChartConfig;
  source: IChartSource;
  isLogScale: boolean;
  athletes: IAthlete[];
};

const customDateFormatter = (value: number | string): string => {
  const date = new Date(value.toString());
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const computeXAxis = (props: IProps): IEChartXAXisOption[] => {
  const { config, isLogScale, source } = props;
  const graph = config?.ai_graph_in_scope?.ai_generated_graphs[0];

  if (!graph) {
    return [];
  }

  const dateUnits = ['date', 'day'];

  return graph.x_axes.map((axis: ISmartChartAxis) => {
    const isDateUnit = dateUnits.includes(axis.unit);

    return {
      type: axis.range_log_scaled ? 'value' : 'category',
      splitNumber: isLogScale ? source.length : 1,
      axisLabel: {
        formatter: isDateUnit
          ? customDateFormatter
          : axis.range_log_scaled
          ? createAxisLabelFormatter()
          : `{value}`,
        rotate: 0,
        hideOverlap: true,
      },
      axisPointer: {
        label: {
          formatter: isDateUnit
            ? // @ts-ignore
              (value) => customDateFormatter(value?.value)
            : isLogScale
            ? //   @ts-ignore
              (value) => formatTime(value?.value)
            : `{value}`,
        },
      },
    };
  });
};
