import './index.css';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FeedbackFormSlider } from './Slider';
import { X } from 'lucide-react';
import { useAthleteStore } from '../../store/athlete/slice';
import { getUTCMidnight } from '../../utils/date-time';
import {
  fatigueWellnessValues,
  moodWellnessValues,
  overallWellnessValues,
  sleepWellnessValues,
  stressWellnessValues,
} from '../../pages/AthleteDetailsMobile/constants';
import {
  Button,
  FormElement,
  IconButton,
  Textarea,
  Toast,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { ISubjectiveFeedbackFormData } from '../../types/wellness';
import { WellnessRepository } from '../../api/wellness/repository';
import { WellnessService } from '../../api/wellness/service';
import { AthleteDetailsMobileContext } from '../../pages/AthleteDetailsMobile/context';

type IProps = {
  onDismiss: () => void;
};

export type ISliderKeys = keyof Omit<
  ISubjectiveFeedbackFormData,
  'athleteId' | 'timeSinceEpoch' | 'inputBy'
>;

export const SubjectiveFeedbackForm = ({ onDismiss }: IProps) => {
  const { t } = useTranslation();
  const { fetchLastUpdatedWellness } = useContext(AthleteDetailsMobileContext);
  const athlete = useAthleteStore((s) => s.athlete);
  const [loading, setLoading] = useState<boolean>(false);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService());
  }, []);

  const [formData, setFormData] = useState<ISubjectiveFeedbackFormData>({
    athleteId: athlete?.id,
    timeSinceEpoch: (() => {
      return getUTCMidnight().getTime();
    })(),
    overall: overallWellnessValues[0],
    fatigue: fatigueWellnessValues[0],
    stress: stressWellnessValues[0],
    mood: moodWellnessValues[0],
    sleep: sleepWellnessValues[0],
    inputBy: 0,
    comment: '',
  });

  const onChange = useCallback((name: ISliderKeys, value: string) => {
    setFormData((prev: ISubjectiveFeedbackFormData) => {
      const next = { ...prev };
      next[name] = value;
      return next;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    wellnessRepository
      .reportSelfWellness(formData)
      .then(() => {
        Toast.success({
          title: t('success.send_subjective_feedback_title'),
        });
        fetchLastUpdatedWellness();
        onDismiss();
      })
      .catch(() => {
        Toast.error(
          {
            title: t('error.submit_subjective_feedback_title'),
            message: t('error.submit_subjective_feedback_message'),
          },
          { toastId: 'submit_subjective_feedback' }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    loading,
    wellnessRepository,
    formData,
    fetchLastUpdatedWellness,
    t,
    onDismiss,
  ]);

  return (
    <div className="subjective-feedback-form">
      <header className="subjective-feedback-form__header">
        <Typography
          as="div"
          variant="headline"
          weight="bold"
          text={t('label.subjective_feedback')}
        />
        <IconButton
          variant="quietLayer2"
          size="m"
          content={<X />}
          onClick={onDismiss}
        />
      </header>
      <FeedbackFormSlider
        question={t('interaction.wellness_general_question')}
        options={overallWellnessValues}
        name="overall"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question={t('interaction.wellness_fatigue_question')}
        options={fatigueWellnessValues}
        name="fatigue"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question={t('interaction.wellness_stress_question')}
        options={stressWellnessValues}
        name="stress"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question={t('interaction.wellness_mood_question')}
        options={moodWellnessValues}
        name="mood"
        onChange={onChange}
      />

      <FeedbackFormSlider
        question={t('interaction.wellness_sleep_question')}
        options={sleepWellnessValues}
        name="sleep"
        onChange={onChange}
      />

      <div className="subjective-feedback-form__comment">
        <FormElement label={t('label.subjective_feedback_comment')}>
          <Textarea
            placeholder={t('placeholder.comment')}
            value={formData.comment}
            onChange={(value) => onChange('comment', value)}
          />
        </FormElement>
      </div>

      <footer className="subjective-feedback-form__footer">
        <Button
          content={t('action.submit')}
          fullWidth
          size="l"
          onClick={handleSubmit}
          loading={loading}
        />
      </footer>
    </div>
  );
};
