import { Button, FormElement, Message } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputPasswordControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import { use, useCallback, useMemo, useState } from 'react';
import { AuthRepository } from '../../api/auth/repository';
import { AuthPageContext } from '../../context/AuthPageContext';
import { IOAuthTokenResponse } from '../../api/auth/types';
import { FormView } from '../FormView';
import { ROUTES } from '../../router/routes';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LS } from '../../constants';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { PasswordRequirementMessage } from '../PasswordRequirementMessage';

type IForm = {
  password: string;
  repeat_password: string;
};

export const AuthPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { continuationTokenRef, usernameRef } = use(AuthPageContext);

  const { onSignUp, authOptions } = useAnalogAuthContext();

  const authRepository = useMemo(() => {
    return AuthRepository.getInstance(authOptions);
  }, [authOptions]);

  const schema = useMemo(() => {
    return z
      .object({
        password: z
          .string()
          .min(1, { message: t('validation.required') })
          .min(8, { message: t('validation.password_too_short') }),
        repeat_password: z
          .string()
          .min(1, { message: t('validation.required') })
          .min(8, { message: t('validation.password_too_short') }),
      })
      .refine((data) => data.password === data.repeat_password, {
        message: t('validation.passwords_must_match'),
        path: ['repeat_password'],
      });
  }, [t]);

  const form = useForm<IForm>({
    defaultValues: {
      password: '',
      repeat_password: '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      setLoading(true);
      return authRepository
        .signUpContinue({
          continuation_token: continuationTokenRef.current,
          grant_type: 'password',
          password: formData.password,
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          return authRepository.getOAuthToken({
            continuation_token: continuationTokenRef.current,
            username: usernameRef.current,
            grant_type: 'continuation_token',
          });
        })
        .then((response: IOAuthTokenResponse) => {
          localStorage.setItem(LS.AccessToken, response.id_token);
          localStorage.setItem(LS.RefreshToken, response.refresh_token);
          if (onSignUp) {
            onSignUp(response);
          }
        })
        .catch((e: { suberror: string }) => {
          const suberror = e?.suberror || 'invalid_email_or_password';
          setMessage(t(`validation.${suberror}`));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [authRepository, continuationTokenRef, usernameRef, onSignUp, t]
  );

  const handleBackClick = useCallback(() => {
    navigate(`/${ROUTES.AUTH}/${ROUTES.SIGN_UP}`, { replace: true });
  }, [navigate]);

  return (
    <FormProvider {...form}>
      <FormView title="Create password">
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.password')}
            message={formState.errors.password?.message}
          >
            <InputPasswordControl
              name="password"
              size="l"
              placeholder={t('placeholder.password')}
              autoFocus={true}
              invalid={!!formState.errors.password}
            />
          </FormElement>

          <FormElement
            label={t('label.repeat_password')}
            message={formState.errors.repeat_password?.message}
          >
            <InputPasswordControl
              name="repeat_password"
              size="l"
              placeholder={t('placeholder.password')}
              autoFocus={true}
              invalid={!!formState.errors.repeat_password}
            />
          </FormElement>

          {message && (
            <div className="analog-auth-common-form__message">
              <Message text={message} variant="error" />
            </div>
          )}

          <PasswordRequirementMessage />

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              size="l"
              content={t('action.continue')}
              fullWidth
              loading={loading}
            />

            <Button
              type="button"
              size="l"
              variant="quietLayer3"
              content={t('action.back')}
              fullWidth
              onClick={handleBackClick}
            />
          </div>
        </form>
      </FormView>
    </FormProvider>
  );
};
