import './index.css';
import { RefObject, useCallback, useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { ChartLegendItem } from '../ChartLegendItem';
import {
  IChartLegendItem,
  IChartLegendProps,
  IEChartLegendOption,
  IEChartSeriesOption,
} from '../../types';
import { clsx } from 'clsx';
import { Skeleton } from '@cycling-web/analog-ui';
import { useChartContext } from '../../context';

type IProps = {
  chartRef: RefObject<echarts.EChartsType | null>;
} & IChartLegendProps;

export const ChartLegend = ({
  chartRef,
  onRenderItem,
  loading,
  deps = [],
}: IProps) => {
  const { option } = useChartContext();
  const [legendItems, setLegendItems] = useState<Array<any>>([]);
  const [selectedState, setSelectedState] = useState<{
    [key: string]: boolean;
  }>({});

  const onSelectionChange = useCallback((item: any) => {
    setSelectedState((selectedState) => {
      return {
        ...selectedState,
        ...item,
      };
    });
  }, []);

  useEffect(() => {
    if (loading || !option) {
      return;
    }

    const selected: Record<string, boolean> = {};
    const optionSeries = (option.series || []) as IEChartSeriesOption[];

    const items = optionSeries.map(
      (series: IEChartSeriesOption, index: number) => {
        // @ts-ignore
        const color = series.itemStyle?.color || option.color[index];
        // @ts-ignore
        const type = series.lineStyle?.type || 'solid';
        selected[series.name as string] = true;

        return {
          name: series.name,
          color,
          type,
        };
      }
    );

    setLegendItems(items);

    const legendSelected = option?.legend?.[0]
      ? (option.legend as IEChartLegendOption)[0].selected
      : {};

    setSelectedState({
      ...selected,
      ...legendSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, loading, ...deps]);

  const onClick = useCallback(
    (item: IChartLegendItem) => {
      if (chartRef.current) {
        chartRef.current.dispatchAction({
          type: 'legendToggleSelect',
          name: item.name,
        });

        const newOption = chartRef.current.getOption();

        const newSelected = (newOption.legend as IEChartLegendOption)[0]
          .selected;
        onSelectionChange(newSelected);
      }
    },
    [chartRef, onSelectionChange]
  );

  const legendItemsJSX = legendItems.map((item, i) => {
    const selected = selectedState[item.name];

    const rootClass = clsx(
      'chart-legend-item',
      'analog-typography--subtitle',
      'light',
      !selected && 'chart-legend-item--off'
    );

    return (
      <button key={i} onClick={() => onClick(item)} className={rootClass}>
        <ChartLegendItem
          item={{ ...item, selected }}
          onRenderItem={onRenderItem}
        />
      </button>
    );
  });

  return (
    <div className="chart-legend">
      {loading ? <Skeleton /> : legendItemsJSX}
    </div>
  );
};
