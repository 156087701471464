import './index.css';
import { ReactNode } from 'react';

type IProps = {
  title?: string;
  subtitle?: string;
  children: ReactNode;
};

export const FormView = ({ children, title, subtitle }: IProps) => {
  return (
    <div className="analog-auth-form-view">
      {(title || subtitle) && (
        <header className="analog-auth-form-view__header">
          {title && (
            <h3 className="analog-auth-form-view__title analog-typography--h3 bold">
              {title}
            </h3>
          )}
          {subtitle && (
            <p className="analog-auth-form-view__subtitle analog-typography--body regular">
              {subtitle}
            </p>
          )}
        </header>
      )}
      {children}
    </div>
  );
};
