import { Dialog, EmptyState } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import InfoCircleFilled from '../../assets/icons/info-circle-filled.svg?react';

export const ConsentSuccess = () => {
  const { t } = useTranslation();

  const onRenderFooter = useCallback(() => {
    return null;
  }, []);

  const text = `${t('banner.successfully_connected_text')} ${t(
    'label.you_can_close_page'
  )}`;

  return (
    <Dialog
      title={t('label.data_sharing')}
      onDismiss={() => {}}
      showDismissButton={false}
      onRenderFooter={onRenderFooter}
      className="analog-consent-dialog"
    >
      <EmptyState
        icon={
          <InfoCircleFilled className="analog-consent-dialog__success-icon" />
        }
        title={t('banner.successfully_connected_title', {
          item: 'Your account',
        })}
        text={text}
      />
    </Dialog>
  );
};
