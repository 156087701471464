import './index.css';
import { IChartContext, IChartFixedCursor, IChartProps } from '../../types';
import { ChartCore } from '../ChartCore';
import { ChartHeader } from '../ChartHeader';
import { ChartLegend } from '../ChartLegend';
import { useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { EmptyState, Spinner } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { ChartSpline } from 'lucide-react';
import { RadarIndicators } from '../RadarIndicators';
import { ChartContext } from '../../context';

export const Chart = ({
  option,
  events,
  headerProps,
  legendProps,
  radarProps,
  loading,
  group,
  hasData,
  onPlotClick,
  fixedCursorProps,
}: IChartProps) => {
  const { t } = useTranslation();
  const [ready, setReady] = useState<boolean>(false);
  const [fixedCursors, setFixedCursors] = useState<IChartFixedCursor[]>([]);

  const chartRef = useRef<echarts.EChartsType | null>(null);
  const showHeader = headerProps?.show === undefined ? true : headerProps.show;
  const showLegend = legendProps?.show === undefined ? true : legendProps.show;

  const isRadar = !!option.radar;

  // @ts-ignore
  const source: any[] = option?.dataset?.source || [];
  const _hasData =
    hasData ||
    source.filter((row) => {
      if (!row) {
        return false;
      }

      if (typeof row[0] === 'string' && row[0].includes('_offset')) {
        return false;
      }

      return true;
    })?.length > 1;

  const context: IChartContext = useMemo((): IChartContext => {
    return {
      option,
      chartRef,
      ready,
      setReady,
      events,
      group,
      onPlotClick,
      fixedCursorProps,
      fixedCursors,
      setFixedCursors,
    };
  }, [
    option,
    ready,
    events,
    group,
    onPlotClick,
    fixedCursorProps,
    fixedCursors,
  ]);

  return (
    <ChartContext.Provider value={context}>
      <div className="chart-container">
        {showHeader && <ChartHeader {...headerProps} loading={loading} />}
        {loading ? (
          <EmptyState
            icon={<Spinner size="xl" />}
            text={t('banner.loading_chart_data')}
          />
        ) : _hasData ? (
          <div className="chart-core__wrapper">
            <ChartCore />
            {isRadar && <RadarIndicators {...radarProps} />}
          </div>
        ) : (
          <EmptyState
            icon={<ChartSpline size={40} />}
            title={t('banner.empty_chart_title')}
            text={t('banner.empty_chart_message')}
          />
        )}
        {showLegend && (
          <ChartLegend chartRef={chartRef} {...legendProps} loading={loading} />
        )}
      </div>
    </ChartContext.Provider>
  );
};
