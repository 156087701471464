import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useUsersStore } from '../../store/users/slice';
import { useTeamsStore } from '../../store/teams/slice';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { TeamsRepository } from '../../api/teams/repository';
import { TeamsService } from '../../api/teams/service';
import { IUserTeam } from '../../types/teams';
import {
  Button,
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
  Tag,
  Toast,
} from '@cycling-web/analog-ui';
import { Users } from 'lucide-react';
import { Actions } from './components/Actions';
import { ROUTES } from '../../router/routes';
import { RoleTag } from '../../components/columns/RoleTag';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../constants';
import { stringifyTeamName } from '../../utils/teamName';
import { useAnalogAuthContext } from '@cycling-web/auth';

export const useViewModel = () => {
  const { t } = useTranslation();
  const fetchingRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const authenticated = useAnalogAuthContext();
  const teams = useTeamsStore((s) => s.teams);
  const teamsLoaded = useTeamsStore((s) => s.teamsLoaded);

  const handleSignOut = useCallback(() => {
    navigate(`/${ROUTES.SIGN_OUT}`);
  }, [navigate]);

  const teamsRepository = useMemo(() => {
    return new TeamsRepository(new TeamsService());
  }, []);

  useEffect(() => {
    if (!authenticated || fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;

    teamsRepository
      .getUserTeams()
      .then((teams: IUserTeam[]) => {
        useTeamsStore.getState().setTeams(teams);
        const pathname = window.location.pathname;
        if (teams.length === 1) {
          const team = teams[0];

          if (team.invitationAccepted) {
            const teamName = stringifyTeamName(teams[0].tenantName);
            if (
              !pathname.includes(teamName) &&
              !pathname.includes(ROUTES.INTEGRATIONS)
            ) {
              navigate(`/${stringifyTeamName(teams[0].tenantName)}`);
            }
          } else {
            navigate(`/${ROUTES.TEAM_INVITATION}?team=${team.tenantName}`);
          }
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_teams_title'),
              message: t('error.get_teams_message'),
            },
            { toastId: 'get_teams' }
          );
        }
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [teamsRepository, authenticated, t, navigate]);

  const columns: ITableColumn[] = [
    {
      key: 'teamName',
      name: t('label.team_name'),
      minWidth: 164,
      maxWidth: 240,
    },
    {
      key: 'role',
      name: t('label.role'),
      minWidth: 160,
    },
    {
      key: 'status',
      name: 'Status',
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: 'action',
      name: 'Actions',
      minWidth: 160,
      maxWidth: 160,
    },
  ];

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IUserTeam;

    const renderMap: Record<string, ReactNode> = {
      teamName: castedItem.tenantName,
      role: (
        <RoleTag
          role={castedItem.roles}
          tagProps={{ variant: 'layer4', size: 's' }}
        />
      ),
      status: castedItem.invitationAccepted ? (
        <Tag variant="success-light" text={t('label.accepted')} />
      ) : (
        <Tag variant="warning-light" text={t('label.pending')} />
      ),
      action: <Actions team={castedItem} />,
    };

    return renderMap[column.key];
  };

  const emptyStateProps: IEmptyStateProps | undefined =
    teamsLoaded && teams.length === 0
      ? {
          title: t('banner.empty_teams_title'),
          text: t('banner.empty_teams_message'),
          icon: <Users size={32} />,
        }
      : undefined;

  const onRenderMenu = useCallback(() => {
    return (
      <Button
        variant="quietLayer2"
        content={t('action.sign_out')}
        onClick={handleSignOut}
      />
    );
  }, [handleSignOut, t]);

  return {
    columns,
    items: teams,
    loading: !teamsLoaded,
    emptyStateProps,
    onRenderCell,
    onRenderMenu,
  };
};
