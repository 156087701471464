import { useEffect } from 'react';
import { PageSpinner } from '@cycling-web/common';
import { LS } from '../../constants';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../router/routes';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';

export const SignOut = () => {
  const navigate = useNavigate();
  const { setAuthenticated, onSignOut } = useAnalogAuthContext();

  useEffect(() => {
    setAuthenticated(false);

    localStorage.removeItem(LS.AccessToken);
    localStorage.removeItem(LS.RefreshToken);

    if (onSignOut) {
      onSignOut();
    }

    navigate(`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`);
    window.location.reload();
  }, [navigate, setAuthenticated, onSignOut]);

  return <PageSpinner />;
};
