import './index.css';
import { useContext, useMemo } from 'react';
import { AthleteCardContext } from '../../../../context';
import { IconButton, Skeleton, Tooltip } from '@cycling-web/analog-ui';
import { Cross } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { UTCMidnightToSameDate } from '../../../../../../utils/date-time';
import { differenceInYears } from 'date-fns';
import { EMPTY } from '../../../../../../constants';

export const StatsHeader = () => {
  const { athlete, loading, toggleBodyMap } = useContext(AthleteCardContext);
  const age = useMemo((): string => {
    return athlete?.dobTimeStampUTC
      ? differenceInYears(
          new Date(),
          UTCMidnightToSameDate(athlete.dobTimeStampUTC)
        ).toString()
      : EMPTY;
  }, [athlete?.dobTimeStampUTC]);

  const { t } = useTranslation();

  if (loading) {
    return (
      <header className="athlete-stats__header">
        <Skeleton width="200px" height="24px" />
        <div className="athlete-stats__basic">
          <Skeleton width="240px" height="22px" />
        </div>
      </header>
    );
  }

  return (
    <header className="athlete-stats__header">
      <h3 className="athlete-stats__header-title analog-typography--h4 bold">
        {athlete.fullName || `${athlete?.firstName} ${athlete?.lastName}`}
      </h3>
      <div className="athlete-stats__basic">
        <div className="athlete-stats__basic-item analog-typography--headline">
          {age} Yrs
        </div>
        <div className="athlete-stats__basic-divider" />
        <div className="athlete-stats__basic-item analog-typography--headline">
          H: {athlete?.height || '--'}
        </div>
        <div className="athlete-stats__basic-item analog-typography--headline">
          W: {athlete?.weight || '--'}
        </div>

        {athlete.medicalReport && (
          <div className="athlete-stats__header-tooltip">
            <Tooltip
              anchor={
                <IconButton
                  variant="quietLayer2"
                  size="s"
                  onClick={toggleBodyMap}
                  content={<Cross />}
                />
              }
              content={t('label.show_body_map')}
              delay={{
                open: 1000,
                close: 0,
              }}
              placement="bottom-end"
            />
          </div>
        )}
      </div>
    </header>
  );
};
