import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { Dialog, FormElement } from '@cycling-web/analog-ui';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DeepPartial, InputControl } from '@cycling-web/common';
import { SmartChartsRepository } from '../../api/smart-charts/repository';
import { SmartChartsService } from '../../api/smart-charts/service';
import {
  ISmartChartConfig,
  ISmartChartConfigBase,
} from '../../types/smart-charts';

type IProps = {
  conversation: ISmartChartConfigBase;
  onDismiss: () => void;
  onSubmitSuccess: (config: ISmartChartConfig) => void;
};

type IRenameFormData = {
  name: string;
};

export const RenameSmartChartModal = ({
  conversation,
  onDismiss,
  onSubmitSuccess,
}: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const schema = useMemo(() => {
    return z.object({
      name: z.string().min(1, { message: t('validation.required') }),
    });
  }, [t]);

  const form = useForm<IRenameFormData>({
    defaultValues: {
      name: conversation.ai_graph_meta_data?.title?.value || '',
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IRenameFormData) => {
      setLoading(true);

      const payload: DeepPartial<ISmartChartConfig> = {
        id: conversation.id,
        ai_graph_in_scope: {
          ai_generated_graphs: [
            {
              title: {
                value: formData.name,
                is_ai_generated: false,
                is_user_updated: true,
              },
            },
          ],
        },
        ai_graph_meta_data: {
          title: {
            value: formData.name,
            is_ai_generated: false,
            is_user_updated: true,
          },
        },
      };

      smartChartsRepository
        .updateConfig(payload)
        .then((response) => {
          onSubmitSuccess(response);
          onDismiss();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [conversation.id, onDismiss, onSubmitSuccess, smartChartsRepository]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(
      (formData: IRenameFormData) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.rename_chart')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="rename-chart-form-modal">
          <FormElement label={t('label.name')} message={errors.name?.message}>
            <InputControl
              name="name"
              placeholder={t('placeholder.name')}
              invalid={!!errors.name}
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
