import './index.css';
import { IconButton, Typography, useBoolean } from '@cycling-web/analog-ui';
import { ReactNode, useCallback } from 'react';
import { ArrowRight, TriangleAlert } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IPlatform } from '../../../types/platform';
import { PlatformIntegrationStatus } from '../PlatformIntegrationStatus';
import { DisconnectConfirmDialog } from '../DisconnectConfirmDialog';

type IProps = {
  icon: ReactNode;
  title?: string;
  handleIntegration: () => void;
  type?: 'platform' | 'device';
  loading?: boolean;
  error?: boolean;
  platform: IPlatform | undefined;
  handleDisconnect: () => Promise<void>;
  showDetails?: boolean;
};

export const OnboardingIntegrationItem = ({
  icon,
  title,
  handleIntegration,
  loading,
  platform,
  error,
  type,
  handleDisconnect,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();

  const {
    value: showDisconnectConfirmModal,
    setTrue: openDisconnectConfirmModal,
    setFalse: dismissDisconnectConfirmModal,
  } = useBoolean(false);

  const onChangeConnection = useCallback(() => {
    if (platform?.disconnected) {
      handleIntegration();
    } else {
      openDisconnectConfirmModal();
    }
  }, [handleIntegration, openDisconnectConfirmModal, platform?.disconnected]);

  const errorJSX = error && (
    <div className="onboarding__integration-error">
      <TriangleAlert size={16} />
      <Typography
        variant="subtitle"
        weight="regular"
        text={t('error.account_connection_failed')}
      />
    </div>
  );

  return (
    <div className="onboarding__integration-item">
      <div className="onboarding__integration-item-wrapper">
        <div className="onboarding__integration-item-icon">{icon}</div>
        <div className="onboarding__integration-item-details">
          {title && (
            <Typography
              variant="headline"
              weight="bold"
              className="onboarding__integration-item-title"
              text={title}
            />
          )}
          {type === 'platform' && errorJSX}
        </div>
        <div className="onboarding__integration-item-actions">
          {platform ? (
            <PlatformIntegrationStatus
              platform={platform}
              onChangeConnection={onChangeConnection}
              showDetails={showDetails}
            />
          ) : (
            <IconButton
              variant="layer1"
              content={<ArrowRight />}
              onClick={handleIntegration}
              loading={loading}
            />
          )}
        </div>
      </div>
      {type === 'device' && errorJSX}
      {showDisconnectConfirmModal && (
        <DisconnectConfirmDialog
          platform={platform}
          onSubmit={handleDisconnect}
          onDismiss={dismissDisconnectConfirmModal}
        />
      )}
    </div>
  );
};
