import './index.css';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useSmartChartsStore } from '../../store/slice';
import { computeGraphOption } from '../../utils/graphConfig';
import {
  EmptyState,
  IconButton,
  Spinner,
  Tooltip,
  useBoolean,
} from '@cycling-web/analog-ui';
import { PinChartModal } from '../PinChartModal';
import { Maximize2, Minimize2, Pin } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSmartChartsContext } from '../../context';
import { Chart } from '../../../../components/Chart';

export const ChartContainer = () => {
  const { t } = useTranslation();
  const { expanded, setExpanded } = useSmartChartsContext();
  const config = useSmartChartsStore((s) => s.config);
  const chartLoaded = useSmartChartsStore((s) => s.chartLoaded);
  const selectedAthletes = useSmartChartsStore((s) => s.selectedAthletes);
  const graphs = config?.ai_graph_in_scope?.ai_generated_graphs || [];
  const hasData = graphs.length > 0 && graphs[0] && graphs[0].plots.length > 0;

  const toggleExpand = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();
      setExpanded((expanded) => !expanded);
    },
    [setExpanded]
  );

  const {
    value: showPinModal,
    setTrue: openPinModal,
    setFalse: dismissPinModal,
  } = useBoolean(false);

  const option = useMemo(() => {
    if (!config || !hasData) {
      return {};
    }

    return computeGraphOption(config, selectedAthletes);
  }, [config, hasData, selectedAthletes]);

  if (graphs.length === 0 || !hasData) {
    return null;
  }

  return (
    <ChartWrapper minHeight="206px" className="sc2__chart-wrapper">
      {chartLoaded ? (
        <Chart
          headerProps={{
            title: config?.ai_graph_meta_data.title.value || 'New chart',
          }}
          option={option}
          loading={!chartLoaded}
        />
      ) : (
        <EmptyState
          icon={<Spinner size="l" />}
          text={t('banner.loading_chart_data')}
        />
      )}

      <div className="sc2__chart-actions">
        <Tooltip
          anchor={
            <IconButton
              size="xs"
              variant="quietLayer3"
              content={<Pin />}
              onClick={openPinModal}
            />
          }
          content={t('action.pin')}
          delay={{
            open: 500,
            close: 0,
          }}
          placement="bottom-end"
        />

        <Tooltip
          anchor={
            <IconButton
              size="xs"
              variant="quietLayer3"
              content={expanded ? <Minimize2 /> : <Maximize2 />}
              onClick={toggleExpand}
            />
          }
          content={expanded ? t('action.collapse') : t('action.expand')}
          delay={{
            open: 500,
            close: 0,
          }}
          placement="bottom-end"
        />
      </div>

      {showPinModal && <PinChartModal onDismiss={dismissPinModal} />}
    </ChartWrapper>
  );
};
