import { Button, FormElement, Message } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputPasswordControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import { use, useCallback, useMemo, useState } from 'react';
import { AuthRepository } from '../../api/auth/repository';
import { AuthPageContext } from '../../context/AuthPageContext';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../router/routes';
import { FormView } from '../FormView';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { PasswordRequirementMessage } from '../PasswordRequirementMessage';

type IForm = {
  new_password: string;
  repeat_password: string;
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const navigate = useNavigate();
  const { continuationTokenRef } = use(AuthPageContext);

  const { onPasswordReset, authOptions } = useAnalogAuthContext();

  const authRepository = useMemo(() => {
    return AuthRepository.getInstance(authOptions);
  }, [authOptions]);

  const schema = useMemo(() => {
    return z
      .object({
        new_password: z
          .string()
          .min(1, { message: t('validation.required') })
          .min(8, { message: t('validation.password_too_short') }),
        repeat_password: z
          .string()
          .min(1, { message: t('validation.required') })
          .min(8, { message: t('validation.password_too_short') }),
      })
      .refine((data) => data.new_password === data.repeat_password, {
        message: t('validation.passwords_must_match'),
        path: ['repeat_password'],
      });
  }, [t]);

  const form = useForm<IForm>({
    defaultValues: {
      new_password: '',
      repeat_password: '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      setLoading(true);
      return authRepository
        .resetPasswordSubmit({
          continuation_token: continuationTokenRef.current,
          new_password: formData.new_password,
        })
        .then(() => {
          if (onPasswordReset) {
            onPasswordReset();
          }
          navigate(`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`, { replace: true });
        })
        .catch((e: { suberror: string }) => {
          const suberror = e?.suberror || 'invalid_email_or_password';
          setMessage(t(`validation.${suberror}`));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [authRepository, continuationTokenRef, navigate, t, onPasswordReset]
  );

  const handleBackClick = useCallback(() => {
    navigate(`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`, { replace: true });
  }, [navigate]);

  return (
    <FormProvider {...form}>
      <FormView title={t('label.create_new_password')}>
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.new_password')}
            message={formState.errors.new_password?.message}
          >
            <InputPasswordControl
              name="new_password"
              size="l"
              placeholder={t('placeholder.password')}
              autoFocus={true}
              invalid={!!formState.errors.new_password}
            />
          </FormElement>

          <FormElement
            label={t('label.repeat_password')}
            message={formState.errors.repeat_password?.message}
          >
            <InputPasswordControl
              name="repeat_password"
              size="l"
              placeholder={t('placeholder.password')}
              invalid={!!formState.errors.repeat_password}
            />
          </FormElement>

          {message && (
            <div className="analog-auth-common-form__message">
              <Message text={message} variant="error" />
            </div>
          )}

          <PasswordRequirementMessage />

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              size="l"
              content={t('action.reset_password')}
              fullWidth
              loading={loading}
            />

            <Button
              type="button"
              size="l"
              variant="quietLayer3"
              content={t('action.back')}
              fullWidth
              onClick={handleBackClick}
            />
          </div>
        </form>
      </FormView>
    </FormProvider>
  );
};
