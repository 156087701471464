import './index.css';
import { useTranslation } from 'react-i18next';

export const PasswordRequirementMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="analog-auth-common-form__password-message analog-typography--body">
      <ul>
        <li>{t('label.password_requirement_message_1')}</li>
        <li>{t('label.password_requirement_message_2')}</li>
        <li>{t('label.password_requirement_message_3')}</li>
        <li>{t('label.password_requirement_message_4')}</li>
        <li>{t('label.password_requirement_message_5')}</li>
      </ul>
    </div>
  );
};
