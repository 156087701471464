import { useCallback, useMemo } from 'react';
import { SmartChartsRepository } from '../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../api/smart-charts/service';
import { useParams } from 'react-router';
import { IAthlete } from '../../../types/athletes';

export const useUpdateConfig = () => {
  const { chartId } = useParams();

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const updateConfig = useCallback(
    (athletes: IAthlete[]) => {
      if (!chartId) {
        return Promise.reject();
      }

      return smartChartsRepository.updateMultipleAthleteConfig({
        graphId: chartId,
        dataSources: athletes.map((a: IAthlete) => {
          return {
            type: 'AthleteDataSourceMetadata',
            athlete_id: a.id.toString(),
            athlete_image_identifier: '',
            athlete_flag_identifier: '',
            athlete_full_name: a.fullName,
          };
        }),
      });
    },
    [chartId, smartChartsRepository]
  );

  return {
    updateConfig,
  };
};
