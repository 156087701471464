import './index.css';
import { Select } from '@cycling-web/analog-ui';
import { ISelectOption } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format, startOfWeek, subWeeks } from 'date-fns';

type IProps = {
  weeks?: number;
  value: string;
  onChange: (value: string) => void;
};

export const WeeksFilter = ({ weeks = 5, value, onChange }: IProps) => {
  const { t } = useTranslation();

  const generateWeeks = useCallback(
    (count: number): ISelectOption[] => {
      const weeks: ISelectOption[] = [];
      const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
      const currentWeekNumber = format(currentWeekStart, 'w');
      const currentYear = format(currentWeekStart, 'RRRR');

      weeks.push({
        id: `${currentWeekNumber}_${currentYear}`,
        text: t('label.current_week'),
      });

      for (let i = 1; i <= count; i++) {
        const pastWeekStart = subWeeks(currentWeekStart, i);
        const pastWeekNumber = format(pastWeekStart, 'w');
        const pastYear = format(pastWeekStart, 'RRRR');
        const pastMonthName =
          pastWeekNumber === '1' ? 'January' : format(pastWeekStart, 'MMMM');

        weeks.push({
          id: `${pastWeekNumber}_${pastYear}`,
          text: `W${pastWeekNumber} ${pastMonthName}`,
        });
      }

      return weeks;
    },
    [t]
  );

  const options: ISelectOption[] = useMemo(() => {
    return generateWeeks(weeks);
  }, [generateWeeks, weeks]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id);
    },
    [onChange]
  );

  return (
    <div className="weeks-filter">
      <Select
        options={options}
        value={options.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.week')}
      />
    </div>
  );
};
