import { forwardRef, useContext } from 'react';
import { clsx } from 'clsx';
import { DatepickerContext } from './context';
import { IDatepickerContext } from './types';
import { IconButton } from '../Button/IconButton';
import { Calendar, X } from 'lucide-react';
import { Typography } from '../Typography';
import {
  textFieldButtonSize,
  textFieldButtonVariant,
} from '../../utils/common';

export const DatepickerToggle = forwardRef<HTMLDivElement, unknown>(
  (_, ref) => {
    const {
      dropdownProps,
      invalid,
      disabled,
      handleClear,
      placeholder,
      displayValue,
      size = 'm',
      variant = 'layer2',
    }: IDatepickerContext = useContext(DatepickerContext);

    const rootClass: string = clsx(
      'analog-datepicker__toggle',
      dropdownProps?.isOpen && 'analog-datepicker__toggle--focused',
      invalid && 'analog-datepicker__toggle--invalid',
      disabled && 'analog-datepicker__toggle--disabled'
    );

    return (
      <div className={rootClass} tabIndex={0} ref={ref}>
        {placeholder && !displayValue && (
          <Typography
            as="div"
            className="analog-datepicker__toggle-placeholder"
            text={placeholder}
          />
        )}

        <Typography
          as="div"
          className="analog-datepicker__toggle-value"
          text={displayValue}
        />

        {!disabled && (
          <div className="analog-datepicker__toggle-actions">
            {displayValue && handleClear && (
              <IconButton
                size={textFieldButtonSize[size]}
                variant={textFieldButtonVariant[variant]}
                onClick={handleClear}
                data-testid="clear-button"
                content={<X />}
              />
            )}
            <IconButton
              size={textFieldButtonSize[size]}
              variant={textFieldButtonVariant[variant]}
              disabled={disabled}
              content={<Calendar />}
            />
          </div>
        )}
      </div>
    );
  }
);

DatepickerToggle.displayName = 'DatepickerToggle';
