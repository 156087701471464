import {
  IAuthServiceOptions,
  IOAuthTokenRequest,
  IOAuthTokenResponse,
  IProxyRequest,
  IRefreshTokenRequest,
  IRefreshTokenResponse,
  IResetPasswordChallengeRequest,
  IResetPasswordChallengeResponse,
  IResetPasswordContinueRequest,
  IResetPasswordContinueResponse,
  IResetPasswordStartRequest,
  IResetPasswordStartResponse,
  IResetPasswordSubmitRequest,
  IResetPasswordSubmitResponse,
  ISignInChallengeRequest,
  ISignInChallengeResponse,
  ISignInStartRequest,
  ISignInStartResponse,
  ISignUpChallengeRequest,
  ISignUpChallengeResponse,
  ISignUpContinueRequest,
  ISignUpContinueResponse,
  ISignUpStartRequest,
  ISignUpStartResponse,
} from './types';

interface IAuthService {
  signUpStart: (p: ISignUpStartRequest) => Promise<ISignUpStartResponse>;
  signUpChallenge: (
    p: ISignUpChallengeRequest
  ) => Promise<ISignUpChallengeResponse>;
  signUpContinue: (
    p: ISignUpContinueRequest
  ) => Promise<ISignUpContinueResponse>;
  getOAuthToken: (p: IOAuthTokenRequest) => Promise<IOAuthTokenResponse>;
  refreshToken: (p: IRefreshTokenRequest) => Promise<IRefreshTokenResponse>;

  signInStart: (p: ISignInStartRequest) => Promise<ISignInStartResponse>;
  signInChallenge: (
    p: ISignInChallengeRequest
  ) => Promise<ISignInChallengeResponse>;

  resetPasswordStart: (
    p: IResetPasswordStartRequest
  ) => Promise<IResetPasswordStartResponse>;
  resetPasswordChallenge: (
    p: IResetPasswordChallengeRequest
  ) => Promise<IResetPasswordChallengeResponse>;
  resetPasswordContinue: (
    p: IResetPasswordContinueRequest
  ) => Promise<IResetPasswordContinueResponse>;
  resetPasswordSubmit: (
    p: IResetPasswordSubmitRequest
  ) => Promise<IResetPasswordSubmitResponse>;
}

export class AuthService implements IAuthService {
  private client_id = '';
  private baseAuthUrl = '';
  private proxyApiUrl = '';
  private baseConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  constructor(options: IAuthServiceOptions) {
    this.baseAuthUrl = `https://${options.subdomain}.ciamlogin.com/${options.subdomain}.onmicrosoft.com`;
    this.client_id = options.client_id;
    this.proxyApiUrl = options.proxyApiUrl;
  }

  private async fetchProxy<T>(proxyPayload: IProxyRequest): Promise<T> {
    const response = await fetch(this.proxyApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(proxyPayload),
    });

    if (!response.ok) {
      throw await response.json().catch(() => null);
    }

    return response.json();
  }

  async signUpStart(p: ISignUpStartRequest): Promise<ISignUpStartResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/signup/v1.0/start`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<ISignUpStartResponse>(proxyPayload);
  }

  async signUpChallenge(
    p: ISignUpChallengeRequest
  ): Promise<ISignUpChallengeResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/signup/v1.0/challenge`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<ISignUpChallengeResponse>(proxyPayload);
  }

  async signUpContinue(
    p: ISignUpContinueRequest
  ): Promise<ISignUpContinueResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/signup/v1.0/continue`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<ISignUpContinueResponse>(proxyPayload);
  }

  async getOAuthToken(p: IOAuthTokenRequest): Promise<IOAuthTokenResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/oauth2/v2.0/token`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<IOAuthTokenResponse>(proxyPayload);
  }

  async refreshToken(p: IRefreshTokenRequest): Promise<IRefreshTokenResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/oauth2/v2.0/token`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<IRefreshTokenResponse>(proxyPayload);
  }

  async signInStart(p: ISignInStartRequest): Promise<ISignInStartResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/oauth2/v2.0/initiate`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<ISignInStartResponse>(proxyPayload);
  }

  async signInChallenge(
    p: ISignInChallengeRequest
  ): Promise<ISignInChallengeResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/oauth2/v2.0/challenge`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<ISignInChallengeResponse>(proxyPayload);
  }

  async resetPasswordStart(
    p: IResetPasswordStartRequest
  ): Promise<IResetPasswordStartResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/resetpassword/v1.0/start`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<IResetPasswordStartResponse>(proxyPayload);
  }

  async resetPasswordChallenge(
    p: IResetPasswordChallengeRequest
  ): Promise<IResetPasswordChallengeResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/resetpassword/v1.0/challenge`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<IResetPasswordChallengeResponse>(proxyPayload);
  }

  async resetPasswordContinue(
    p: IResetPasswordContinueRequest
  ): Promise<IResetPasswordContinueResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/resetpassword/v1.0/continue`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<IResetPasswordContinueResponse>(proxyPayload);
  }

  async resetPasswordSubmit(
    p: IResetPasswordSubmitRequest
  ): Promise<IResetPasswordSubmitResponse> {
    const formBody = this.preparePayload({
      ...p,
      client_id: this.client_id,
    });

    const proxyPayload: IProxyRequest = {
      url: `${this.baseAuthUrl}/resetpassword/v1.0/submit`,
      method: 'POST',
      body: formBody,
      headers: this.baseConfig.headers,
    };

    return this.fetchProxy<IResetPasswordSubmitResponse>(proxyPayload);
  }

  private preparePayload<T extends Record<string, any>>(data: T): string {
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, string>);

    return new URLSearchParams(filteredData).toString();
  }
}
