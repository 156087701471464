import { useCallback, useMemo } from 'react';
import { ICriticalPower, IPeak, IWorkoutDetail } from '../../types/performance';
import { useFetchGroupPeaks } from './hooks/useFetchGroupPeaks';
import { useFetchGroupCriticalPower } from './hooks/useFetchGroupCriticalPower';
import { useFetchGroupWorkoutDetails } from './hooks/useFetchGroupWorkoutDetails';
import { useGroupReportsStore } from '../GroupDetails/store/slice';
import { AnyValue } from '../../types/common';
import { IGroupReportsFilters } from '../GroupDetails/types';
import { IHandleChangeFilter } from '@cycling-web/common';
import { useAthletesStore } from '../../store/athletes/slice';
import { sortByAthleteDelegate } from '../../utils/sortByAthleteDelegate';
import { ChartAxisName } from '../../constants/charts';
import { IChartSource } from '../../components/Chart';

export const useViewModel = () => {
  useFetchGroupPeaks();
  useFetchGroupCriticalPower();
  useFetchGroupWorkoutDetails();

  const criticalPower: ICriticalPower[] = useGroupReportsStore(
    (s) => s.criticalPower
  );
  const criticalPowerLoaded: boolean = useGroupReportsStore(
    (s) => s.criticalPowerLoaded
  );

  const workoutDetails: IWorkoutDetail[] = useGroupReportsStore(
    (s) => s.workoutDetails
  );
  const workoutDetailsLoaded: boolean = useGroupReportsStore(
    (s) => s.workoutDetailsLoaded
  );

  const peaks: IPeak[] = useGroupReportsStore((s) => s.peaks);
  const peaksLoaded: boolean = useGroupReportsStore((s) => s.peaksLoaded);

  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const filters = useGroupReportsStore((s) => s.filters);
  const setFilters = useGroupReportsStore((s) => s.setFilters);

  const sortedWorkoutDetails = useMemo(() => {
    const sorted = [...workoutDetails];
    sorted.sort(sortByAthleteDelegate(athletesMap));
    return sorted;
  }, [athletesMap, workoutDetails]);

  const sortedCriticalPower = useMemo(() => {
    const sorted = [...criticalPower];
    sorted.sort(sortByAthleteDelegate(athletesMap));
    return sorted;
  }, [athletesMap, criticalPower]);

  const sortedPeaks = useMemo(() => {
    const sorted = [...peaks];
    sorted.sort(sortByAthleteDelegate(athletesMap));
    return sorted;
  }, [athletesMap, peaks]);

  const handleChangeFilter: IHandleChangeFilter<IGroupReportsFilters> =
    useCallback(
      (key: keyof IGroupReportsFilters) => {
        return (value: AnyValue) => {
          setFilters({ [key]: value });
        };
      },
      [setFilters]
    );

  const workoutDetailsSources = useMemo(() => {
    const tssAndIf: IChartSource = [
      [ChartAxisName.Athlete, ChartAxisName.TSS, ChartAxisName.IF],
    ];
    const durationAndDistance: IChartSource = [
      [ChartAxisName.Athlete, ChartAxisName.Duration, ChartAxisName.Distance],
    ];
    const durationAndElevation: IChartSource = [
      [ChartAxisName.Athlete, ChartAxisName.Duration, ChartAxisName.Elevation],
    ];
    const workAndIf: IChartSource = [
      [ChartAxisName.Athlete, ChartAxisName.Work, ChartAxisName.IF],
    ];

    sortedWorkoutDetails.forEach((item: IWorkoutDetail) => {
      const athlete = athletesMap[item.athleteId];

      tssAndIf.push([athlete?.fullName || '']);
      durationAndDistance.push([athlete?.fullName || '']);
      durationAndElevation.push([athlete?.fullName || '']);
      workAndIf.push([athlete?.fullName || '']);

      tssAndIf[tssAndIf.length - 1].push(
        item.tss || 0,
        item.intensityFactor || 0
      );

      durationAndDistance[durationAndDistance.length - 1].push(
        item.durationInHour || 0,
        item.distanceInKm || 0
      );

      durationAndElevation[durationAndElevation.length - 1].push(
        item.durationInHour || 0,
        item.climbInMeter || 0
      );

      workAndIf[workAndIf.length - 1].push(
        item.workInKj || 0,
        item.intensityFactor || 0
      );
    });

    return {
      tssAndIf,
      durationAndDistance,
      durationAndElevation,
      workAndIf,
    };
  }, [athletesMap, sortedWorkoutDetails]);

  const criticalPowerSource = useMemo(() => {
    const source: IChartSource = [
      [
        ChartAxisName.Athlete,
        ChartAxisName.mCP,
        ChartAxisName.mCPRelative,
        ChartAxisName.W,
      ],
    ];

    sortedCriticalPower.forEach((item: ICriticalPower) => {
      const athlete = athletesMap[item.athleteId];

      source.push([athlete?.fullName || '']);

      source[source.length - 1].push(
        item.cp ? Math.round(item.cp) : 0,
        item.cp ? (item.cp / item.athleteWeight).toFixed(2) : 0,
        item.w ? (item.w / 1000).toFixed(1) : 0
      );
    });

    return source;
  }, [sortedCriticalPower, athletesMap]);

  const peaksSource = useMemo(() => {
    const source: IChartSource = [[ChartAxisName.Athlete]];

    sortedPeaks.forEach((item: IPeak, i: number) => {
      const athlete = athletesMap[item.athleteId];

      source.push([athlete?.fullName || '']);

      for (const key in item) {
        if (key !== 'athleteId') {
          if (i === 0) {
            source[0].push(key);
          }

          source[source.length - 1].push(item[key]);
        }
      }
    });

    return source;
  }, [sortedPeaks, athletesMap]);

  return {
    workoutDetailsSources,
    workoutDetailsLoading: !workoutDetailsLoaded,
    criticalPowerSource,
    criticalPowerLoading: !criticalPowerLoaded,
    peaksSource,
    peaksLoading: !peaksLoaded,
    filters,
    handleChangeFilter,
  };
};
