import { IPlatformEnum } from '../types/platform';
import { t } from 'i18next';

export const PlatformName: Record<IPlatformEnum, string> = {
  [IPlatformEnum.TrainingPeaks]: t('label.training_peaks'),
  [IPlatformEnum.MyWhoosh]: t('label.my_whoosh'),
  [IPlatformEnum.UltraHuman]: t('label.ultrahuman'),
  [IPlatformEnum.Wahoo]: t('label.wahoo'),
  [IPlatformEnum.Whoop]: t('label.whoop'),
};
