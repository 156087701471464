import { Button, FormElement } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import { use, useCallback, useMemo, useState } from 'react';
import { AuthRepository } from '../../api/auth/repository';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../router/routes';
import { AuthPageContext } from '../../context/AuthPageContext';
import { FormView } from '../FormView';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';

type IForm = {
  email: string;
};

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { continuationTokenRef, usernameRef } = use(AuthPageContext);
  const { authOptions } = useAnalogAuthContext();

  const authRepository = useMemo(() => {
    return AuthRepository.getInstance(authOptions);
  }, [authOptions]);

  const schema = useMemo(() => {
    return z.object({
      email: z.string().min(1, { message: t('validation.required') }),
    });
  }, [t]);

  const form = useForm<IForm>({
    defaultValues: {
      email: usernameRef.current || '',
    },
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState, setError } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      setLoading(true);
      usernameRef.current = formData.email;

      authRepository
        .resetPasswordStart({
          challenge_type: 'oob redirect',
          username: formData.email,
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          return authRepository.resetPasswordChallenge({
            challenge_type: 'oob redirect',
            continuation_token: continuationTokenRef.current,
          });
        })
        .then(() => {
          navigate(`/${ROUTES.AUTH}/${ROUTES.FORGOT_PASSWORD_OTP}`, {
            replace: true,
          });
        })
        .catch(() => {
          setError('email', {
            message: t('label.email_not_registered'),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [authRepository, continuationTokenRef, navigate, setError, t, usernameRef]
  );

  const handleBackClick = useCallback(() => {
    navigate(`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`, { replace: true });
  }, [navigate]);

  return (
    <FormProvider {...form}>
      <FormView
        title={t('label.forgot_password')}
        subtitle={t('label.enter_email_for_otp')}
      >
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.email')}
            message={formState.errors.email?.message}
          >
            <InputControl
              name="email"
              size="l"
              placeholder={t('placeholder.email')}
              invalid={!!formState.errors.email}
              autoFocus={true}
            />
          </FormElement>

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              size="l"
              content={t('action.continue')}
              fullWidth
              loading={loading}
            />

            <Button
              type="button"
              size="l"
              variant="quietLayer3"
              content={t('action.back')}
              fullWidth
              onClick={handleBackClick}
            />
          </div>
        </form>
      </FormView>
    </FormProvider>
  );
};
