import { UsersRepository } from '../api/users/repository';
import { UsersService } from '../api/users/service';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { LS } from '../constants';
import { AuthRepository } from '@cycling-web/auth';

let isRefreshing = false;
let failedRequestsQueue: Array<{
  config: InternalAxiosRequestConfig;
  resolve: (config: InternalAxiosRequestConfig) => void;
  reject: (error: AxiosError) => void;
}> = [];

export const onRefreshToken = (
  error: AxiosError
): Promise<InternalAxiosRequestConfig> => {
  const originalRequest = error.config as InternalAxiosRequestConfig;

  return new Promise((resolve, reject) => {
    const hasCheckRegistrationFailed = failedRequestsQueue.some(
      ({ config }) => {
        return config.url === '/v1/preauth/checkRegistration';
      }
    );

    if (hasCheckRegistrationFailed) {
      failedRequestsQueue.forEach(({ reject }) => reject(error));
      failedRequestsQueue = [];
      isRefreshing = false;
      return reject(error);
    }

    failedRequestsQueue.push({ config: originalRequest, resolve, reject });

    if (!isRefreshing) {
      isRefreshing = true;

      const usersRepository = new UsersRepository(new UsersService());

      const authRepository = AuthRepository.getInstance();
      authRepository
        .refreshToken({
          refresh_token: localStorage.getItem(LS.RefreshToken) || '',
        })
        .then((response) => {
          localStorage.setItem(LS.AccessToken, response.id_token);
          localStorage.setItem(LS.RefreshToken, response.refresh_token);

          return usersRepository.checkRegistration({
            idToken: response.id_token,
            accessToken: response.access_token,
          });
        })
        .then(() => {
          failedRequestsQueue.forEach(({ config, resolve }) => {
            resolve(config);
          });
        })
        .catch((refreshError) => {
          failedRequestsQueue.forEach(({ reject }) => reject(refreshError));
        })
        .finally(() => {
          failedRequestsQueue = [];
          isRefreshing = false;
        });
    }
  });
};
