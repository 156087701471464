import MyWhooshLogo from '../../../assets/illustrations/mywhoosh-logo.png';
import { OnboardingIntegrationItem } from '../IntegrationItem';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { Skeleton, Toast } from '@cycling-web/analog-ui';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';
import { IPlatform } from '../../../types/platform';
import { usePlatformsStore } from '../../../store/platforms/slice';

type IProps = {
  platform: IPlatform;
  platformLoaded: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
  showDetails?: boolean;
};

export const MyWhoosh = ({
  platform,
  platformLoaded,
  onConnect,
  onDisconnect,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const handleDisconnect = useCallback(() => {
    usePlatformsStore.getState().disconnect(platform);
    return platformRepository
      .removeMyWhooshAccount()
      .then(() => {
        if (onDisconnect) {
          onDisconnect();
        }
      })
      .catch(() => {
        Toast.error({
          title: t('error.disconnect_app_title'),
        });
        usePlatformsStore.getState().setConnected(platform);
      });
  }, [platform, platformRepository, onDisconnect, t]);

  if (!platform || platform.disconnected) {
    return null;
  }

  return (
    <>
      {platformLoaded ? (
        <OnboardingIntegrationItem
          icon={
            <img
              className="onboarding-platforms__item-img"
              src={MyWhooshLogo}
              alt={t('label.my_whoosh')}
            />
          }
          type="platform"
          title={t('label.my_whoosh')}
          handleIntegration={() => {}}
          platform={platform}
          handleDisconnect={handleDisconnect}
          showDetails={showDetails}
        />
      ) : (
        <Skeleton width="100%" height="111.5px" />
      )}
    </>
  );
};
