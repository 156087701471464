export enum Environment {
  Local = 'local',
  Development = 'development',
  Production = 'production',
}

export function getEnvironment(): Environment {
  if (
    window.origin.includes('localhost') ||
    window.origin.includes('127.0.0.1')
  ) {
    return Environment.Local;
  }

  if (window.origin.includes('https://sports.analog.io')) {
    return Environment.Production;
  }

  return Environment.Development;
}

export function getCredentials() {
  if (getEnvironment() === Environment.Production) {
    return {
      clientId: import.meta.env.VITE_MSAL_CLIENT_ID_PROD,
      authority: import.meta.env.VITE_MSAL_AUTHORITY_PROD,
      subdomain: import.meta.env.VITE_MSAL_SUBDOMAIN_PROD,
    };
  }

  return {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID_DEV,
    authority: import.meta.env.VITE_MSAL_AUTHORITY_DEV,
    subdomain: import.meta.env.VITE_MSAL_SUBDOMAIN_DEV,
  };
}

export const getApiUrl = () => {
  if (getEnvironment() === Environment.Production) {
    return import.meta.env.VITE_API_URL_PROD;
  }

  return import.meta.env.VITE_API_URL_DEV;
};
