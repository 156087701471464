import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import {
  ISelectOption,
  ISelectProps,
  ISingleSelectProps,
  Select,
} from '@cycling-web/analog-ui';
import { UserRole } from '@cycling-web/common';
import { IUserBase } from '../../../../types/users';
import { useTranslation } from 'react-i18next';

type IProps = {
  staff: IUserBase[];
  selectProps: Partial<ISelectProps & ISingleSelectProps>;
};

export const CoachControl = ({ staff, selectProps }: IProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [coach, external_coach] = useWatch({
    name: ['roleStaffIdMap.coach', 'roleStaffIdMap.external_coach'],
  });
  const value = (selectProps.options || []).find((o: ISelectOption) => {
    const id = coach || external_coach;
    if (id) {
      return o.id === id.toString();
    }
    return undefined;
  });

  const onCoachChange = useCallback(
    (option: ISelectOption) => {
      const user = staff.find((o) => o.staffId === +option.id);
      if (user?.roles.includes(UserRole.ExternalCoach)) {
        // @ts-ignore
        setValue('roleStaffIdMap.coach', '');
        setValue('roleStaffIdMap.external_coach', +option.id);
      } else {
        setValue('roleStaffIdMap.coach', +option.id);
        // @ts-ignore
        setValue('roleStaffIdMap.external_coach', '');
      }
    },
    [staff, setValue]
  );

  const handleClear = useCallback(() => {
    setValue('roleStaffIdMap.coach', '');
    setValue('roleStaffIdMap.external_coach', '');
  }, [setValue]);

  return (
    <Select
      {...selectProps}
      placeholder={t('placeholder.select_user')}
      dropdownProps={{
        maxHeight: '260px',
      }}
      onClear={handleClear}
      onChange={onCoachChange}
      options={selectProps.options || []}
      value={value}
      multiple={false}
      search={true}
    />
  );
};
