class ComplianceModule {
  private static instance: ComplianceModule;
  private appsInsightsConsent = false;

  private constructor() {}

  public static getInstance(): ComplianceModule {
    if (!ComplianceModule.instance) {
      ComplianceModule.instance = new ComplianceModule();
    }
    return ComplianceModule.instance;
  }

  public getAppsInsightsConsent(): boolean {
    return this.appsInsightsConsent;
  }

  public setAppsInsightsConsent(consent: boolean): void {
    this.appsInsightsConsent = consent;
    if (!consent) {
      this.removeAppsInsightsKeys();
    }
  }

  private removeAppsInsightsKeys(): void {
    localStorage.removeItem('AI_sentBuffer');
  }
}

export const complianceModuleInstance = ComplianceModule.getInstance();
