import { Navigate, Outlet } from 'react-router';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { LS } from '../../constants';

export const PrivatePage = () => {
  const { authenticated } = useAnalogAuthContext();

  const currentPagePathname: string = useMemo(() => {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }, []);

  if (!authenticated) {
    localStorage.setItem(LS.PreviousPage, currentPagePathname);
    return <Navigate to={`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`} replace={true} />;
  }

  return <Outlet />;
};
