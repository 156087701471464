import i18n from 'i18next';
import translation from './translations/en.json';

export const resources = {
  en: {
    'analog-auth': translation,
  },
} as const;

const analogAuthI18n = i18n.createInstance();

analogAuthI18n.init({
  resources,
  lng: 'en',
  ns: ['analog-auth'],
  defaultNS: 'analog-auth',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default analogAuthI18n;
