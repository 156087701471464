import './index.css';
import { ChartSpline } from 'lucide-react';
import { EmptyState, Spinner } from '@cycling-web/analog-ui';
import { t } from 'i18next';
import { PinnedChart } from './components/PinnedChart';
import { useFetchPinnedCharts } from './hooks/useFetchPinnedCharts';
import { usePinnedTabStore } from '../../store/pinned-tabs/slice';
import { ISmartChartConfig } from '../../types/smart-charts';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { ROUTES } from '../../router/routes';

export const PinnedTab = () => {
  const { pinnedTabId, groupId, athleteId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useFetchPinnedCharts();

  const charts = usePinnedTabStore((s) => s.charts);
  const chartsLoaded = usePinnedTabStore((s) => s.chartsLoaded);

  useEffect(() => {
    return () => {
      usePinnedTabStore.getState().setCharts([]);
      usePinnedTabStore.getState().setChartsLoaded(false);
    };
  }, [pinnedTabId, groupId, athleteId]);

  const navigateToSmartCharts = useCallback(() => {
    const tmp = pathname
      .split('/')
      .filter((_, i) => i < 3)
      .join('/');
    navigate(`${tmp}/${ROUTES.SMART_CHARTS}`);
  }, [navigate, pathname]);

  if (!chartsLoaded) {
    return (
      <EmptyState
        icon={<Spinner size="xl" />}
        title={t('banner.loading_chart_data')}
      />
    );
  }

  if (charts.length === 0) {
    return (
      <EmptyState
        icon={<ChartSpline size={40} />}
        title={t('banner.empty_pinned_charts_title')}
        text={t('banner.empty_pinned_charts_text')}
        buttonProps={{
          content: t('action.create_chart'),
          onClick: navigateToSmartCharts,
        }}
      />
    );
  }

  const chartsJSX = charts.map((chart: ISmartChartConfig) => {
    return <PinnedChart key={chart.id} chart={chart} />;
  });

  return <div className="pinned-charts">{chartsJSX}</div>;
};
