import { curveDisplayValues, curveFormatTime } from '../../../utils/charts';

export const createAxisLabelFormatter = () => {
  let displayValuesIndexRef = 0;
  const savedXAxisLabels: string[] = [];
  let xAxisLabelsSaved = false;

  const axisLabelFormatter = (value: number, i: number) => {
    if (i === 0 && savedXAxisLabels.length > 0) {
      xAxisLabelsSaved = true;
    }

    if (xAxisLabelsSaved) {
      return savedXAxisLabels[i];
    }

    const parsedValue = Math.pow(2, value);
    const tolerance = 0.01 * parsedValue;

    const targetDisplayValue = curveDisplayValues[displayValuesIndexRef];
    const delta = Math.abs(parsedValue - targetDisplayValue);
    const isPassedMaxTolerance = parsedValue - targetDisplayValue > tolerance;
    const isCloseEnough = delta <= tolerance;

    if (isPassedMaxTolerance) {
      displayValuesIndexRef++;
    }

    if (isCloseEnough) {
      const displayValue = curveFormatTime(targetDisplayValue);
      savedXAxisLabels.push(displayValue);
      return displayValue;
    }

    savedXAxisLabels.push('');
    return '';
  };

  return axisLabelFormatter;
};
